import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import EditContainer, { editAttributes, blockAttributes, editingCurrentPage } from 'Shared/EditContainer';
import { renderElement, renderHtml } from 'Shared/text-formatting';
import { SMALL } from 'Shared/breakpoints';
import { contentPositions } from 'Shared/ContentPosition';
import { textThemes } from 'Shared/Theme';
import GradientOverlay, { getGradientPosition } from 'Shared/GradientOverlay';
import ResponsiveImage, { imageCropShape } from 'Shared/ResponsiveImage';
import * as styles from './base.scss';

const SaleBlock = ({
    isMobile,
    blockSize,
    backgroundColor,
    imageSources,
    header,
    ingress,
    theme,
    shadeBackground,
    buttonText,
    buttonLink,
    contentPositionMobile,
    contentPositionDesktop,
    editProps,
    isInEditMode,
    routedContentLink,
}) => {
    const contentPosition = isMobile ? contentPositionMobile : contentPositionDesktop;
    const isEditingCurrentPage = editingCurrentPage(isInEditMode, routedContentLink, editProps);
    const renderDelegate = isEditingCurrentPage ? renderElement : renderHtml;
    const noImage = !imageSources || imageSources.length < 1;
    return (
        <div
            {...blockAttributes(editProps)}
            className={classnames(styles.saleBlock, styles[blockSize], {
                [styles.noImage]: !backgroundColor && noImage,
            })}
            style={{ backgroundColor }}
        >
            <GradientOverlay position={getGradientPosition(contentPosition)} disabled={!shadeBackground}>
                <ResponsiveImage className={classnames(styles.objectContainer, styles.image)} crops={imageSources} />
            </GradientOverlay>

            <div className={classnames(styles.content, styles[contentPosition], styles[theme])}>
                {renderDelegate(
                    header,
                    {
                        className: classnames(styles.header),
                        ...editAttributes('Header', editProps),
                    },
                    'h2',
                )}
                {renderDelegate(ingress, {
                    className: classnames(styles.ingress),
                    ...editAttributes('Ingress', editProps),
                })}
                <EditContainer propertyName="Button" propertyRender="none" {...editProps}>
                    {buttonText && buttonLink && (
                        <a href={buttonLink} className={classnames('component-button', theme)}>
                            {buttonText}
                        </a>
                    )}
                </EditContainer>
            </div>
            {isInEditMode && (
                <EditContainer className={styles.editContainer} {...editProps}>
                    <button
                        {...editAttributes('BlockSize', {
                            ...editProps,
                            propertyRender: 'none',
                            editType: 'floating',
                        })}
                    >
                        Block size
                    </button>
                    <button
                        {...editAttributes('BackgroundColor', {
                            ...editProps,
                            propertyRender: 'none',
                            editType: 'floating',
                        })}
                    >
                        Background color
                    </button>
                    <button
                        {...editAttributes('Theme', {
                            ...editProps,
                            propertyRender: 'none',
                            editType: 'floating',
                        })}
                    >
                        Textfarge
                    </button>
                    <button
                        {...editAttributes('Image', {
                            ...editProps,
                            propertyRender: 'none',
                        })}
                    >
                        Image
                    </button>
                    <button
                        {...editAttributes('ContentPositionMobile', {
                            ...editProps,
                            propertyRender: 'none',
                            editType: 'floating',
                        })}
                    >
                        Content position (mobile)
                    </button>
                    <button
                        {...editAttributes('ContentPositionDesktop', {
                            ...editProps,
                            propertyRender: 'none',
                            editType: 'floating',
                        })}
                    >
                        Content position (desktop)
                    </button>
                    <button
                        {...editAttributes('ShadeBackground', {
                            ...editProps,
                            propertyRender: 'none',
                        })}
                    >
                        Shade background
                    </button>
                </EditContainer>
            )}
        </div>
    );
};

const blockSizes = ['', 'fullWidth', 'halfWidth', 'twoThirdsWidth', 'oneThirdWidth'];

SaleBlock.propTypes = {
    classNames: PropTypes.array,
    blockSize: PropTypes.oneOf(blockSizes),
    backgroundColor: PropTypes.string,
    imageSources: PropTypes.arrayOf(PropTypes.shape(imageCropShape)).isRequired,
    contentPositionMobile: PropTypes.oneOf(contentPositions),
    contentPositionDesktop: PropTypes.oneOf(contentPositions),
    shadeBackground: PropTypes.bool,
    theme: PropTypes.oneOf(textThemes),
    header: PropTypes.string,
    ingress: PropTypes.string,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string,
};

SaleBlock.defaultProps = {
    imageSizes: [
        {
            selector: '(max-width: 510px)',
            width: 500,
            height: 530,
        },
        {
            selector: '(min-width: 511px)',
            width: 960,
            height: 641,
            fallback: true,
        },
    ],
};

export default connect(({ editContainer, breakpoint }) => ({
    isMobile: breakpoint < SMALL,
    ...editContainer,
}))(SaleBlock);
