import { combineReducers } from 'redux';

import address from './DeliveryAddress/reducer';
import deliverySelection from './Delivery/reducer';
import payment from './Payment/reducer';
import order from './Order/reducer';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

export default combineReducers({
    address,
    deliverySelection,
    order,
    payment,
});
