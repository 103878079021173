export function debounce(wait, func) {
    let timeout;
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func.apply(this, args);
        }, wait);
    };
}

export function cancellingDebounce(wait, func) {
    let timeout, controller, resolve, reject;
    let pending = false;
    let promise = new Promise((r, a) => {
        resolve = r;
        reject = a;
    });
    return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            if (pending) {
                controller.abort();
            }
            controller = new AbortController();
            args.push(controller.signal);
            let pf = func.apply(this, args);
            if (pf && pf.then) {
                pending = true;
                pf.catch((e) => {
                    pending = false;
                    reject(e);
                }).then((...args) => {
                    pending = false;
                    resolve(args);
                });
            }
        }, wait);
        return promise;
    };
}

export function cancellingCall(func) {
    let controller;
    let pending = false;
    return function (...args) {
        if (pending) {
            controller.abort();
        }
        controller = new AbortController();
        args.push(controller.signal);
        let promise = func.apply(this, args);
        if (promise && promise.then) {
            pending = true;
            promise.then(() => {
                pending = false;
            });
        }
        return promise;
    };
}
