import React from 'react';
import { updateParents, setNewHeight, forceHeight } from './height';
import * as styles from './base.scss';
import classnames from 'classnames';

const isDefined = (value) => {
    if (value === null) return false;
    if (value === undefined) return false;
    return true;
};

class AutoHeight extends React.Component {
    constructor(props) {
        super(props);
        this.elementRef;
        this.state = {
            rendered: false,
        };
    }

    static defaultProps = {
        element: 'div',
        adjustment: 0,
        autoUpdateParents: true,
    };

    componentDidMount() {
        this.updateHeight();
    }

    componentDidUpdate() {
        this.updateHeight();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const nextRendered = nextState.rendered;
        const { rendered } = this.state;
        if (!rendered && nextRendered) return false;
        return true;
    }

    setElementRef = (node) => {
        const { setElementRef } = this.props;
        this.elementRef = node;
        if (setElementRef) setElementRef(node);
    };

    updateHeight = () => {
        const element = this.elementRef;
        if (!element) return;

        const { forcedHeight, adjustment, autoUpdateParents, animationClass } = this.props;
        const { rendered } = this.state;

        if (isDefined(forcedHeight)) {
            forceHeight(element, styles.autoHeight, forcedHeight, rendered, !autoUpdateParents, animationClass);
        } else {
            setNewHeight(element, styles.autoHeight, adjustment, !autoUpdateParents, animationClass);
        }

        if (autoUpdateParents) updateParents(styles.autoHeight);
        if (!rendered) this.setState({ rendered: true });
    };

    render() {
        const { props } = this;
        const { className, children, element } = props;
        const Element = element;

        return (
            <Element ref={this.setElementRef} className={classnames(styles.autoHeight, className)}>
                {children}
            </Element>
        );
    }
}

export default AutoHeight;
