import { LOGIN_PENDING, LOGIN_REJECT, LOGIN_UPDATE } from 'Shared/action-types';
import { hasAuthSession, authSession, setAuthState } from 'Shared/session-helper';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';
export const FAILED = 'FAILED';

const sessionExists = hasAuthSession();
const authState = sessionExists ? authSession() : {};

const initialState = {
    status: sessionExists ? FULFILLED : PENDING,
    userId: authState.userId,
    isAuthenticated: authState.isAuthenticated,
    isRegistered: authState.isRegistered,
    loadingSpinner: false,
    isOpenLoginModal: false,
    loginMenuVisible: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_PENDING:
            return {
                ...state,
                status: PENDING,
                loadingSpinner: true,
            };
        case LOGIN_REJECT:
            return {
                ...state,
                status: REJECTED,
                loadingSpinner: false,
            };
        case LOGIN_UPDATE:
            setAuthState(action.data);
            return {
                ...state,
                ...action.data,
                loadingSpinner: false,
            };
        default:
            return state;
    }
}
