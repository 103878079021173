import { ARTICLELISTPAGE_UPDATE } from 'Shared/action-types';

export const PENDING = 'PENDING';
export const UPDATING = 'UPDATING';
export const FAILED = 'FAILED';

const pageState = window.ARTICLELIST_DATA;
const initialState = pageState || {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ARTICLELISTPAGE_UPDATE:
            return { ...state, ...action.data };
        default:
            return state;
    }
}
