import {
    CLOSE_MODAL,
    OPEN_MODAL,
    SET_BTN_TEXT,
    SET_IS_POSITION_SHARED,
    SET_CONTENT_STATE,
    GEO_LOADING,
    GEO_CURRENT_POSITION,
    SET_MODAL_DATA,
    SET_MESSAGE,
} from 'Shared/action-types';
import { INIT } from './actions';

const initialState = {
    isOpen: false,
    contentState: INIT,
    btnText: 'All stores',
    isPositionShared: false,
    isLoading: false,
    message: null,
    currentPosition: {
        lat: 0,
        lng: 0,
    },
    modalData: {
        allStores: [],
        closestStores: [],
        userLocalStore: null,
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_MODAL:
            return Object.assign({}, state, {
                isOpen: true,
            });
        case CLOSE_MODAL:
            return Object.assign({}, state, {
                isOpen: false,
            });
        case SET_BTN_TEXT:
            return Object.assign({}, state, {
                btnText: action.btnText,
            });
        case SET_IS_POSITION_SHARED:
            return Object.assign({}, state, {
                isPositionShared: action.isPositionShared,
            });
        case SET_CONTENT_STATE:
            return Object.assign({}, state, {
                contentState: action.contentState,
            });
        case GEO_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case GEO_CURRENT_POSITION:
            return Object.assign({}, state, {
                currentPosition: action.currentPosition,
            });
        case SET_MODAL_DATA:
            return Object.assign({}, state, {
                modalData: action.modalData,
            });
        case SET_MESSAGE:
            return Object.assign({}, state, {
                message: action.message,
            });
        default:
            return state;
    }
}
