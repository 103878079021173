import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from 'Shared/store';
import LoginMenu from './';

export function embed(container) {
    const props = {
        signInUrl: container.dataset.signinurl,
        signOutUrl: container.dataset.signouturl,
        profileUrl: container.dataset.profileurl,
        orderHistoryUrl: container.dataset.orderhistoryurl,
        passwordUrl: container.dataset.passwordurl,
    };
    render(
        <Provider store={store}>
            <LoginMenu {...props} />
        </Provider>,
        container,
    );
}
