import PropTypes from 'prop-types';

export const baseProps = {
    name: PropTypes.string.isRequired,
};

export const elementProps = {
    name: PropTypes.string.isRequired,
    label: PropTypes.node,
    className: PropTypes.string,
};

export const inputProps = {
    prefix: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    error: PropTypes.string,
    required: PropTypes.bool,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    pattern: PropTypes.string,
};

export const submitProps = {
    onClick: PropTypes.func.isRequired,
};

export const markProps = {
    name: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
};

export const formProps = {
    values: PropTypes.arrayOf(PropTypes.shape(formElement)),
    onUpdate: PropTypes.func,
    onValidate: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
};

const formElement = {
    ...elementProps,
    type: PropTypes.oneOf(['input', 'checkbox', 'submit']),
};
