import React from 'react';
import PropTypes from 'prop-types';
import { renderNbsp, formatNumber } from 'Shared/text-formatting';
import { translate } from '../translate';
import * as styles from './base.scss';

export const Price = ({ price, showDecimals }) => {
    if (typeof price === 'string') return renderNbsp(price);
    const currencySymbol = translate('/Price/CurrencySymbol');
    const formattedPrice = formatNumber(price, !showDecimals);
    return (
        <>
            {formattedPrice}&nbsp;{currencySymbol}
        </>
    );
};

Price.defaultProps = {
    showDecimals: true,
};

export const FromPrice = ({ price, numberOfVariants, lowercase }) => {
    let label = translate('/Product/From');
    if (lowercase) label = label.toLowerCase();
    return (
        <>
            {numberOfVariants > 1 && <>{label} </>}
            <Price price={price} />
        </>
    );
};

export const MemberPrice = ({ price, numberOfVariants }) => {
    return (
        <>
            <>{translate('/Product/Member')} </>
            <span className={styles.inlineBlock}>
                <FromPrice price={price} numberOfVariants={numberOfVariants} lowercase={true} />
            </span>
        </>
    );
};

export const priceShape = {
    adjustedPrice: PropTypes.string,
    adjustedPriceWithoutCurrency: PropTypes.number,
    basePrice: PropTypes.string,
    basePriceWithoutCurrency: PropTypes.number,
    isPriceAdjusted: PropTypes.bool,
    isMemberPrice: PropTypes.bool,
};
