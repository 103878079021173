import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import CategoryLink from 'Shared/CategoryLink';
import CategoryList, { getSwiperParams } from 'Shared/CategoryList';
import * as styles from './base.scss';
import * as baseStyles from 'Shared/CategoryList/base.scss';

const GlobalCategoryList = ({ categories, status, className, renderSwiper, isMobile }) => {
    if (status === 'PENDING') {
        return (
            <div className={cn(className, styles.categoryList, baseStyles.categoryList)}>
                <div className={styles.loader}></div>
            </div>
        );
    }

    if (renderSwiper) {
        return (
            <Swiper
                containerClass={cn(
                    className,
                    styles.categoryList,
                    baseStyles.categoryList,
                    styles.slider,
                    baseStyles.slider,
                )}
                {...getSwiperParams(isMobile ? 16 : 24)}
            >
                {renderCategories(categories)}
            </Swiper>
        );
    }

    return (
        <div className={cn(className, styles.categoryList, baseStyles.categoryList)}>
            {renderCategories(categories)}
        </div>
    );
};

const renderCategories = (categories) => {
    return categories.map((category, index) => (
        <CategoryLink key={index} className={baseStyles.category} {...category} />
    ));
};

GlobalCategoryList.propTypes = {
    ...CategoryList.propTypes,
    status: PropTypes.string,
    renderSwiper: PropTypes.bool,
    isMobile: PropTypes.bool,
};

export default connect(({ menu }) => ({
    categories: compileLinks(menu.commerceHierarchy),
    status: menu.status,
}))(GlobalCategoryList);

const compileLinks = (categories) => {
    let links = [];
    for (const category of categories) {
        for (const subCategory of category.children) {
            if (subCategory.name === 'Outlet') continue;
            links.push(subCategory);
        }
    }
    return links;
};
