import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import * as styles from './base.scss';
import * as sharedStyles from '../../shared.scss';
import { renderHtml } from 'Shared/text-formatting';

const LinkTable = ({ links, columns, max, className }) => {
    return <table className={cn(className, styles.linkTable)}>{renderTableBody(links, columns, max)}</table>;
};

const renderTableBody = (links, columns, max) => {
    const rows = nestArray(links, columns, max);
    return (
        <tbody>
            {rows.map((row, y) => (
                <tr key={y}>
                    {row.map((column, x) => (
                        <td key={x}>
                            <a className={sharedStyles.contentLink} href={column.url}>
                                {renderHtml(column.name)}
                            </a>
                        </td>
                    ))}
                </tr>
            ))}
        </tbody>
    );
};

const nestArray = (items, columns, max) => {
    let result = [];
    let currentRow = [];
    const length = Math.min(items.length, max);
    for (let i = 0; i < length; i++) {
        if (i % columns == 0) result.push(currentRow);
        currentRow.push(items[i]);
        if (i % columns == columns - 1) currentRow = [];
    }
    return result;
};

LinkTable.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            url: PropTypes.string,
        }),
    ).isRequired,
    columns: PropTypes.number,
    max: PropTypes.number,
    className: PropTypes.string,
};

LinkTable.defaultProps = {
    columns: 2,
};

export default LinkTable;
