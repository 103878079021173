import PropTypes from 'prop-types';

export const imageShape = {
    source: PropTypes.string.isRequired,
    sourceSet: PropTypes.string,
    alt: PropTypes.string.isRequired,
};

export const itemShape = {
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
    image: PropTypes.shape(imageShape),
    children: PropTypes.arrayOf(PropTypes.shape(itemShape)),
    hasProducts: PropTypes.bool.isRequired,
    isActive: PropTypes.bool.isRequired,
};

export const linkShape = {
    icon: PropTypes.string,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};
