import {
    SEARCH_BY_FILTERS_EXPAND,
    SEARCH_BY_FILTERS__SET_CURRENT_DEP_FILTERS,
    SEARCH_BY_FILTERS__SET_CURRENT_FAC_FILTERS,
} from 'Shared/action-types';

const initialState = {
    expanded: false,
    currentDepartmentFilters: [],
    currentFacilityFilters: [],
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SEARCH_BY_FILTERS_EXPAND:
            return Object.assign({}, state, {
                expanded: action.expand,
            });

        case SEARCH_BY_FILTERS__SET_CURRENT_DEP_FILTERS:
            return Object.assign({}, state, {
                currentDepartmentFilters: action.currentFilters,
            });

        case SEARCH_BY_FILTERS__SET_CURRENT_FAC_FILTERS:
            return Object.assign({}, state, {
                currentFacilityFilters: action.currentFilters,
            });
        default:
            return state;
    }
}
