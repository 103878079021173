import React from 'react';
import cn from 'classnames';
import * as styles from './base.scss';

const ProductLabel = ({ className, promotions, showDiscountOverlay, discountPercentage }) => {
    return (
        <>
            {(promotions || []).map((promotion, i) => {
                return (
                    <div key={i} className={cn(styles.productOverlay, className)}>
                        {typeof promotion.link === 'string' ? (
                            <a href={promotion.link}>{promotion.promotionName}</a>
                        ) : promotion.promotionName ? (
                            <span>{promotion.promotionName}</span>
                        ) : (
                            <span>{promotion}</span>
                        )}
                    </div>
                );
            })}
            {showDiscountOverlay && (
                <div className={styles.discountOverlay}>
                    <span>{formatDiscountPercentage(discountPercentage)}</span>
                </div>
            )}
        </>
    );
};

export const formatDiscountPercentage = (discountPercentage) => {
    return `-${discountPercentage}%`;
};

export default ProductLabel;
