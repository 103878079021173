export const emailPattern = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/i;
export const phonePattern = /^[49]{1}[0-9]{7}$/;

const trim = (input) => {
    return (input || '').trim();
};

export const empty = (input) => {
    return trim(input) === '';
};

export const email = (input) => {
    if (empty(input)) return false;
    return emailPattern.test(input);
};

export const phone = (input) => {
    if (empty(input)) return false;
    return phonePattern.test(input);
};
