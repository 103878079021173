import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'Shared/translate';
import classnames from 'classnames';
import CartAvailableIn from '../CartAvailableIn';
import { searchByQuery } from '../../../PayAndCollect/actions';
import * as styles from './base.scss';

export class Validation extends React.Component {
    render() {
        const { issues, onAvailabilityClick } = this.props;
        const showIssues = issues.length > 0;
        return (
            <div className={styles.validation}>
                {showIssues && this.renderIssues(issues)}
                <CartAvailableIn onAvailabilityClick={onAvailabilityClick} />
            </div>
        );
    }

    renderIssues = (issues) => {
        return (
            <ul className={classnames(styles.summary)}>
                {issues.map((issue, index) => (
                    <li className={styles.issue} key={index}>
                        <span className={styles.message}>{issue}</span>
                    </li>
                ))}
            </ul>
        );
    };
}

const validationErrorMessages = [
    translate('/Cart/Validation/CannotBeBoughtOnline'),
    translate('/Cart/Validation/CannotBeCollectedAtSelectedStore'),
    translate('/Cart/Validation/NotEnoughInStock'),
    translate('/Cart/Validation/SelectedStoreMissing'),
    translate('/Cart/Validation/EmptyStock'),
];

const summaryValidationErrorMessages = [
    translate('/Cart/Validation/Summary/CannotBeBoughtOnline'),
    translate('/Cart/Validation/Summary/CannotBeCollectedAtSelectedStore'),
    translate('/Cart/Validation/Summary/NotEnoughInStock'),
    translate('/Cart/Validation/Summary/SelectedStoreMissing'),
    translate('/Cart/Validation/Summary/SoldOut'),
];

const validationIssueMessages = [translate('/PayAndCollect/Delayed')];
const validationIssueKeys = ['', '/PayAndCollect/Delayed'];

const summaryValidationErrorKeys = [
    '',
    '/Cart/Validation/Summary/CannotBeBoughtOnline',
    '/Cart/Validation/Summary/CannotBeCollectedAtSelectedStore',
    '/Cart/Validation/Summary/NotEnoughInStock',
    '/Cart/Validation/Summary/SelectedStoreMissing',
    '/Cart/Validation/Summary/SoldOut',
];

const validationErrorKeys = [
    '',
    '/Cart/Validation/CannotBeBoughtOnline',
    '/Cart/Validation/CannotBeCollectedAtSelectedStore',
    '/Cart/Validation/NotEnoughInStock',
    '/Cart/Validation/SelectedStoreMissing',
    '/Cart/Validation/EmptyStock',
];

export const getValidationIssue = (issueCode) => {
    return translate(validationIssueKeys[issueCode]);
};

export const getValidationMessage = (errorCode, availableQuantity) => {
    let errorMessage = translate(validationErrorKeys[errorCode]);
    if (errorCode === 3 && availableQuantity > 0) {
        errorMessage = `${errorMessage} - ${translate('/Cart/Validation/ItemsRemain', availableQuantity)}`;
    }
    return errorMessage;
};

const getValidationIssues = (cart) => {
    const { items } = cart;
    let issues = [];

    for (let i = 1; i < summaryValidationErrorKeys.length; i++) {
        const count = countValidationIssues(items, i);
        if (count === 0) continue;
        issues.push(translate(summaryValidationErrorKeys[i]));
    }

    return issues;
};

const countValidationIssues = (cartItems, index) => {
    if (!cartItems) return 0;
    return cartItems.reduce((count, item) => count + (item.validationErrorCode === index ? 1 : 0), 0);
};

export default connect(
    (state) => ({
        breakpoint: state.breakpoint,
        issues: getValidationIssues(state.cart.data, state.payAndCollect),
    }),
    (dispatch) => ({
        searchByQuery: (...args) => dispatch(searchByQuery(...args)),
    }),
)(Validation);
