import React from 'react';
import classnames from 'classnames';
import * as styles from './base.scss';
import { elementProps, markProps } from '../props';

export default function Checkbox({ name, label, className, selected, onClick }) {
    return (
        <label className={classnames(styles.checkbox, className, { [styles.selected]: selected })}>
            <input name={name} type="checkbox" checked={selected} readOnly={true} onClick={onClick} />
            <span className={styles.mark}></span>
            <span>{label}</span>
        </label>
    );
}

Checkbox.propTypes = { ...elementProps, ...markProps };
