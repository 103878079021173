import React from 'react';
import { itemShape } from '../../propTypes';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Plus from 'Shared/Plus';
import * as styles from './base.scss';
import * as navStyles from '../MobileNav/base.scss';
import AutoHeight from 'Shared/AutoHeight';

export default class ExpandingLevel extends React.Component {
    static get propTypes() {
        return {
            className: PropTypes.string,
            wrapperClassName: PropTypes.string,
            item: PropTypes.shape(itemShape).isRequired,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    handleClick = (item, event) => {
        const hasChildren = item.children && item.children.length > 0;
        if (!hasChildren) return;

        event.preventDefault();
        this.handlePlusClick(event);
    };

    handlePlusClick = () => {
        const { expanded } = this.state;
        this.setState({ expanded: !expanded });
    };

    render() {
        const { item, className, wrapperClassName } = this.props;
        const { expanded } = this.state;
        const hasChildren = item.children && item.children.length > 0;
        return (
            <>
                <a
                    href={item.url}
                    title={item.title}
                    className={className}
                    {...(item.target && { target: item.target })}
                    onClick={(event) => this.handleClick(item, event)}
                >
                    <span>{item.title || item.name}</span>
                    {hasChildren && <Plus className={navStyles.linkIcon} open={expanded} />}
                </a>
                {hasChildren && (
                    <AutoHeight
                        className={classnames(styles.expand, wrapperClassName)}
                        forcedHeight={expanded ? null : 0}
                    >
                        <ul className={styles.expandList}>
                            {this.renderChild(item, 0)}
                            {item.children.map((child, index) => this.renderChild(child, index + 1))}
                        </ul>
                    </AutoHeight>
                )}
            </>
        );
    }

    renderChild(item, index) {
        return (
            <li className={styles.expandListItem} key={index}>
                <a href={item.url} title={item.title} {...(item.target && { target: item.target })}>
                    <span>{item.name}</span>
                </a>
            </li>
        );
    }
}
