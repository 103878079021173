import { normalizeProductId } from 'Shared/product-id';

export function getCurrentPosition(options) {
    const { geolocation } = window.navigator;
    if (!geolocation) return Promise.reject();
    return new Promise(function (resolve, reject) {
        geolocation.getCurrentPosition(resolve, reject, options);
    });
}

export function reduceQuantities(items) {
    let quantites = {};
    if (!items) return quantites;
    for (const item of items) {
        if (quantites[item.productId]) {
            quantites[item.productId] += item.quantity;
        } else {
            quantites[item.productId] = item.quantity;
        }
    }
    return quantites;
}

export function compileQuantities(cartItems, additionalCode) {
    const quantities = reduceQuantities(cartItems);
    if (!additionalCode) return quantities;

    const normalizedCode = normalizeProductId(additionalCode);

    if (quantities[normalizedCode]) {
        quantities[normalizedCode] += 1;
    } else {
        quantities[additionalCode] = 1;
    }

    return quantities;
}
