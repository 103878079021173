import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './base.scss';
import { resize } from 'Shared/url';
import { PriceArea } from '../../../Product/Components/PriceArea';
import { priceShape } from 'Shared/Price';
import Image from 'Shared/Image';

export default function Thumbnail({ displayName, imageUrls, price }) {
    if (!imageUrls || imageUrls.length < 1) return null;
    const url = imageUrls[0];
    return (
        <div className={styles.thumbnail}>
            <Image src={resize(url, 86, 74)} alt={displayName} width={86} height={74} />
            <div className={styles.priceArea}>{price && <PriceArea price={price} />}</div>
        </div>
    );
}

Thumbnail.propTypes = {
    displayName: PropTypes.string,
    imageUrls: PropTypes.arrayOf(PropTypes.string),
    price: PropTypes.shape(priceShape),
};

Thumbnail.defaultProps = {
    imageUrls: [],
    displayName: 'Product image',
};
