import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from 'Shared/store';
import { PriceArea } from './PriceArea';
import { getProps } from 'Shared/dom';
export { PriceArea };

export function embed(container) {
    const props = getProps(container);

    render(
        <Provider store={store}>
            <PriceArea noMargin={true} {...props} />
        </Provider>,
        container,
    );
}
