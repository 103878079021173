import {
    SEARCH_PENDING,
    SEARCH_FULFILL,
    SEARCH_REJECT,
    SEARCH_UPDATE,
    UPDATE_SEARCHQUERY,
    TOGGLE_QUICKSEARCH,
} from 'Shared/action-types';
import { postJson } from 'Shared/server.js';
import { quickSearch } from '../events';

export function reset() {
    return (dispatch) => {
        dispatch({
            type: SEARCH_UPDATE,
            results: [],
        });
        dispatch({
            type: UPDATE_SEARCHQUERY,
            searchQuery: '',
        });
    };
}

export function search(url, q, callback, signal) {
    return (dispatch, getState) => {
        if (q.length > 1) {
            dispatch({ type: SEARCH_PENDING });
            return postJson(url, { q }, null, null, signal).then(
                (res) => {
                    if (res && getState().searchResults.show) {
                        dispatch({ type: SEARCH_FULFILL });
                        dispatch({
                            type: SEARCH_UPDATE,
                            results: res,
                        });
                        quickSearch(q, res);
                    }
                    if (callback) callback();
                },
                () => {
                    dispatch({ type: SEARCH_REJECT });
                    if (callback) callback();
                },
            );
        } else if (!q) {
            dispatch({
                type: SEARCH_UPDATE,
                results: [],
            });
            return Promise.resolve();
        }
    };
}

export function toggleQuickSearch(show = false) {
    return (dispatch) => {
        dispatch({
            type: TOGGLE_QUICKSEARCH,
            show,
        });
    };
}

export function updateSearchQuery(query) {
    return (dispatch) => {
        dispatch({
            type: UPDATE_SEARCHQUERY,
            searchQuery: query,
        });
    };
}
