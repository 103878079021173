import { TOGGLE_MOBILE_NAVIGATION, NAVIGATION_UPDATE, NAVIGATION_REJECT } from '../action-types';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

const initialState = {
    showMobile: false,
    commerceHierarchy: window.MAIN_COMMERCE_NAVIGATION || {},
    roomsHierarchy: window.MAIN_ROOMS_NAVIGATION || {},
    cmsHierarchy: window.MAIN_CMS_NAVIGATION || {},
    links: window.LINKS || {},
    status: PENDING,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_MOBILE_NAVIGATION:
            return {
                ...state,
                showMobile: action.show,
            };
        case NAVIGATION_UPDATE:
            return {
                ...state,
                ...action.data,
                status: FULFILLED,
            };
        case NAVIGATION_REJECT:
            return {
                ...state,
                status: REJECTED,
            };
        default:
            return state;
    }
}
