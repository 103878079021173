import { NAVIGATION_REJECT, NAVIGATION_UPDATE, TOGGLE_MOBILE_NAVIGATION } from 'Shared/action-types';
import { getMenu } from './api';

export function toggleMobileNavigation(show = false) {
    return (dispatch) => {
        dispatch({
            type: TOGGLE_MOBILE_NAVIGATION,
            show,
        });
    };
}

export function loadMenu() {
    return (dispatch) => {
        return getMenu().then(
            (data) =>
                dispatch({
                    type: NAVIGATION_UPDATE,
                    data,
                }),
            (error) =>
                dispatch({
                    type: NAVIGATION_REJECT,
                    error,
                }),
        );
    };
}
