import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { imageCropShape } from './index';
import Hotspot from './hotspot';
import { useCurrentCrop } from '../Hooks/currentCrop';
import * as styles from './base.scss';

const { GetaImage } = window;
const imageProps = {
    crop: { x: 0, y: 0 },
    originalWidth: 0,
    originalHeight: 0,
    focalPoint: {
        width: 0,
        height: 0,
        x: 0,
        y: 0,
    },
};

function HotspotContainer({ crops, className }) {
    if (!GetaImage) return null;
    if (crops.length < 1) return null;
    const currentCrop = useCurrentCrop(crops);
    const { hotspots, aspectRatio } = currentCrop;
    const hasHotspots = hotspots.length > 0;
    const imageRef = useRef();
    const containerRef = useRef();

    const setupHotspots = () => {
        imageRef.current = new GetaImage(containerRef.current);
        imageRef.current.update();
    };

    const teardownHotspots = () => {
        if (imageRef.current) imageRef.current.destroy();
    };

    useEffect(() => {
        if (!hasHotspots) return;

        setupHotspots();
        return () => teardownHotspots();
    }, [currentCrop.mediaQuery]);

    if (hotspots.length < 1) return null;
    return (
        <div className={styles.getaImage}>
            <div
                ref={containerRef}
                className={classnames('geta-image', styles.hotspots, className)}
                data-geta-image={JSON.stringify(imageProps)}
                style={{ aspectRatio: `${aspectRatio}` }}
            >
                {hotspots.map((hotspot, index) => (
                    <Hotspot key={index} {...hotspot} />
                ))}
            </div>
        </div>
    );
}

HotspotContainer.propTypes = {
    crops: PropTypes.arrayOf(PropTypes.shape(imageCropShape)),
};

export default HotspotContainer;
