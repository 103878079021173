import React, { useState } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Quantity from '../Quantity';
import { update } from '../../actions';
import { groupCartItems } from '../../utils';
import { PENDING } from '../../reducer';
import * as styles from './base.scss';

function ItemQuantity({ item, cartItems, cartType, cartIsPending, update }) {
    if (!item) return null;
    const cartItem = groupCartItems(cartItems).find((x) => x.code === item.code);
    const [quantity, setQuantity] = useState(cartItem.quantity);
    const adjustQuantity = cartItem.quantity - cartItem.originalQuantity;
    const updateQuantity = (newQuantity) => {
        setQuantity(newQuantity);
        update(cartType, cartItem.lineId, newQuantity);
    };
    return (
        <Quantity
            className={styles.quantity}
            buttonClass={styles.quantityButton}
            labelClass={classnames(styles.quantityLabel, { [styles.pending]: cartIsPending })}
            iconClass="fa"
            adjustQuantity={adjustQuantity}
            quantity={quantity}
            min={1}
            onIncrement={() => updateQuantity(quantity + 1)}
            onDecrement={() => updateQuantity(quantity - 1)}
        />
    );
}

export default connect(
    ({ cart }) => ({
        cartType: cart.data.cartType,
        cartItems: cart.data.items,
        cartIsPending: cart.status === PENDING,
    }),
    (dispatch) => ({
        update: (...params) => dispatch(update(...params)),
    }),
)(ItemQuantity);
