import { SEARCH_BY_CITIES_AND_ZIP_EXPAND, SEARCH_BY_CITIES_AND_ZIP_SETADDRESS } from 'Shared/action-types';

const initialState = {
    expanded: false,
    selectedAddress: '',
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SEARCH_BY_CITIES_AND_ZIP_EXPAND:
            return Object.assign({}, state, {
                expanded: action.expand,
            });

        case SEARCH_BY_CITIES_AND_ZIP_SETADDRESS:
            return Object.assign({}, state, {
                selectedAddress: action.selectedAddress,
            });
        default:
            return state;
    }
}
