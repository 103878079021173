import React from 'react';
import { hotspotShape } from './index';
import { PriceArea } from '../../Product/Components/PriceArea/PriceArea';
import classnames from 'classnames';
import * as styles from './base.scss';

function Hotspot({ x, y, title, brand, price, numberOfVariants, url }) {
    return (
        <div
            className={classnames('geta-image__hotspot', styles.hotspot)}
            style={{ left: `${x * 100}%`, top: `${y * 100}%` }}
            data-geta-image__hotspot={JSON.stringify({ x, y })}
        >
            <div className="geta-image__hotspot__content" data-geta-image__hotspot__content>
                <div class="geta-image__hotspot__content__inner">
                    <aside class="hotspot-card">
                        {brand && <header class="hotspot-card__brand">{brand}</header>}
                        <section class="hotspot-card__main">
                            <h4 class="hotspot-card__heading">
                                {title}
                                {!price && <i class="fa fa-chevron-right"></i>}
                            </h4>
                            {price && (
                                <PriceArea
                                    className="hotspot-card__price"
                                    price={price}
                                    numberOfVariants={numberOfVariants}
                                />
                            )}
                        </section>
                        <a class="hotspot-card__overlay" href={url}>
                            {price && <i class="hotspot-card__link fa fa-chevron-right"></i>}
                        </a>
                    </aside>
                </div>
            </div>
            <button class="geta-image__hotspot__trigger" data-geta-image__hotspot__trigger>
                <i class="far fa-plus"></i>
            </button>
        </div>
    );
}

Hotspot.propTypes = hotspotShape;

export default Hotspot;