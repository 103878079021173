import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as styles from './base.scss';

export const starType = {
    filled: 'filled',
    half: 'half',
    empty: 'empty',
};

export const Star = ({ className, starClassName, type }) => {
    if (type === starType.filled) {
        return (
            <span className={classnames(className, styles.star)}>
                <i className={classnames(starClassName, styles.star_foreground, 'fas', 'fa-star')}></i>
            </span>
        );
    } else if (type === starType.half) {
        return (
            <span className={classnames(className, styles.star)}>
                <i className={classnames(styles.star_background, 'far', 'fa-star')}></i>
                <i
                    className={classnames(starClassName, styles.star_foreground, styles.star_half, 'fas', 'fa-star')}
                ></i>
            </span>
        );
    }
    return (
        <span className={classnames(className, styles.star)}>
            <i className={classnames(styles.star_background, 'far', 'fa-star')}></i>
        </span>
    );
};

Star.propTypes = {
    type: PropTypes.oneOf(Object.values(starType)),
    className: PropTypes.string,
    startClassName: PropTypes.string,
};

Star.defaultProps = {
    className: null,
    starClassName: null,
    type: starType.filled,
};

export default Star;
