import {
    LOGIN_PENDING,
    LOGIN_FULFILL,
    LOGIN_REJECT,
    LOGIN_UPDATE,
    CHANGE_BOOL,
    REGISTER_CLEAN,
    LOGIN_CLEAN,
    REGISTER_ENABLE_VALIDATION,
    LOGIN_HAS_ERROR,
    TOGGLE_DIMMED_AREA,
} from 'Shared/action-types';
import { postJson, getJson } from 'Shared/server.js';
import { updateCartInfo } from '../Cart/actions';

export function login(url, body) {
    return (dispatch) => {
        dispatch({ type: LOGIN_PENDING });

        return postJson(url, body).then(
            (data) => {
                if (data.success) {
                    return Promise.resolve(data);
                } else {
                    dispatch({ type: LOGIN_HAS_ERROR, data });
                    return Promise.reject(null);
                }
            },
            () => {
                dispatch({ type: LOGIN_REJECT });
                return Promise.reject(null);
            },
        );
    };
}

export function getAuthState() {
    return (dispatch) => {
        return getJson('/login/status').then(
            (data) => {
                dispatch({ type: LOGIN_UPDATE, data });
            },
            () => {
                dispatch({ type: LOGIN_REJECT });
                return Promise.reject(null);
            },
        );
    };
}

export function loginSuccess(data, location) {
    return (dispatch, getState) => {
        const resolveLoginSuccess = getState().login.resolveLoginSuccess;
        if (typeof resolveLoginSuccess === 'function') {
            resolveLoginSuccess(data);
            dispatch({
                type: TOGGLE_DIMMED_AREA,
                show: false,
            });
        }
        dispatch({ type: LOGIN_FULFILL });

        if (data.cart) {
            dispatch(updateCartInfo(JSON.parse(data.cart)));
        }

        if (resolveLoginSuccess === false) {
            if (location === window.LOGIN_URL) {
                document.location = data.returnUrl;
            } else {
                document.location.reload();
            }
        }
        dispatch({
            type: TOGGLE_DIMMED_AREA,
            show: false,
        });
    };
}

export function loginOrRegistration(isNewAccount) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_BOOL,
            isNewAccount: isNewAccount,
        });

        dispatch({ type: REGISTER_CLEAN });
        dispatch({ type: LOGIN_CLEAN });
    };
}

export function updateFields(data) {
    return (dispatch) => {
        dispatch({ type: LOGIN_UPDATE, data: data });
    };
}

export function enableValidation() {
    return (dispatch) => {
        dispatch({ type: REGISTER_ENABLE_VALIDATION });
    };
}
