import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Image from 'Shared/Image';
import * as styles from './base.scss';
import { renderHtml } from 'Shared/text-formatting';
import { resize } from 'Shared/url';
import { SMALL } from 'Shared/breakpoints';

export const cardSize = {
    small: 'small',
    medium: 'medium',
};

const Card = ({ name, url, imageUrl, size, className, isMobile, renderChildren, children }) => {
    const [width, height] = getSize(size, isMobile);
    const hasImage = Boolean(imageUrl);
    const renderImage = hasImage || size == cardSize.small;
    const useSmallHeading = size == cardSize.small;
    const renderContent = renderChildren === undefined ? size == cardSize.medium : renderChildren;
    return (
        <div className={cn(styles.card, className, styles[size])}>
            {renderImage && (
                <div className={cn(styles.imageContainer, { [styles.placeholder]: !hasImage })}>
                    {hasImage && <Image src={resize(imageUrl, width, height, 'crop')} />}
                </div>
            )}
            <div className={cn(styles.contentContainer, styles[size], { [styles.hasImage]: renderImage })}>
                <h4 className={cn(styles.title, { ['h2-format']: !useSmallHeading })}>{renderHtml(name)}</h4>
                {renderContent && children}
            </div>
            <a href={url} className={styles.link} />
        </div>
    );
};

const getSize = (size, isMobile) => {
    switch (size) {
        case cardSize.small:
            return [138, 86];
        case cardSize.medium:
            // TODO: Increase mobile resolution, keep aspect
            return isMobile ? [363, 245] : [237, 218];
    }
};

Card.propTypes = {
    name: PropTypes.string,
    url: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.oneOf([cardSize.small, cardSize.medium]),
    renderChildren: PropTypes.bool,
    isMobile: PropTypes.bool,
};

Card.defaultProps = {
    size: cardSize.small,
};

export default connect(({ breakpoint }, ownProps) => {
    return {
        ...ownProps,
        isMobile: breakpoint < SMALL,
    };
})(Card);
