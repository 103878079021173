import { ORDER_HISTORY_UPDATE, ORDER_HISTORY_PENDING, ORDER_HISTORY_REJECT } from 'Shared/action-types';

export const PENDING = 'PENDING';
export const REJECTED = 'REJECTED';
export const UPDATING = 'UPDATING';
export const FULFILLED = 'FULFILLED';

const pageState = window.ORDER_HISTORY || {};

const initialState = {
    ...pageState,
    status: '',
    loading: false,
    error: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ORDER_HISTORY_PENDING:
            return { ...state, status: PENDING, loading: true, error: false };

        case ORDER_HISTORY_REJECT:
            return { ...state, status: REJECTED, loading: false, error: true };

        case ORDER_HISTORY_UPDATE:
            return { ...state, ...action.data, status: FULFILLED, loading: false };

        default:
            return state;
    }
}
