import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Card from '../Card';
import Swiper from 'react-id-swiper';
import * as styles from './base.scss';
import { translate } from 'Shared/translate';
import { cardSize } from '../Card';
import { getSwiperParams } from 'Shared/CategoryList';

const StoreList = ({ stores, className, renderSwiper }) => {
    if (renderSwiper)
        return (
            <Swiper containerClass={cn(className, styles.storeList, styles.slider)} {...getSwiperParams()}>
                {renderStores(stores)}
            </Swiper>
        );
    return <div className={cn(className, styles.storeList)}>{renderStores(stores)}</div>;
};

const renderStores = (stores) => {
    return stores.map((store, index) => {
        if (!store) return null;
        const { streetAddress, zipCode, city, email, telephone } = store;
        const hasContactInfo = Boolean(email) || Boolean(telephone);
        const size = cardSize.small;
        return (
            <Card key={index} className={styles.store} size={size} renderChildren={true} {...store}>
                <p>
                    {streetAddress}
                    <br />
                    {zipCode} {city}
                </p>
                {hasContactInfo && size !== cardSize.small && (
                    <div className={styles.contact}>
                        <h5 className={styles.title}>{translate('/Header/Search/ContactInformation')}:</h5>
                        <p className={styles.detail}>
                            {telephone && (
                                <>
                                    {translate('/Header/Search/Phone')}: <a href={`tel:${telephone}`}>{telephone}</a>
                                </>
                            )}
                            <br />
                            {email && (
                                <>
                                    {translate('/Header/Search/Email')}: <a href={`mailto:${email}`}>{email}</a>
                                </>
                            )}
                        </p>
                    </div>
                )}
            </Card>
        );
    });
};

StoreList.propTypes = {
    stores: PropTypes.arrayOf(
        PropTypes.shape({
            ...Card.propTypes,
            streetAddress: PropTypes.string,
            zipCode: PropTypes.string,
            city: PropTypes.string,
            email: PropTypes.string,
            telephone: PropTypes.string,
        }),
    ).isRequired,
    className: PropTypes.string,
    renderSwiper: PropTypes.bool,
};

export default StoreList;
