import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as styles from './base.scss';
import { resize } from 'Shared/url';
import { priceShape } from 'Shared/Price';

function ResponsiveImage({ crops, fallbackImageUrl, dispatch, className, ...imageProps }) {
    if (crops.length < 1) return null;
    const getSourceSet = (crop) => {
        const { imageUrl, sourceSet } = crop;
        return sourceSet.map((source) => {
            return `${resize(imageUrl, source.width, source.height)} ${source.width}w`;
        });
    };
    const defaultCrop = crops.find((x) => x.isDefault === true) || crops.find(() => true);
    const defaultSource = defaultCrop ? defaultCrop.imageUrl : null;
    const defaultAlt = defaultCrop ? defaultCrop.imageAlt : null;
    return (
        <picture className={classnames(className, { [styles.noImage]: !defaultSource })}>
            {crops.map((crop, index) => (
                <source key={index} media={crop.mediaQuery} srcSet={getSourceSet(crop)} sizes={crop.sizes} />
            ))}
            <img src={defaultSource} alt={defaultAlt} loading="lazy" {...imageProps} />
        </picture>
    );
}

ResponsiveImage.propTypes = {
    className: PropTypes.string,
    crops: PropTypes.arrayOf(PropTypes.shape(imageCropShape)),
};

ResponsiveImage.defaultProps = {
    crops: [],
};

export const imageCropShape = {
    imageUrl: PropTypes.string.isRequired,
    imageAlt: PropTypes.string,
    isDefault: PropTypes.bool,
    aspectRatio: PropTypes.number,
    sizes: PropTypes.arrayOf(PropTypes.string),
    sourceSet: PropTypes.arrayOf(PropTypes.shape(imageSizeShape)),
    hotspots: PropTypes.arrayOf(PropTypes.shape(hotspotShape)),
};

export const imageSizeShape = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number,
};

export const hotspotShape = {
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    brand: PropTypes.string,
    price: PropTypes.shape(priceShape),
    numberOfVariants: PropTypes.number,
    url: PropTypes.string.isRequired,
};

export default connect(
    ({ knownUrls }) => ({
        fallbackImageUrl: knownUrls.fallbackImageUrl,
    }),
    null,
)(ResponsiveImage);
