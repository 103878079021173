import {
    REGISTER_PENDING,
    REGISTER_FULFILL,
    REGISTER_REJECT,
    REGISTER_UPDATE,
    REGISTER_CHANGE,
    REGISTER_CLEAN,
    REGISTER_HAS_ERROR,
} from 'Shared/action-types';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

const pageState = window.REGISTER || {};
const defaultState = {
    status: '',
    data: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        consentMembershipTerms: false,
        consentInfoViaSms: false,
        consentInfoViaEmail: false,
        consentThirdPartyTracking: false,
    },
    unsavedChanges: false,
    loading: false,
    error: false,
};

const initialState = {
    ...defaultState,
    data: {
        ...defaultState.data,
        ...pageState,
    },
};

export default function register(state = initialState, action) {
    switch (action.type) {
        case REGISTER_CHANGE:
            return {
                ...state,
                data: { ...state.data, ...action.data },
                unsavedChanges: true,
                error: false,
            };
        case REGISTER_PENDING:
            return {
                ...state,
                status: PENDING,
                loading: true,
                error: false,
            };
        case REGISTER_FULFILL:
            return {
                ...state,
                status: FULFILLED,
                loading: false,
            };
        case REGISTER_REJECT:
            return {
                ...state,
                status: REJECTED,
                loading: false,
            };
        case REGISTER_UPDATE:
            return {
                ...state,
                data: { ...state.data, ...action.data },
                loading: false,
                unsavedChanges: false,
            };
        case REGISTER_HAS_ERROR: {
            return {
                ...state,
                error: true,
                loading: false,
            };
        }
        case REGISTER_CLEAN:
            return { ...initialState };
        default:
            return state;
    }
}
