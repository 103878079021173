import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from '../store';
import Navigation from './Components/Nav';

export function embed(container) {
    render(
        <Provider store={store}>
            <Navigation />
        </Provider>,
        container,
    );
}
