import React from 'react';
import Button from './index';
import PropTypes from 'prop-types';
export { appearanceTypes } from './index';

const status = {
    DEFAULT: 'DEFAULT',
    PENDING: 'PENDING',
    FULFILL: 'FULFILL',
    REJECTED: 'REJECTED',
};

const MINIMUM_PENDING = 800;

export class FeedbackProvider extends React.Component {
    static defaultProps = {
        minimumPending: MINIMUM_PENDING,
        resetTimeout: 2000,
    };

    componentDidMount() {
        this.mounted = true;
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    state = { status: status.DEFAULT };

    activate = (activePromise) => {
        this.setState({ status: status.PENDING });
        if (!activePromise) activePromise = new Promise(() => true);
        return new Promise((resolveRequest, rejectRequest) => {
            minimumPendingPromise(activePromise.then((data) => Promise.resolve(data)))
                .then((requestData) => {
                    this.onPromise(status.FULFILL);
                    timeout(this.props.delayPromiseResolveMs).then(() => {
                        resolveRequest(requestData);
                    });
                })
                .catch((e) => {
                    this.onPromise(status.REJECTED);
                    rejectRequest(e);
                });
        });
    };

    onButtonClick = (event) => {
        return this.activate(this.props.onClick(event));
    };

    onPromise(newStatus) {
        this.mounted && this.setState({ status: newStatus });
        timeout(this.props.resetTimeout).then(() => {
            this.mounted && this.setState({ status: status.DEFAULT });
        });
    }

    renderButton = (buttonProps) => {
        return (
            <Button
                {...buttonProps}
                isPending={this.state.status === status.PENDING}
                isRejected={this.state.status === status.REJECTED}
                isFulfill={this.state.status === status.FULFILL}
                isFeedbackButton
            />
        );
    };

    render() {
        const { onClick, isPending, resetTimeout, minimumPending, delayPromiseResolveMs, ...restProps } = this.props;

        return this.props.render({
            onClick: this.onButtonClick,
            activate: this.activate,
            Button: this.renderButton,
            ...restProps,
        });
    }
}

FeedbackProvider.propTypes = {
    minimumPending: PropTypes.number, // Pending state will be at least this long even if request is faster
    delayPromiseResolveMs: PropTypes.number, // After request is resolved we will wait this time until we resolve
    resetTimeout: PropTypes.number, // Time to wait after request resolve until we go back to default state
    render: PropTypes.func,
};

function timeout(ms) {
    return new Promise((resolve) => (ms > 0 ? setTimeout(resolve, ms) : resolve()));
}

export function minimumPendingPromise(promise, minimumPending = MINIMUM_PENDING) {
    return Promise.all([promise, timeout(minimumPending)]).then(([requestData, timoutData]) =>
        Promise.resolve(requestData),
    );
}

export default function FeedbackButton(props) {
    return (
        <FeedbackProvider
            {...props}
            render={(feedbackProps) => {
                const { Button, activate, render, ...restProps } = feedbackProps;
                return <Button {...restProps} />;
            }}
        />
    );
}
