import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';
import Image from 'Shared/Image';
import Swiper from 'react-id-swiper';
import * as styles from './base.scss';
import * as sharedStyles from '../../shared.scss';
import { resize } from 'Shared/url';
import { translate } from 'Shared/translate';
import { PriceArea } from '../../../../Product/Components/PriceArea';
import { productClicked } from '../../../events';

const ProductList = ({ products, isMobile, centeredList }) => {
    let { items } = products;
    const { totalMatching, allResultsUrl } = products;

    if (totalMatching < 1) return null;
    return (
        <div className={styles.productList}>
            {renderList(items, isMobile, centeredList)}
            {totalMatching > items.length && (
                <div className={styles.totalLink}>
                    <a className={sharedStyles.allResultsLink} href={allResultsUrl}>
                        {translate('/Header/Search/ShowAllProducts')}&nbsp;({totalMatching})
                    </a>
                </div>
            )}
        </div>
    );
};

const renderList = (items, isMobile, centeredList) => {
    if (!isMobile) {
        return renderDesktopList(items, centeredList);
    }

    if (items.length > 4) {
        items = items.slice(0, 4);
    }

    return <ul className={cn(styles.items)}>{items.map((item) => renderListItem(item, true))}</ul>;
};

const onProductClick = (product) => {
    productClicked(product);
};

const renderListItem = (item, isMobile) => {
    const width = isMobile ? 50 : 163;
    const height = isMobile ? 60 : 107;
    return (
        <li key={item.code} className={styles.item}>
            <a className={styles.link} onClick={() => onProductClick(item)} href={item.url}>
                {renderThumbnail(item, width, height)}
                {!isMobile && renderBrand(item)}
                <div>
                    <LinesEllipsis
                        component={'span'}
                        className={cn(styles.name, {
                            [styles.narrow]: item.numberOfVariants > 1,
                        })}
                        text={item.displayName}
                        maxLine="2"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                    />
                    {isMobile && renderDescription(item.numberOfVariants)}
                </div>
                {renderPrice(item.price, item.numberOfVariants, isMobile)}
            </a>
        </li>
    );
};

const renderBrand = (item) => {
    const { brand } = item;
    return <div className={styles.brand}>{brand}</div>;
};

const renderDesktopList = (items, centeredList) => {
    if (centeredList) {
        return (
            <div className={cn(styles.items, styles.slider, styles.center)}>
                {items.map((item) => renderListItem(item))}
            </div>
        );
    }
    return <Swiper {...getSwiperParams()}>{items.map((item) => renderListItem(item))}</Swiper>;
};

const renderPrice = (price, variants, isMobile) => {
    if (!price) return null;
    return (
        <span className={styles.price}>
            <PriceArea
                price={price}
                numberOfVariants={variants}
                alignRight={isMobile}
                alignLeft={!isMobile}
                noMargin={true}
            />
        </span>
    );
};

const renderThumbnail = (item, width, height) => {
    return (
        <Image
            className={styles.image}
            src={resize(item.image, width, height, 'pad')}
            alt={item.displayName}
            width={width}
            height={height}
        />
    );
};

const renderDescription = (variants) => {
    if (variants < 2) return null;
    return <span className={styles.description}>{translate('/Category/Product/VariantsCountShort', variants)}</span>;
};

const getSwiperParams = () => {
    return {
        containerClass: cn(styles.items, styles.slider),
        slidesPerView: 'auto',
        spaceBetween: 16,
        rebuildOnUpdate: true,
        shouldUpdate: true,
        loop: false,
    };
};

ProductList.propTypes = {
    products: PropTypes.shape({
        totalMatching: PropTypes.number,
        allProductsUrl: PropTypes.string,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                code: PropTypes.string.isRequired,
                url: PropTypes.string.isRequired,
                displayName: PropTypes.string,
            }),
        ).isRequired,
    }).isRequired,
    centeredList: PropTypes.bool,
    isMobile: PropTypes.bool,
};

export default ProductList;
