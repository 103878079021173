import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Product from '../../Category/Components/ProductList/product';
import FadeContainer from 'Shared/FadeContainer';
import { productClick } from './events';
import { startCapital } from 'Shared/text-formatting';
import * as styles from './base.scss';

export const layoutStyles = {
    horizontal: styles.horizontalScroll,
    vertical: styles.verticalScroll,
};

function RelatedItems({
    heading,
    items,
    className,
    headingClassName,
    itemClassName,
    layoutStyle,
    maxHeight,
    showInGrid,
}) {
    const renderWrapper = (items, layoutStyle) => {
        if (layoutStyle === layoutStyles.vertical) {
            return (
                <FadeContainer className={styles.items} scrollClassName={styles.scroll}>
                    {renderItems(items)}
                </FadeContainer>
            );
        }
        return <div className={styles.items}>{renderItems(items, layoutStyle)}</div>;
    };
    const renderItems = (items) => {
        return items.map((item) => renderItem(item));
    };
    const renderItem = (item) => {
        return (
            <Product
                key={item.code}
                className={classnames(styles.item, itemClassName)}
                {...item}
                contentBlock={false}
                hideVariants={true}
                onClick={() => productClick(item)}
                inRelatedItems={true}
            />
        );
    };
    return (
        <div
            className={classnames(styles.related, className, layoutStyle, {
                [styles.grid]: showInGrid,
            })}
            style={{ maxHeight }}
        >
            {heading && <h3 className={classnames(styles.heading, headingClassName)}>{startCapital(heading)}</h3>}
            {renderWrapper(items, layoutStyle)}
        </div>
    );
}

RelatedItems.propTypes = {
    heading: PropTypes.string,
    className: PropTypes.string,
    headingClassName: PropTypes.string,
    layoutStyle: PropTypes.oneOf(Object.values(layoutStyles)),
    items: PropTypes.arrayOf(
        PropTypes.shape({
            code: PropTypes.string.isRequired,
        }),
    ),
};

RelatedItems.defaultProps = {
    layoutStyle: layoutStyles.horizontal,
};

export default RelatedItems;
