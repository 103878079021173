import { RECOMMENDATIONS_PENDING, RECOMMENDATIONS_REJECTED, RECOMMENDATIONS_UPDATE } from 'Shared/action-types';
import { postJson } from 'Shared/server';
import { widgetIsRegistered, anyWidgetIsRegistered } from '../Recommendations/widgets';
import store from 'Shared/store';

export function updateRecommendations(position, recommendations) {
    const registered = widgetIsRegistered(position);
    const shouldPopulateAny = anyWidgetShouldPopulate(position);
    if (!registered && !shouldPopulateAny) return;
    store.dispatch({ type: RECOMMENDATIONS_PENDING });
    return postJson('/RecommendationsApi/GetProducts', { recommendations }).then(
        (data) => store.dispatch({ type: RECOMMENDATIONS_UPDATE, position, data }),
        () => store.dispatch({ type: RECOMMENDATIONS_REJECTED }),
    );
}

function anyWidgetShouldPopulate(position) {
    if (position === 'searchWidget') return false;
    if (!anyWidgetIsRegistered()) return false;
    const { recommendations } = store.getState();
    const { any } = recommendations.widgets;
    return any.length < 1;
}
