/** Initializes the Flowbox Embed into the #js-flowbox-flow div */
if (window.flowboxApiKey) {
    window.flowbox('init', {
        container: '#js-flowbox-flow',
        key: window.flowboxApiKey,
        tags: [],
        locale: 'nb-NO',
    });
}

/**  Defines a function that takes a tag and updates the Flow
 * (using the same key as the script in our HTML code)
 */

function updateFlow(tag) {
    window.flowbox('update', {
        key: window.flowboxApiKey,
        tags: [tag],
    });
}

// Gets all our buttons
const buttons = document.querySelectorAll('button[data-tag]');

// Function for looping through all buttons and clearing their classes
function clearButtonClasses() {
    for (const button of buttons) {
        button.classList.remove('active');
    }
}

// Loops through all our buttons and adds click handlers
for (const button of buttons) {
    button.addEventListener('click', () => {
        // When clicking the button, use its data-tag attriibute to update the Flow
        updateFlow(button.dataset.tag);
        clearButtonClasses();
        button.classList.add('active');
    });
}
