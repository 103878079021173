import { RECENTLYADDED_UPDATE, RECENTLYADDED_CLEAR } from 'Shared/action-types';

export const RECENTLYADDED_TIMER = 5000;

export const messageTypes = {
    mobileAddedToCart: 'mobileAddedToCart',
};

export function clearRecentlyAddedMessage() {
    return (dispatch) => {
        dispatch({
            type: RECENTLYADDED_CLEAR,
        });
    };
}

let timer = null;
export function updateRecentlyAddedMessage(recentlyAddedText, recentlyAddedButtonText = '', recentlyAddedUrl = '') {
    return (dispatch) => {
        dispatch({
            type: RECENTLYADDED_UPDATE,
            recentlyAddedText,
            recentlyAddedButtonText,
            recentlyAddedUrl,
        });

        if (timer) {
            clearTimeout(timer);
            timer = null;
        }

        timer = setTimeout(() => {
            dispatch(clearRecentlyAddedMessage());
        }, RECENTLYADDED_TIMER);
    };
}
