import { getJson } from 'Shared/server';

export const getStore = (storeId) => {
    return getJson(`/storeApi/details?id=${storeId}`);
};

// Note: this method exists due to variation of property names in endpoint data
export const mapStoreResponse = (response) => {
    return {
        storeName: response.name,
        phone: response.contactInformation.telephon,
        email: response.contactInformation.email,
        addressStreet: response.streetAddress,
        url: response.url,
        openHours: response.openingHours,
        warehouseOpenHours: response.warehouseOpeningHours,
        collectionLocationNotification: response.collectionLocationNotification
    };
};

// Note: this method exists due to variation property names in endpoint data
export const mapLowerCaseSerializedStore = (store) => {
    if (!store) return null;
    return {
        storeName: store.name,
        phone: store.contactInformation && store.contactInformation.telephon,
        email: store.contactInformation && store.contactInformation.email,
        addressStreet: store.streetAddress,
        url: store.url,
        orgUnitNumber: store.orgUnitNumber,
        openHours: store.openingHours,
        warehouseOpenHours: store.warehouseOpeningHours,
        collectionLocationNotification: store.collectionLocationNotification
    };
};
