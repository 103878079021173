import React from 'react';
import InfoCircle from 'Shared/InfoCircle';
import { translate } from 'Shared/translate';
import * as styles from '../base.scss';

export const paymentOptionIds = {
    card: 'card',
    vipps: 'vipps',
    invoice: 'invoice',
    installments: 'svea',
    postponed: 'postponed',
};

export const getSelections = (props) => {
    return props.paymentOptions.map((option) => {
        switch (option.id) {
            case paymentOptionIds.card:
                return {
                    ...option,
                    leftContent: <>{translate('/Checkout/Payment/PaymentOptionCard')}</>,
                    rightContent: (
                        <span>
                            <img className={styles.paymentLogo} src={require('Styles/Images/Visa_logo.svg')} />
                            <img
                                className={styles.paymentLogo}
                                src={require('Styles/Images/Mastercard_logo.svg')}
                            />
                        </span>
                    ),
                    openContent: null,
                };

            case paymentOptionIds.vipps:
                return {
                    ...option,
                    leftContent: <>{translate('/Checkout/Payment/PaymentOptionVipps')}</>,
                    rightContent: (
                        <img className={styles.paymentLogo} src={require('Styles/Images/Vipps_logo.svg')} />
                    ),
                    openContent: null,
                };

            case paymentOptionIds.invoice:
                return {
                    ...option,
                    leftContent: (
                        <>
                            <div className={styles.selection}>
                                {translate('/Checkout/Payment/PaymentOptionInvoice')}
                                <span className={styles.details}>
                                    <span className={styles.hyphen}>-</span>
                                    {translate('/Checkout/Payment/PaymentOptionInvoice/Details')}&nbsp;&nbsp;
                                    <InfoCircle
                                        id="invoice"
                                        message={translate('/Checkout/Payment/Tooltip/Instabank/Invoice')}
                                    />
                                </span>
                            </div>
                        </>
                    ),
                    rightContent: (
                        <img className={styles.paymentLogo} src={require('Styles/Images/logo-instabank.svg')} />
                    ),
                    openContent: null,
                };

            case paymentOptionIds.installments:
                return {
                    ...option,
                    
                    leftContent: (
                        <>
                            <div className={styles.selection}>
                                {translate('/Checkout/Payment/PaymentOptionInstallments')}
                                <span className={styles.details}>
                                  {/*  <span className={styles.hyphen}>-</span>*/}
                                    {translate('/Checkout/Payment/PaymentOptionInstallments/Details')}&nbsp;&nbsp;
                                    <InfoCircle
                                        id="installments"
                                        message={translate('/Checkout/Payment/Tooltip/Instabank/Installments')}
                                    />
                                </span>
                            </div>
                        </>
                    ),
                    rightContent: (
                        <img className={styles.paymentLogo} src={require('Styles/Images/logo-svea.png')} />
                    ),
                    openContent: null,
                };

            case paymentOptionIds.postponed:
                return {
                    ...option,
                    leftContent: (
                        <>
                            {translate('/Checkout/Payment/PaymentOptionPostponed')}
                            <span className={styles.details}>
                                <span className={styles.hyphen}>-</span>
                                {translate('/Checkout/Payment/PaymentOptionPostponed/Details')}&nbsp;&nbsp;
                                <InfoCircle
                                    id="postponed"
                                    message={translate('/Checkout/Payment/Tooltip/Instabank/Postponement')}
                                />
                            </span>
                        </>
                    ),
                    rightContent: (
                        <img className={styles.paymentLogo} src={require('Styles/Images/logo-instabank.svg')} />
                    ),
                    openContent: null,
                };

            default:
                return option;
        }
    });

}