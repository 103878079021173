import { write } from './dom';
import store from './store';
import { clearRecentlyAddedMessage } from './RecentlyAddedMessage/actions';
import { toggleQuickSearch, reset as resetQuickSearch } from '../Search/SearchBar/actions';
import { toggleMiniCart } from '../Cart/actions';
import { email } from 'Shared/text-validation';

export const setIcon = (target, oldValue, newValue) => {
    if (target !== null) {
        target.classList.remove(oldValue);
        target.classList.add(newValue);
    }
};

export const hideMarketLanguageSelectors = () => {
    const elements = ['.jsLanguageSelector', '.jsMarketSelector'];

    elements.map((element) => {
        const domElement = document.querySelector(element);

        if (domElement) {
            const menu = domElement.getElementsByTagName('ul');
            if (menu) {
                write(() => {
                    menu[0].style.display = 'none';
                });
            }

            setIcon(document.querySelector(`${element} .icon`), 'arrow-up', 'arrow-down');
        }
    });
};

/**
usage:
componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
}
componentWillUnmout() {
    document.removeEventListener('mousedown', this.handleClickOutside);
}
setWrapperRef = (node) => {
    this.wrapperRef = node;
}
handleClickOutside = outsideClickHandler(this, (evt) => {
    this.closeDropdown();
})
*/
export const outsideClickHandler = (comp, handler) => {
    return (evt) => {
        if (comp.wrapperRef && !comp.wrapperRef.contains(evt.target)) {
            if (typeof handler == 'function') {
                handler(evt);
            }
        }
    };
};

export const emailValidation = (value) => {
    return email(value);
};

export const hightlightCart = () => {
    const cartButton = document.querySelector('.miniCart-area button');
    const debounceMs = 100;

    cartButton.classList.add('highlight');

    setTimeout(() => {
        cartButton.classList.remove('highlight');
    }, debounceMs);
};

export const hideAllOverlays = (except) => {
    hideMarketLanguageSelectors();
    if (except != 'QUICK_SEARCH') store.dispatch(toggleQuickSearch(false));
    if (except != 'QUICK_SEARCH') store.dispatch(resetQuickSearch());
    //if (except != 'SORT_AREA') store.dispatch(toggleExpandSortArea(false));
    //if (except != 'FILTER_AREA') store.dispatch(toggleExpandFilterArea(false));
    if (except != 'RA_MESSAGE') store.dispatch(clearRecentlyAddedMessage());
    if (except != 'MINI_CART') store.dispatch(toggleMiniCart(false));
    //if (except != 'STORE_SELECTOR') has no external action;
    //if (except != 'LOGIN') has no external action;
};
