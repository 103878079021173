import { PAYMENT_SET, PAYMENT_UPDATE } from 'Shared/action-types';
import { paymentOptionIds } from './Util/paymentOptionsUtil';

const availablePaymentMethods = window.PAYMENT_METHODS || {};
const checkoutInfo = window.CHECKOUT_INFO;
const paymentSettings = window.PAYMENT_SETTINGS || {};
const initialCartTotal =
    checkoutInfo && checkoutInfo.checkoutInfo && checkoutInfo.checkoutInfo.shoppingCart
        ? checkoutInfo.checkoutInfo.shoppingCart.totalAmount
        : 0;

const paymentOptions = [
    {
        id: paymentOptionIds.card,
        isAvailableKey: 'hasCreditCardPayment',
    },
    {
        id: paymentOptionIds.vipps,
        isAvailableKey: 'hasVippsPayment',
    },
    {
        id: paymentOptionIds.invoice,
        isAvailableKey: 'hasInvoicePayment',
        isAvailable: (total) => total >= 200,
        show: paymentSettings.InstabankEnableInvoice,
    },
    {
        //commented by aziz for svea installment
        //id: paymentOptionIds.installments,
        //isAvailableKey: 'hasInvoicePayment',
        //isAvailable: (total) => total >= 2000,
        //aziz

        id: paymentOptionIds.installments,
        isAvailableKey: 'hasSveaPayment',
        isAvailable: (total) => total >= 2000,
        show: paymentSettings.InstabankEnableFinance,
    },
    {
        id: paymentOptionIds.postponed,
        isAvailableKey: 'hasInvoicePayment',
        isAvailable: (total) => total >= 2000,
        show: paymentSettings.EnablePostponement,
    },
];

const getPaymentOptions = (total) => {
    return paymentOptions
        .filter((option) => !!availablePaymentMethods[option.isAvailableKey])
        .filter((option) => !option.isAvailable || option.isAvailable(total) && option.show)
        .map((option, index) => ({ ...option, isSelected: index === 0 }));
};

export const initialState = {
    paymentOptions: getPaymentOptions(initialCartTotal),
};

const getSelectedId = (state) => {
    if (!state.paymentOptions) return null;
    var selected = state.paymentOptions.find((x) => x.isSelected);
    if (!selected) return null;
    return selected.id;
};

export default function paymentReducer(state = initialState, action) {
    switch (action.type) {
        case PAYMENT_UPDATE: {
            const selectedId = getSelectedId(state);
            return {
                ...state,
                paymentOptions: getPaymentOptions(action.total).map((option) => ({
                    ...option,
                    isSelected: option.id === selectedId,
                })),
            };
        }
        case PAYMENT_SET:
            return {
                ...state,
                paymentOptions: state.paymentOptions.map((option) => ({
                    ...option,
                    isSelected: option.id === action.selectedId,
                })),
            };

        default:
            return state;
    }
}
