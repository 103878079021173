import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as styles from './switch.scss';
import { elementProps } from '../props';

export default class Switch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: props.selected,
        };
    }

    componentDidUpdate(prevProps) {
        const { selected, name } = this.props;
        const stateSelected = this.state.selected;
        if (prevProps.selected !== selected && selected !== stateSelected) {
            this.setState({ selected });
        }
    }

    handleChange = (event) => {
        const { onChange, name, readOnly } = this.props;
        if (readOnly) return;

        const { selected } = this.state;
        const newValue = !selected;

        this.setState({ selected: newValue });
        if (onChange) onChange(name, newValue);
    };

    render() {
        const { name, className, readOnly } = this.props;
        const { selected } = this.state;
        return (
            <label className={classnames(styles.switch, className, { [styles.locked]: readOnly })}>
                <input name={name} type="checkbox" checked={selected} readOnly={true} onClick={this.handleChange} />
                <span className={classnames(styles.slider, styles.round, { [styles.locked]: readOnly })}></span>
            </label>
        );
    }
}

Switch.propTypes = { ...elementProps, readOnly: PropTypes.bool };
