const prefixFilter = /[PV]_/;
const dataLayer = window.dataLayer;
const currency = 'NOK';

// BRANDS
// =====================
export const brandViewEventV4 = (data) => {
    if (!data || !data.brand) return;
    customDataLayerPushV4('brand_impression', data);
};

// MENU
// =====================

export const menuClickEventV4 = (data) => {
    const { name } = data;
    customDataLayerPushV4('desktop_menu_section_click', { section: name });
};

export const hamburgerClickEventV4 = () => {
    customDataLayerPushV4('mobile_menu_hamburger_click', {});
};

export const quickSearchFocusEventV4 = () => {
    customDataLayerPushV4('quicksearch_focus', {});
};

// CATEGORY
// =====================
export const categoryViewEventV4 = (data) => {
    if (!data || !data.categoryIdentifier) return;
    dataLayerPushV4('view_item_list', categoryMapper(data));
};

export const categoryFilterEventV4 = (data) => {
    if (!data || !data.group || !data.key) return;
    if (!data.selected) return; // Don't track when filter is toggled off

    customDataLayerPushV4('category_filter', data);
};

export const categoryFilterClickEventV4 = () => {
    customDataLayerPushV4('category_filter_click', {});
};

export const categoryExpandMenuClickEventV4 = () => {
    customDataLayerPushV4('category_expand_left_menu_click', {});
};

export const categorySortClickEventV4 = () => {
    customDataLayerPushV4('category_sort_click', {});
};

export const categorySortEventV4 = (data) => {
    const { text } = data;
    if (!text) return;
    customDataLayerPushV4('category_sort', { sort: text });
};

export const categoryImageTypeEventV4 = (type) => {
    customDataLayerPushV4('category_image_type', { type });
};

// ADD/REMOVE FROM CART DATA LAYER
// ================================
export const addToCartEventV4 = (addedProduct, quantity, callback) => {
    dataLayerPushV4('add_to_cart', addToCartMapper(addedProduct, quantity));
};

export const removeFromCartEventV4 = (removedProduct, callback) => {
    dataLayerPushV4('remove_from_cart', removeFromCartMapper(removedProduct));
};

export const viewCartV4 = (cart) => {
    dataLayerPushV4('view_cart', viewCartMapper(cart));
};

// PRODUCT'S DATA LAYERS
// =====================
export const productClickEventV4 = (clickedProduct, listName, listId, callback) => {
    dataLayerPushV4('select_item', selectItemMapper(clickedProduct, listName, listId));
};

export const productDetailViewEventV4 = (viewedProduct, callback) => {
    dataLayerPushV4('view_item', viewItemMapper(viewedProduct));
};

export const productVariantSelectorClickEventV4 = () => {
    customDataLayerPushV4('product_variant_selector_click', {});
};

export const productPayAndCollectButtonClickEventV4 = () => {
    customDataLayerPushV4('product_pay_and_collect_button_click', {});
};

export const productChangeStoreClickEventV4 = () => {
    customDataLayerPushV4('product_change_store_click', {});
};

export const productReadMoreClickEventV4 = () => {
    customDataLayerPushV4('product_read_more_click', {});
};

export const productImageThumbnailClickEventV4 = () => {
    customDataLayerPushV4('product_image_thumbnail_click', {});
};

export const productImageZoomEventV4 = () => {
    customDataLayerPushV4('product_image_zoom', {});
};

export const productInfoViewEventV4 = (data) => {
    const { id } = data;
    customDataLayerPushV4('product_info_view', { section: id });
};

export const productArCabableV4 = () => {
    customDataLayerPushV4('product_ar_capable', {});
};

export const productArActivateV4 = () => {
    customDataLayerPushV4('product_ar_activate', {});
};

export const productArModalOpenV4 = () => {
    customDataLayerPushV4('product_ar_modal_open', {});
};

// CHECKOUT DATA LAYER
// ======================
export const checkoutEventV4 = (step, data, callback) => {
    let eventName = 'checkout_step';
    let checkoutData = {
        ...checkoutMapper(data),
        step,
    };

    switch (step) {
        case 0: // View page
            eventName = 'begin_checkout';
            break;

        case 1: // Cart step
            eventName = 'checkout_cart_details';
            break;

        case 2: // Contact information
            eventName = 'checkout_contact_information';
            break;

        case 3: // Delivery step
            eventName = 'add_shipping_info';
            checkoutData = {
                ...checkoutData,
                shipping_tier: getSelectedShippingMethods(data).join(','),
            };
            break;

        case 4: // Payment step
            eventName = 'add_payment_info';
            checkoutData = {
                ...checkoutData,
                payment_type: getSelectedPaymentMethod(data),
            };
            break;

        //OrderComplete To be Added By Aziz
    }
    dataLayerPushV4(eventName, checkoutData);
};

export const checkoutUserLoginEventV4 = () => {
    customDataLayerPushV4('checkout_user_login', {});
};

export const checkoutTypeChangeEventV4 = (type) => {
    customDataLayerPushV4('checkout_type_change', { type });
};

export const checkoutAvailabilityClickEventV4 = () => {
    customDataLayerPushV4('checkout_cart_availability_click', {});
};

// PURCHASE DATA LAYER
// ======================
export const purchaseEventV4 = (orderData, callback) => {

    if (!orderData) return;
    const { referenceId, totalWithoutCurrency, taxTotalWithoutCurrency, chargeWithoutCurrency, customerDetails, items } = orderData;
    const purchaseData = {
        send_to: 'GA4',
        transaction_id: referenceId,
        value: totalWithoutCurrency,
        tax: taxTotalWithoutCurrency,
        shipping: chargeWithoutCurrency,
        currency,
        coupon: '',
        custemail: customerDetails.email,
        custphoneno: customerDetails.phone,
        custname: customerDetails.name,
        items: items.map(cartPurchaseMapper),
    };
    dataLayerPushV4('purchase', purchaseData);
};

// HELPER METHODS
// ======================
const dataLayerPushV4 = (eventName, data) => {
    let dataLayerObject = {
        event: eventName,
        ecommerce: data,
    };
    //console.info('dataLayerV4', dataLayerObject);
    if (dataLayer) {
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        dataLayer.push(dataLayerObject);
    }
};

const customDataLayerPushV4 = (eventName, data) => {
    const dataLayerObject = {
        ...data,
        event: eventName,
    };
    //console.info('custom dataLayerV4', dataLayerObject);
    dataLayer && dataLayer.push(dataLayerObject);
};

const getSelectedShippingMethods = (checkoutData) => {
    if (!checkoutData || !checkoutData.deliveryMethod) return [];
    const { strategies, deliveries, services } = checkoutData.deliveryMethod;
    let selections = [];

    for (const strategy of strategies) {
        const { selected, mappings } = strategy;
        if (!selected) continue;
        for (const mapping of mappings) {
            const { salesLineMappings } = mapping;
            for (const code of Object.values(salesLineMappings)) {
                tryAdd(code, selections);
            }
        }
    }

    for (const delivery of deliveries) {
        const { options } = delivery;
        for (const option of options) {
            const { selected, code, nextDeliveryOverrideCode } = option;
            if (!selected) continue;
            tryAdd(code, selections);
            tryAdd(nextDeliveryOverrideCode, selections);
        }
    }

    for (const code of services) {
        tryAdd(code, selections);
    }

    selections.sort();

    return selections;
};

const getSelectedPaymentMethod = (checkoutData) => {
    return checkoutData.paymentMethod.paymentOptions.filter((m) => m.isSelected)[0].id;
};

const tryAdd = (value, array) => {
    if (!value) return;
    if (!array) return;
    if (array.indexOf(value) > -1) return;
    array.push(value);
};

const addToCartMapper = (localProductObj, quantity) => {
    return {
        send_to: 'GA4',
        currency,
        value: localProductObj.price.isPriceAdjusted
            ? localProductObj.price.adjustedPriceWithoutCurrency
            : localProductObj.price.basePriceWithoutCurrency,
        items: [
            {
                item_id: localProductObj.code.replace(prefixFilter, ''),
                item_name: emptyStringInsteadOfUndefined(localProductObj.displayName),
                discount: localProductObj.price.discount,
                item_brand: emptyStringInsteadOfUndefined(localProductObj.brand),
                item_category: emptyStringInsteadOfUndefined(localProductObj.categorySegments[0]),
                item_category2: emptyStringInsteadOfUndefined(localProductObj.categorySegments[1]),
                item_category3: emptyStringInsteadOfUndefined(localProductObj.categorySegments[2]),
                item_category4: emptyStringInsteadOfUndefined(localProductObj.categorySegments[3]),
                item_category5: emptyStringInsteadOfUndefined(localProductObj.categorySegments[4]),
                item_variant: emptyStringInsteadOfUndefined(localProductObj.variant),
                price: localProductObj.price.basePriceWithoutCurrency,
                quantity: emptyStringInsteadOfUndefined(quantity),
            },
        ],
    };
};

const removeFromCartMapper = (localProductObj) => {
    return {
        send_to: 'GA4',
        items: [
            {
                item_id: localProductObj.productId,
                item_name: localProductObj.productName,
                price: localProductObj.priceIncDiscountWithoutCurrency,
                quantity: emptyStringInsteadOfUndefined(localProductObj.quantity),
            },
        ],
    };
};

const viewCartMapper = (localCartObj) => {
    return {
        send_to: 'GA4',
        currency,
        value: localCartObj.totalAmount,
        items: localCartObj.items.map((item) => ({
            item_id: item.code.replace(prefixFilter, ''),
            item_name: emptyStringInsteadOfUndefined(item.productName),
            discount: item.discountAmount,
            item_brand: emptyStringInsteadOfUndefined(item.brand),
            item_category: arrayValueOrEmptyString(item.categorySegments, 0),
            item_category2: arrayValueOrEmptyString(item.categorySegments, 1),
            item_category3: arrayValueOrEmptyString(item.categorySegments, 2),
            item_category4: arrayValueOrEmptyString(item.categorySegments, 3),
            item_category5: arrayValueOrEmptyString(item.categorySegments, 4),
            item_variant: emptyStringInsteadOfUndefined(item.variant),
            price: item.priceInclDiscount,
            quantity: 1,
        })),
    };
};

const checkoutMapper = (localCheckoutObj) => {
    return {
        send_to: 'GA4',
        currency,
        value: localCheckoutObj.products.totalAmount,
        coupon: '',
        items: localCheckoutObj.products.items.map((item) => ({
            item_id: item.code,
            item_name: emptyStringInsteadOfUndefined(item.productName),
            discount: item.discountAmount / item.quantity, // NOTE: This discount is expected to be "per unit" in GA4,
            item_brand: emptyStringInsteadOfUndefined(item.brand),
            item_category: arrayValueOrEmptyString(item.categorySegments, 0),
            item_category2: arrayValueOrEmptyString(item.categorySegments, 1),
            item_category3: arrayValueOrEmptyString(item.categorySegments, 2),
            item_category4: arrayValueOrEmptyString(item.categorySegments, 3),
            item_category5: arrayValueOrEmptyString(item.categorySegments, 4),
            item_variant: emptyStringInsteadOfUndefined(item.code),
            price: item.priceInclDiscount / item.quantity, // NOTE: This price is expected to be "per unit" in GA4
            quantity: item.quantity,
        })),
    };
};

const categoryMapper = (localCategoryObj) => {
    return {
        item_list_id: emptyStringInsteadOfUndefined(localCategoryObj.categoryIdentifier),
        item_list_name: emptyStringInsteadOfUndefined(localCategoryObj.pageTitle),
        items: localCategoryObj.productViewModels.map((item) => ({
            item_id: item.code.replace(prefixFilter, ''),
            item_name: emptyStringInsteadOfUndefined(item.displayName),
            discount: item.price.discount,
            item_brand: emptyStringInsteadOfUndefined(item.brand),
            item_category: emptyStringInsteadOfUndefined(item.categorySegments[0]),
            item_category2: emptyStringInsteadOfUndefined(item.categorySegments[1]),
            item_category3: emptyStringInsteadOfUndefined(item.categorySegments[2]),
            item_category4: emptyStringInsteadOfUndefined(item.categorySegments[3]),
            item_category5: emptyStringInsteadOfUndefined(item.categorySegments[4]),
            item_variant: emptyStringInsteadOfUndefined(item.variant),
            price: item.price.basePriceWithoutCurrency,
            quantity: 1,
        })),
    };
};

const cartPurchaseMapper = (localProductObj) => {
    const quantity = localProductObj.quantity || 1;
    return {
        // Name or ID is required.
        item_name: localProductObj.displayName,
        item_id: localProductObj.productId,
        price: localProductObj.priceIncDiscountWithoutCurrency / quantity,
        coupon: emptyStringInsteadOfUndefined(localProductObj.couponCode),
        quantity,
    };
};

const selectItemMapper = (localProductObj, listName, listId) => {
    let mappedObject = {
        item_list_id: emptyStringInsteadOfUndefined(listId),
        item_list_name: emptyStringInsteadOfUndefined(listName),
        items: [
            {
                item_id: localProductObj.code.replace(prefixFilter, ''),
                item_name: emptyStringInsteadOfUndefined(localProductObj.displayName),
                discount: localProductObj.price.discount,
                item_brand: emptyStringInsteadOfUndefined(localProductObj.brand),
                item_variant: emptyStringInsteadOfUndefined(localProductObj.variant),
                price: localProductObj.price.basePriceWithoutCurrency,
                quantity: 1,
            },
        ],
    };

    if (localProductObj.categorySegments) {
        mappedObject.item_category = emptyStringInsteadOfUndefined(localProductObj.categorySegments[0]);
        mappedObject.item_category2 = emptyStringInsteadOfUndefined(localProductObj.categorySegments[1]);
        mappedObject.item_category3 = emptyStringInsteadOfUndefined(localProductObj.categorySegments[2]);
        mappedObject.item_category4 = emptyStringInsteadOfUndefined(localProductObj.categorySegments[3]);
        mappedObject.item_category5 = emptyStringInsteadOfUndefined(localProductObj.categorySegments[4]);
    }

    return mappedObject;
};

const viewItemMapper = (localProductObj) => {
    return {
        send_to: 'GA4',
        currency,
        value: localProductObj.price.isPriceAdjusted
            ? localProductObj.price.adjustedPriceWithoutCurrency
            : localProductObj.price.basePriceWithoutCurrency,
        items: [
            {
                item_id: localProductObj.code.replace(prefixFilter, ''),
                item_name: emptyStringInsteadOfUndefined(localProductObj.displayName),
                discount: localProductObj.price.discount,
                item_brand: emptyStringInsteadOfUndefined(localProductObj.brand),
                item_category: emptyStringInsteadOfUndefined(localProductObj.categorySegments[0]),
                item_category2: emptyStringInsteadOfUndefined(localProductObj.categorySegments[1]),
                item_category3: emptyStringInsteadOfUndefined(localProductObj.categorySegments[2]),
                item_category4: emptyStringInsteadOfUndefined(localProductObj.categorySegments[3]),
                item_category5: emptyStringInsteadOfUndefined(localProductObj.categorySegments[4]),
                item_variant: emptyStringInsteadOfUndefined(localProductObj.variant),
                price: localProductObj.price.basePriceWithoutCurrency,
                quantity: 1,
            },
        ],
    };
};

const arrayValueOrEmptyString = (property, index) => {
    if (typeof property === 'undefined' || property === null) {
        return '';
    }

    let value = property[index];

    return typeof value === 'undefined' ? '' : value;
};

const emptyStringInsteadOfUndefined = (value) => {
    return typeof value === 'undefined' ? '' : value;
};
