import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Card from '../Card';
import Swiper from 'react-id-swiper';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import { renderHtml } from 'Shared/text-formatting';
import { getSwiperParams } from 'Shared/CategoryList';

const CardList = ({ cards, className, renderSwiper }) => {
    if (renderSwiper)
        return (
            <Swiper containerClass={cn(className, styles.cardList, styles.slider)} {...getSwiperParams()}>
                {renderCards(cards)}
            </Swiper>
        );
    return <div className={cn(className, styles.cardList)}>{renderCards(cards)}</div>;
};

const renderCards = (cards) => {
    return cards.map((card, index) => (
        <Card key={index} className={styles.card} {...card}>
            {renderHtml(card.description, { className: styles.truncate }, 'p')}
            <p className={styles.link}>{translate('/Header/Search/ReadMore')}</p>
        </Card>
    ));
};

CardList.propTypes = {
    cards: PropTypes.arrayOf(
        PropTypes.shape({
            ...Card.propTypes,
            description: PropTypes.string,
        }),
    ).isRequired,
    className: PropTypes.string,
    renderSwiper: PropTypes.bool,
};

export default CardList;
