import React from 'react';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import { injectHtml } from 'Shared/text-formatting';

import * as styles from './base.scss';

const ProductBadge = ({ className, isComingSoon, isNew, isCustomerFavorite, alwaysLowPrice }) => {
    const contents = isComingSoon
        ? translate('/Product/SneakPeek')
        : isNew
        ? translate('/Product/New')
        : isCustomerFavorite
        ? translate('/Product/Bestseller')
        : null;

    return (
        contents && (
            <div className={classnames(styles.circleOverlay, styles.large, className)}>
                <span dangerouslySetInnerHTML={injectHtml(contents)} />
            </div>
        )
    );
};

export default ProductBadge;
