import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { getProps } from 'Shared/dom';
import store from 'Shared/store';
import ContentBlock from './';

export function embed(container) {
    const props = getProps(container);
    render(
        <Provider store={store}>
            <ContentBlock {...props} />
        </Provider>,
        container,
    );
}
