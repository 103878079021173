import React from 'react';
import classnames from 'classnames';
import { elementProps, inputProps } from '../props';
import * as styles from './input.scss';

export default class Input extends React.Component {
    constructor(props) {
        super(props);
        this.changeTimeoutId = null;
        this.state = {
            value: this.getValue(props),
            changed: false,
        };
    }

    getValue = (props) => {
        const { prefix, defaultValue } = props;
        if (!prefix) return defaultValue;
        if (!defaultValue) return '';
        if (defaultValue.indexOf(prefix) === 0) return defaultValue.substr(prefix.length);
        return defaultValue;
    };

    handleChange = (event) => {
        const { value } = event.target;
        const prevValue = this.state.value;

        if (value !== prevValue) {
            this.setState({ value, changed: true });
            this.persistDebounce();
        }
    };

    handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            this.persistChange();
        }
    };

    handleBlur = () => {
        this.persistChange();
    };

    clearPersistTimeout = () => {
        if (!this.changeTimeoutId) return;

        clearTimeout(this.changeTimeoutId);
        this.changeTimeoutId = null;
    };

    persistDebounce = () => {
        this.clearPersistTimeout();

        const { changeTimeout } = this.props;
        this.changeTimeoutId = setTimeout(this.persistChange, changeTimeout);
    };

    persistChange = () => {
        const { name, onChange } = this.props;
        const { value, changed } = this.state;

        this.clearPersistTimeout();

        if (changed) {
            if (onChange) onChange(name, value);
            this.setState({ changed: false });
        }
    };

    render() {
        const {
            name,
            error,
            label,
            className,
            prefix,
            readOnly,
            onChange,
            onBlur,
            changeTimeout,
            type,
            ...inputProps
        } = this.props;
        return (
            <div className={classnames(styles.wrapper, className)}>
                <label className={styles.label} label-for={name}>
                    {label}
                </label>
                <span className={classnames(styles.container, { [styles.error]: error, [styles.readOnly]: readOnly })}>
                    {prefix && <span className={styles.prefix}>{prefix}</span>}
                    <input
                        id={name}
                        name={name}
                        type={type}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        onBlur={this.handleBlur}
                        aria-label={name}
                        readOnly={readOnly}
                        {...inputProps}
                    />
                </span>
                {this.renderError()}
            </div>
        );
    }

    renderError() {
        const { error } = this.props;
        return (
            <span className={classnames(styles.errorMessage, { [styles.visible]: error })}>
                {this.renderMessage(error)}
            </span>
        );
    }

    renderMessage(message) {
        if (message) return message;
        else return <>&nbsp;</>;
    }
}

Input.propTypes = { ...elementProps, ...inputProps };
Input.defaultProps = {
    changeTimeout: 2000,
    type: 'input',
};
