import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { outsideClickHandler } from 'Shared/customfunctions';
import AutoHeight from 'Shared/AutoHeight';
import { itemShape } from '../../propTypes';
import { debounce } from 'Shared/debounce';
import Thumbnail from '../Thumbnail';
import { PENDING } from '../../reducer';
import * as styles from './base.scss';
import { menuClickDesktop } from '../../events';

// Note: This delay is in sync with the local variable '$transition-time-opacity'
// used under the class 'megaMenu', found in /styles/components/_header.scss
const opactityTiming = 300;

const deriveState = (nextProps) => {
    const { commerceHierarchy, roomsHierarchy, cmsHierarchy, status } = nextProps;
    return { hierarchy: commerceHierarchy.concat(roomsHierarchy).concat(cmsHierarchy), status };
};

export default class DesktopNav extends React.Component {
    static get propTypes() {
        return {
            commerceHierarchy: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
            roomsHierarchy: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
            cmsHierarchy: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
            isMobileTablet: PropTypes.bool,
            breakpoint: PropTypes.number,
        };
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (state.status === nextProps.status) return null;
        return deriveState(nextProps);
    }

    constructor(props) {
        super(props);
        this.state = {
            ...deriveState(props),
            activeContent: null,
            activeMenu: null,
            open: false,
            hide: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmout() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    handleItemClick = (event, item, index) => {
        const { activeMenu, activeContent } = this.state;

        if (index === activeMenu) return;

        // In this case we first want to open the menu
        // allowing children to show.
        event.preventDefault();
        
        menuClickDesktop(item);

        if (activeContent === null) {
            const { status, loadMenu } = this.props;
            if (status === PENDING) {
                loadMenu();
            }
            this.setState({ activeMenu: index, activeContent: index, open: true, hide: false });
        } else {
            this.setState({ activeMenu: index, open: true, hide: true });
            this.setStateDebounced({ activeContent: index, hide: false });
        }
    };

    handleClickOutside = outsideClickHandler(this, () => {
        this.setState({ activeMenu: null, open: false });
        this.setStateDebounced({ activeContent: null });
    });

    setStateDebounced = debounce(opactityTiming, this.setState);

    getActiveContent = () => {
        const { activeContent } = this.state;
        return this.getHierarchy(activeContent);
    };

    getHierarchy = (index) => {
        const { hierarchy } = this.state;
        if (index === null) return null;
        if (typeof index !== 'number') return null;
        return hierarchy[index];
    };

    formatItemName(name) {
        return name;
    }

    render() {
        const { commerceHierarchy, roomsHierarchy, cmsHierarchy } = this.props;
        const { hierarchy } = this.state;
        const commerceItems = commerceHierarchy.length;
        const roomItems = roomsHierarchy.length;
        const items = hierarchy.length;

        if (items < 1) return null;
        return (
            <div ref={this.setWrapperRef}>
                <ul className="mainMenu">
                    {commerceHierarchy.map((item, index) => this.renderMegaMenuItem(item, index))}
                    {roomsHierarchy.map((item, index) => this.renderMegaMenuItem(item, commerceItems + index))}
                    {cmsHierarchy.map((item, index) => this.renderSimpleItem(item, commerceItems + roomItems + index))}
                </ul>
                {this.renderMegaMenu()}
            </div>
        );
    }

    renderMegaMenu() {
        const { status } = this.props;
        const selectedItem = this.getActiveContent();
        const renderContents = selectedItem !== null;
        const renderLoading = renderContents && status === PENDING;
        const items = (selectedItem && selectedItem.children) || [];
        const hasImages = items.some((i) => i.image);
        const { open, hide } = this.state;
        return (
            <AutoHeight
                forceHeight={open ? null : 0}
                className={classnames('megaMenu', { open: open, hasImages: hasImages })}
            >
                <div className={classnames('megaMenuContent', { hide: !open || hide })}>
                    {renderLoading && this.renderLoading()}
                    {items && items.map((item, index) => this.renderMegaMenuTextColumn(item, index, !hasImages))}
                </div>
            </AutoHeight>
        );
    }

    renderLoading() {
        return <div className={styles.loading}></div>;
    }

    renderMegaMenuTextColumn(item, index, renderChildren) {
        const { title, name } = item;
        const children = item.children || [];
        return (
            <div key={index}>
                <h4>{title || name}</h4>
                {this.renderImage(item, 180, 130)}
                <ul>
                    {this.renderListItem(0, item)}
                    {renderChildren && children.map((child, index) => this.renderListItem(index + 1, child))}
                </ul>
            </div>
        );
    }

    renderImage(item, width, height) {
        const { image, url } = item;
        return <Thumbnail image={image} url={url} width={width} height={height} className="thumbnail" />;
    }

    renderListItem(key, item) {
        const { url, name } = item;
        return (
            <li key={key}>
                <a href={url}>{name}</a>
            </li>
        );
    }

    renderMegaMenuItem(item, index) {
        const { activeMenu } = this.state;
        return (
            <li key={index}>
                <a
                    className={classnames({ active: item.isActive, open: activeMenu === index })}
                    href={item.url}
                    title={item.title || item.name}
                    onClick={(event) => this.handleItemClick(event, item, index)}
                >
                    {item.name}
                </a>
            </li>
        );
    }

    renderSimpleItem(item, index) {
        return (
            <li key={index}>
                <a
                    className={classnames({ active: item.isActive })}
                    href={item.isActive ? null : item.url}
                    title={item.title || item.name}
                >
                    {item.name}
                </a>
            </li>
        );
    }
}
