import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from '../../../store';
import Hamburger from './hamburger';

export function embed(container) {
    render(
        <Provider store={store}>
            <Hamburger />
        </Provider>,
        container,
    );
}
