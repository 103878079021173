import React from 'react';
import { translate } from 'Shared/translate';
import PropTypes from 'prop-types';

export const descriptorShape = PropTypes.shape({
    sizes: PropTypes.number,
    colors: PropTypes.number,
    styles: PropTypes.number,
});

export const seeMoreVariantsLabel = (descriptor, variationCount) => {
    if (descriptor) {
        const { colors, styles, sizes } = descriptor;

        if (colors > 1 && styles > 1) return translate('/Product/MoreStylesCount', colors - 1, styles);
        if (colors > 1 && sizes > 1) return translate('/Product/MoreSizesCount', colors - 1, sizes);
    }

    if (variationCount > 2) return translate('/Product/MoreVariantsCount', variationCount - 1);

    return translate('/Product/MoreVariants');
};

export const moreVariantsLabel = (descriptor, variationCount) => {
    if (descriptor) {
        const { colors, styles, sizes } = descriptor;

        if (colors > 1 && styles > 1) return translate('/Category/Product/ColorStylesCount', colors, styles);
        if (colors > 1 && sizes > 1) return translate('/Category/Product/ColorSizesCount', colors, sizes);
        if (sizes > 1) return translate('/Category/Product/SizesCount', sizes);
    }

    if (variationCount > 2) return translate('/Category/Product/VariantsCount', variationCount);

    return translate('/Category/Product/VariantsExist');
};

const VariantsLabel = ({ descriptor, variations }) => {
    if (!shouldRender(descriptor, variations)) return null;
    return <>{moreVariantsLabel(descriptor, variations)}</>;
};

const shouldRender = (descriptor, variations) => {
    if (!descriptor) return false;
    const { colors, styles, sizes } = descriptor;
    if (colors > 1 && styles > 1) return true;
    if (colors > 1 && sizes > 1) return true;
    if (variations > 1) return true;
    return false;
};

VariantsLabel.propTypes = {
    descriptor: descriptorShape,
    variations: PropTypes.number,
};

export default VariantsLabel;
