import {
    DISPLAY_PAY_AND_COLLECT,
    HIDE_PAY_AND_COLLECT,
    PAY_AND_COLLECT_STORE_UPDATE,
    PAY_AND_COLLECT_SEARCH,
    PAY_AND_COLLECT_SEARCH_COMPLETE,
    PAY_AND_COLLECT_SEARCH_ERROR,
} from 'Shared/action-types';
import { mapLowerCaseSerializedStore } from './api';

export const initialCount = 3;
export const overloadCount = initialCount + 1;

const pageState =
    window.PAY_AND_COLLECT ||
    (window.CHECKOUT_INFO && {
        selectedStoreDetails: mapLowerCaseSerializedStore(window.CHECKOUT_INFO.checkoutInfo.storeDetails),
    }) ||
    {};

export const initialState = {
    code: null,
    availableIn: null,
    orderOnline: false,
    stores: pageState.stores || [],
    selectedStoreDetails: pageState.selectedStoreDetails || null,
    query: pageState.query || '',
    usingBrowserPosition: pageState.usingBrowserPosition || false,
    isModalOpen: false,
    isStoreLoaded: false,
    isLoading: false,
    loaded: false,
    loadMore: pageState.loadMore || false,
    more: false,
    requestedCount: 0,
    errorMessage: '',
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case DISPLAY_PAY_AND_COLLECT:
            return {
                ...state,
                ...action,
                isModalOpen: true,
                usingStoresWithStock: !action.showAllStores,
                usingStoresWithDisplay: action.showOnDisplay,
            };
        case PAY_AND_COLLECT_STORE_UPDATE: {
            return {
                ...state,
                selectedStoreDetails: action.store,
                isStoreLoaded: true,
            };
        }
        case HIDE_PAY_AND_COLLECT:
            return {
                ...state,
                isModalOpen: false,
            };
        case PAY_AND_COLLECT_SEARCH:
            return {
                ...state,
                ...setupInventory(state, action),
                isLoading: true,
                loaded: false,
                errorMessage: '',
                requestedCount: action.count,
                more: false,
            };
        case PAY_AND_COLLECT_SEARCH_COMPLETE:
        case PAY_AND_COLLECT_SEARCH_ERROR:
            const data = { ...action.data };
            return {
                ...state,
                ...populateInventory(state, data.storeInventories),
                availableIn: data.totalCount,
                errorMessage: data.errorMessage,
                loaded: true,
                isLoading: false,
            };
        default:
            return state;
    }
}

function setupInventory(state, action) {
    const { stores } = state;
    if (action.count === undefined) {
        return { stores: [...stores] };
    }
    return { stores: [...stores] };
}

function populateInventory(state, stores) {
    if (!stores) return { more: false, stores: [] };
    if (stores.length < overloadCount) return { more: false, stores };
    if (isUndefined(state.requestedCount)) return { more: false, stores };
    return { more: true, stores: stores.slice(0, initialCount) };
}

function isUndefined(value) {
    if (value === undefined) return true;
    if (value === null) return true;
    return false;
}
