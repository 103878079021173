import { FINANCING_UPDATE, FINANCING_ERROR } from 'Shared/action-types';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

const defaultState = null;

export const initialState = {
    status: PENDING,
    data: window.FINANCING || defaultState,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case FINANCING_UPDATE:
            return {
                status: FULFILLED,
                data: { ...(action.data && action.data.calculation) },
            };
        case FINANCING_ERROR:
            return {
                status: REJECTED,
                data: null,
            };
        default:
            return state;
    }
}
