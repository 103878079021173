import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from 'Shared/store';
import MiniCart from './MiniCart';
import CommerceEnabled from 'Shared/CommerceEnabled';

export function embed(container) {
    const checkouturl = container.dataset.checkouturl;
    render(
        <CommerceEnabled>
            <Provider store={store}>
                <MiniCart checkouturl={checkouturl} />
            </Provider>
        </CommerceEnabled>,
        container,
    );
}
