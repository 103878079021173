import { CATEGORYPAGE_UPDATE, CATEGORYPAGE_UPDATE_PRODUCTLIST } from 'Shared/action-types';

export const PENDING = 'PENDING';
export const UPDATING = 'UPDATING';
export const FAILED = 'FAILED';

const pageState = window.CATEGORY_DATA;
const menuState = window.MENU_DATA;

const initialState = {
    data: pageState || {},
    products: pageState ? pageState.productViewModels : [],
    banners: pageState ? pageState.promotionBannerViewModels : [],
    checkoutUrl: window.CHECKOUTURL,
    menu: menuState || [],
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CATEGORYPAGE_UPDATE:
        case CATEGORYPAGE_UPDATE_PRODUCTLIST:
            return { ...state, ...action };
        default:
            return state;
    }
}
