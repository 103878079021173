import { dispatchEvent, addListener, removeListener } from 'Shared/events';

const updatedEventName = 'onCartUpdate';
const clearedEventName = 'onCartClear';

export function cartUpdated(data) {
    dispatchEvent(updatedEventName, data);
}

export function cartCleared(data) {
    dispatchEvent(clearedEventName, data);
}

export function registerCartEvents(updateCallback, clearCallback) {
    addListener(updatedEventName, updateCallback);
    addListener(clearedEventName, clearCallback ? clearCallback : updateCallback);
}

export function unregisterCartEvents(updateCallback, clearCallback) {
    removeListener(updatedEventName, updateCallback);
    removeListener(clearedEventName, clearCallback ? clearCallback : updateCallback);
}
