import { BRANDLIST_UPDATE, BRANDLIST_FILL } from 'Shared/action-types';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

const initialState = {
    allBrandsList: [],
    currentBrandsList: [],
};

export default function register(state = initialState, action) {
    switch (action.type) {
        case BRANDLIST_UPDATE:
            return Object.assign({}, state, {
                currentBrandsList: action.currentBrandsList,
            });
        case BRANDLIST_FILL:
            return Object.assign({}, state, {
                allBrandsList: action.allBrandsList,
            });
        default:
            return state;
    }
}
