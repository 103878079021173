import React, { useEffect, useState, memo } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import * as styles from './base.scss';

function Image({ src, fallbackImageUrl, dispatch, className, ...imageProps }) {
    const [source, setSource] = useState(src || fallbackImageUrl);

    useEffect(() => setSource(src || fallbackImageUrl), [src]);

    const handleError = () => {
        if (source === fallbackImageUrl) return;
        setSource(fallbackImageUrl);
    };

    return (
        <img
            className={classnames(className, { [styles.noImage]: !src })}
            src={source}
            onError={handleError}
            loading="lazy"
            {...imageProps}
        />
    );
}

export default memo(
    connect(
        ({ knownUrls }) => ({
            fallbackImageUrl: knownUrls.fallbackImageUrl,
        }),
        null,
    )(Image),
);
