const attributeName = 'data-adjust-height';
const animationAttributeName = 'data-adjusting-height';
const needsUpdate = new Set();
const elementsUpdating = new Map();

export function updateParents(className) {
    requestAnimationFrame(() => {
        for (const element of needsUpdate) {
            setNewHeight(element, className);
        }
    });
}

export function setNewHeight(element, className, adjustment, skipParents) {
    if (!adjustment) adjustment = 0;

    const transition = element
        .getAnimations()
        .filter((anim) => anim instanceof CSSTransition)
        .find((x) => x.transitionProperty === 'height');

    if (transition) {
        transition.finished.then(() => adjustElementHeight(element, className, adjustment, skipParents));
    } else {
        adjustElementHeight(element, className, adjustment, skipParents);
    }
}

function adjustElementHeight(element, className, adjustment, skipParents) {
    const style = getComputedStyle(element);
    const delay = style.getPropertyValue('transition-delay');
    const duration = style.getPropertyValue('transition-duration');
    const height = element.style.height || style.getPropertyValue('height');
    const durationSeconds = duration.match(/[\d\.]+/)[0];

    element.style.transitionDelay = '0';
    element.style.transitionDuration = '0';
    element.style.height = 'auto';

    const adjustedHeight = parseInt(element.getAttribute(attributeName)) + adjustment || 0;
    const newHeight = element.scrollHeight + adjustedHeight + 'px';

    let updatingId = elementsUpdating.get(element);

    element.style.height = height;

    if (!updatingId) {
        element.setAttribute(animationAttributeName, '1');
    }

    element.scrollHeight; // force reflow before re-enabling transitions

    element.style.transitionDelay = delay;
    element.style.transitionDuration = duration;
    element.style.height = newHeight;

    if (updatingId) {
        clearTimeout(updatingId);
        elementsUpdating.delete(element);
    }

    updatingId = setTimeout(() => {
        element.removeAttribute(animationAttributeName);
        elementsUpdating.set(element, undefined);
    }, durationSeconds * 1000 + 100);
    elementsUpdating.set(element, updatingId);

    if (!skipParents) adjustParents(element, className, height, newHeight);

    return parseFloat(newHeight);
}

export function forceHeight(element, className, forcedHeight, initialized, skipParents) {
    const height = element.style.height || getComputedStyle(element).getPropertyValue('height');
    const newHeight = `${forcedHeight}px`;

    element.scrollHeight; // force reflow before re-enabling transitions
    element.style.height = newHeight;

    if (!skipParents) adjustParents(element, className, initialized ? height : newHeight, newHeight);
}

function adjustParents(element, className, height, newHeight) {
    element.setAttribute(attributeName, 0);
    needsUpdate.delete(element);

    if (height != newHeight) {
        const heightDifference = parseInt(newHeight) - parseInt(height);
        let parent = element.parentElement;

        while (parent) {
            if (parent.className.includes(className)) {
                const existingAdjustment = parseInt(parent.getAttribute(attributeName)) || 0;
                const parentHeight = parseFloat(
                    parent.style.height || getComputedStyle(parent).getPropertyValue('height'),
                );
                let adjustment = existingAdjustment + heightDifference;

                if (parentHeight + adjustment < 0) adjustment = 0;

                parent.setAttribute(attributeName, adjustment);

                if (!needsUpdate.has(parent)) needsUpdate.add(parent);
                parent = null;
            } else {
                parent = parent.parentElement;
            }
        }
    }
}
