import { embed as embedHamburger } from 'Shared/Menu/Components/Hamburger';
import { embed as embedSearchBar } from './Search/SearchBar';
import { embed as embedLoginMenu } from './Login/Components/LoginMenu/embed';
import { embed as embedMiniCart } from './Cart';
import { embed as embedNav } from 'Shared/Menu';
import { embed as embedRightNav } from 'Shared/Menu/Components/PortalNav';
import { embed as embedContentBlock } from './Banner/Components/ContentBlock/embed';
import { embed as embedSalesBlock } from './Sales/components/SaleBlock/embed';

const containers = [
    {
        id: 'Hamburger',
        wrapper: embedHamburger,
    },
    {
        id: 'SearchBar',
        wrapper: embedSearchBar,
    },
    {
        id: 'LoginMenu',
        wrapper: embedLoginMenu,
    },
    {
        id: 'MiniCart',
        wrapper: embedMiniCart,
    },
    {
        id: 'Nav',
        wrapper: embedNav,
        force: true,
    },
    {
        id: 'RightNav',
        wrapper: embedRightNav,
    },
    {
        id: 'ContentBlock',
        wrapper: embedContentBlock,
    },
    {
        id: 'SaleBlock',
        wrapper: embedSalesBlock,
    },
    {
        id: 'DimmedArea',
        wrapperPromise: () => import('./Shared/DimmedArea/index.js'),
    },
    {
        id: 'AddedToCart',
        wrapperPromise: () => import('./Cart/Components/Added/index.js'),
    },
    {
        id: 'RecentlyAddedMessage',
        wrapperPromise: () => import('./Shared/RecentlyAddedMessage/index.js'),
    },
    {
        id: 'PayAndCollectModal',
        wrapperPromise: () => import('./PayAndCollect/index.js'),
    },
    {
        id: 'checkoutPage',
        wrapperPromise: () => import('./Checkout/index.js'),
        force: true,
    },
    {
        id: 'profileInfoWrapper',
        wrapperPromise: () => import('./Profile/index.js'),
    },
    {
        id: 'StoreDetailsWrapper',
        wrapperPromise: () => import('./StoreDetails/index.js'),
    },
    {
        id: 'ThumbnailList',
        wrapperPromise: () => import('./Category/Components/ThumbnailList/index.js'),
    },
    {
        id: 'Carousel',
        wrapperPromise: () => import('./Shared/Carousel/index.js'),
    },
    {
        id: 'UspBanner',
        wrapperPromise: () => import('./Shared/UspBanner/index.js'),
    },
    {
        id: 'CategoryPage',
        wrapperPromise: () => import('./Category/index.js'),
    },
    {
        id: 'SearchPage',
        wrapperPromise: () => import('./Search/index.js'),
    },
    {
        id: 'DesignSeriesPage',
        wrapperPromise: () => import('./DesignSeries/index.js'),
    },
    {
        id: 'ProductListBlock',
        wrapperPromise: () => import('./ProductListBlock/index.js'),
    },
    {
        id: 'RecentlyViewedProductsBlock',
        wrapperPromise: () => import('./RecentlyViewedProducts/index.js'),
    },
    {
        id: 'ProductListTile',
        wrapperPromise: () => import('./ProductListTile/index.js'),
    },
    {
        id: 'ProductPage',
        wrapperPromise: () => import('./Product/ProductPage/index.js'),
        force: true,
    },
    {
        id: 'PriceArea',
        wrapperPromise: () => import('./Product/Components/PriceArea/index.js'),
    },
    {
        id: 'BundlePage',
        wrapperPromise: () => import('./Product/BundlePage/index.js'),
    },
    {
        id: 'PromotionPage',
        wrapperPromise: () => import('./Promotions/Promotion/index.js'),
    },
    {
        id: 'SeoFooter',
        wrapperPromise: () => import('./Footer/Components/SeoSection/embed.js'),
    },
    {
        id: 'UspListFooter',
        wrapperPromise: () => import('./Footer/Components/UspBlocks/embed.js'),
    },
    {
        id: 'Footer',
        wrapperPromise: () => import('./Footer/index.js'),
    },
    {
        id: 'SignOut',
        wrapperPromise: () => import('./Header/Components/index.js'),
    },
    {
        id: 'RegistrationWrapper',
        wrapperPromise: () => import('./Registration/index.js'),
    },
    {
        id: 'LoginWrapper',
        wrapperPromise: () => import('./Login/index.js'),
    },
    {
        id: 'PageSpinner',
        wrapperPromise: () => import('./Shared/Loader/embed.js'),
    },
    {
        id: 'BrandList',
        wrapperPromise: () => import('./Brand/index.js'),
    },
    {
        id: 'BrandPage',
        wrapperPromise: () => import('./Brand/Components/BrandPage/index.js'),
    },
    {
        id: 'OrderHistory',
        wrapperPromise: () => import('./OrderHistory/index.js'),
    },
    {
        id: 'CountdownTimer',
        wrapperPromise: () => import('./CountdownBanner/index.js'),
    },
    {
        id: 'InspirationMenu',
        wrapperPromise: () => import('./Inspiration/Components/InspirationMenu/embed.js'),
    },
    {
        id: 'InspirationBlock',
        wrapperPromise: () => import('./Inspiration/Components/InspirationBlock/embed.js'),
    },
    {
        id: 'TestimonialBanner',
        wrapperPromise: () => import('./Shared/LipScore/Components/TestimonialsBanner/embed.js'),
        force: true,
    },
    {
        id: 'ArticleListPage',
        wrapperPromise: () => import('./Articles/index.js'),
    },
    {
        id: 'ArticleListBlock',
        wrapperPromise: () => import('./Articles/Components/ArticleListBlock/embed.js'),
    },
    {
        id: 'CollageSlider',
        wrapperPromise: () => import('./Collage/components/CollageSlider/embed.js'),
    },
    {
        id: 'CollageItem',
        wrapperPromise: () => import('./Collage/components/CollageItem/embed.js'),
    },
    {
        id: 'FabricDetails',
        wrapperPromise: () => import('./Textiles/Components/FabricDetails/embed.js'),
    },
    {
        id: 'FabricList',
        wrapperPromise: () => import('./Textiles/Components/FabricList/embed.js'),
    },
    {
        id: 'ReactContentArea',
        wrapperPromise: () => import('./Shared/ReactContentArea/index.js'),
    },
    {
        id: 'ProductRecommendations',
        wrapperPromise: () => import('./Recommendations/Components/ProductRecommendations/embed.js'),
    },
    {
        id: 'TextileBanner',
        wrapperPromise: () => import('./Product/ProductPage/ProductVariantsSection/embed.js'),
    },
    {
        id: 'AccordionBlock',
        wrapperPromise: () => import('./Shared/Accordion/Components/embed.js'),
    },
    {
        id: 'js-flowbox-flow',
        wrapperPromise: () => import('./FlowBox/index.js'),
    },
    {
        id: 'ShopgunList',
        wrapperPromise: () => import('./Shopgun/components/ListViewer/embed.js'),
    },
    {
        id: 'ShopgunPublication',
        wrapperPromise: () => import('./Shopgun/components/PublicationViewer/embed.js'),
    },
    {
        id: 'FinanceBlock',
        wrapperPromise: () => import('./Financing/Components/FinancingBlock/embed.js'),
    },
];

const hasIntersectionSupport = window.IntersectionObserver;

export const renderContainers = (root) => {
    if (!root) root = document;

    containers.map((container) => {
        const nodeList = root.querySelectorAll(`[id^='${container.id}']`);
        const nodes = Array.prototype.slice.call(nodeList);
        let wrapperPromise = null;

        const embed = (node) => {
            if (container.wrapper) {
                container.wrapper(node);
            } else {
                if (!wrapperPromise) {
                    wrapperPromise = container.wrapperPromise();
                }
                wrapperPromise.then((wrapper) => {
                    if (wrapper.embed) wrapper.embed(node);
                });
            }
        };

        if (container.force || !hasIntersectionSupport) {
            nodes.forEach((node) => embed(node));
        } else {
            nodes.forEach((node) => {
                const observer = new IntersectionObserver(
                    ([entry]) => {
                        if (!entry.isIntersecting) return;
                        embed(node);
                        observer.disconnect();
                    },
                    { rootMargin: '50px' },
                );
                observer.observe(node);
            });
        }
    });
};
