import { TOGGLE_DIMMED_AREA } from 'Shared/action-types';

export function toggleDimmedArea(show) {
    return dispatch => {
        dispatch({
            type: TOGGLE_DIMMED_AREA,
            show,
        });
    };
}
