import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { renderElement, renderHtml } from 'Shared/text-formatting';
import EditContainer, { editAttributes, blockAttributes, editingCurrentPage } from 'Shared/EditContainer';
import { SMALL } from 'Shared/breakpoints';
import EmbedObject from 'Shared/EmbedObject';
import ResponsiveImage, { imageCropShape } from 'Shared/ResponsiveImage';
import Hotspots from 'Shared/ResponsiveImage/hotspots';
import { contentPositions } from 'Shared/ContentPosition';
import { textThemes } from 'Shared/Theme';
import GradientOverlay, { getGradientPosition } from 'Shared/GradientOverlay';
import { getWidthRatio } from 'Shared/Blocks';
import { min } from 'Shared/number-format';
import classnames from 'classnames';
import * as styles from './base.scss';
import { useCurrentCrop } from 'Shared/Hooks/currentCrop';

const ContentBlock = ({
    isMobile,
    blockSize,
    imageSources,
    video,
    videoMobile,
    heading,
    headingFormat,
    text,
    theme,
    shadeBackground,
    backgroundColor,
    buttonText,
    buttonLink,
    editProps,
    contentPositionMobile,
    contentPositionDesktop,
    routedContentLink,
    isInEditMode,
}) => {
    const contentPosition = isMobile ? contentPositionMobile : contentPositionDesktop;
    const currentEmbed = isMobile ? videoMobile : video;

    const isEditingCurrentPage = editingCurrentPage(isInEditMode, routedContentLink, editProps);
    const renderDelegate = isEditingCurrentPage ? renderElement : renderHtml;
    const renderTitle = isEditingCurrentPage || heading;
    const currentCrop = useCurrentCrop(imageSources);
    const hasHotspots = currentCrop && currentCrop.hotspots.length > 0;

    const { width, height, embedSource } = currentEmbed || {};
    const backgroundDefined = embedSource || imageSources.length > 0;

    return (
        <>
            {isInEditMode && (
                <EditContainer className={styles.editContainer} {...editProps}>
                    <button
                        {...editAttributes('BlockSize', {
                            ...editProps,
                            propertyRender: 'none',
                            editType: 'floating',
                        })}
                    >
                        Block size
                    </button>
                    <button
                        {...editAttributes('HeadingFormat', {
                            ...editProps,
                            propertyRender: 'none',
                            editType: 'floating',
                        })}
                    >
                        Tittel-format
                    </button>
                    <button
                        {...editAttributes('Theme', {
                            ...editProps,
                            propertyRender: 'none',
                            editType: 'floating',
                        })}
                    >
                        Textfarge
                    </button>
                    <button
                        {...editAttributes('Image', {
                            ...editProps,
                            propertyRender: 'none',
                        })}
                    >
                        Image
                    </button>
                    <button
                        {...editAttributes('Video', {
                            ...editProps,
                            propertyRender: 'none',
                        })}
                    >
                        Video
                    </button>
                    <button
                        {...editAttributes('ContentPositionMobile', {
                            ...editProps,
                            propertyRender: 'none',
                            editType: 'floating',
                        })}
                    >
                        Content position (mobile)
                    </button>
                    <button
                        {...editAttributes('ContentPositionDesktop', {
                            ...editProps,
                            propertyRender: 'none',
                            editType: 'floating',
                        })}
                    >
                        Content position (desktop)
                    </button>
                    <button
                        {...editAttributes('BackgroundColor', {
                            ...editProps,
                            propertyRender: 'none',
                            editType: 'floating',
                        })}
                    >
                        Background color
                    </button>
                    <button
                        {...editAttributes('ShadeBackground', {
                            ...editProps,
                            propertyRender: 'none',
                        })}
                    >
                        Shade
                    </button>
                    <button
                        {...editAttributes('ButtonLink', {
                            ...editProps,
                            propertyRender: 'none',
                            editType: 'floating',
                        })}
                    >
                        Link
                    </button>
                </EditContainer>
            )}
            <div
                {...blockAttributes(editProps)}
                className={classnames(styles.contentBlock, styles[blockSize])}
                style={{ backgroundColor }}
            >
                <GradientOverlay position={getGradientPosition(contentPosition)} disabled={!shadeBackground}>
                    {embedSource ? (
                        <EmbedObject
                            className={classnames(styles.objectContainer, styles.video)}
                            {...currentEmbed}
                            sizes={getEmbedSizes(width, height, blockSize)}
                        />
                    ) : (
                        <ResponsiveImage
                            className={classnames(styles.objectContainer, styles.image, {
                                [styles.hasHotspots]: hasHotspots,
                            })}
                            crops={imageSources}
                        />
                    )}
                </GradientOverlay>
                <div
                    className={classnames(styles.textContainer, styles[contentPosition], styles[theme], {
                        [styles.backgroundUndefined]: !backgroundDefined,
                    })}
                >
                    {renderTitle && (
                        <h4
                            className={classnames(headingFormat, styles.heading)}
                            {...editAttributes('Heading', editProps)}
                        >
                            {heading}
                        </h4>
                    )}
                    {renderDelegate(text, {
                        className: styles.text,
                        ...editAttributes('Text', editProps),
                    })}
                    {buttonText && buttonLink && (
                        <a
                            href={buttonLink}
                            className={classnames('component-button', styles.button, theme)}
                            {...editAttributes('ButtonText', {
                                ...editProps,
                                propertyRender: 'none',
                            })}
                        >
                            {buttonText}
                        </a>
                    )}
                </div>
                {!embedSource && <Hotspots crops={imageSources} />}
            </div>
        </>
    );
};

const getEmbedSizes = (maxWidth, maxHeight, blockSize) => {
    var ratio = getWidthRatio(blockSize);
    return [
        {
            selector: '(max-width: 510px)',
            width: min(maxWidth, 510 * ratio),
            height: min(maxHeight, 556),
        },
        {
            selector: '(min-width: 511px)',
            width: min(maxWidth, 1157 * ratio),
            height: min(maxHeight, 675),
            fallback: true,
        },
    ];
};

const blockSizes = [
    '',
    'fullWidth',
    'halfWidth',
    'oneThirdWidth',
    'twoThirdsWidth',
    'oneQuarterWidth',
    'threeQuartersWidth',
];

ContentBlock.propTypes = {
    isMobile: PropTypes.bool,
    blockSize: PropTypes.oneOf(blockSizes),
    contentPositionMobile: PropTypes.oneOf(contentPositions),
    contentPositionDesktop: PropTypes.oneOf(contentPositions),
    theme: PropTypes.oneOf(textThemes),
    shadeBackground: PropTypes.bool,
    backgroundColor: PropTypes.string,
    video: PropTypes.shape(EmbedObject.propTypes),
    videoMobile: PropTypes.shape(EmbedObject.propTypes),
    imageSources: PropTypes.arrayOf(PropTypes.shape(imageCropShape)).isRequired,
    heading: PropTypes.string,
    headingFormat: PropTypes.string,
    text: PropTypes.string,
    linkUrl: PropTypes.string,
};

ContentBlock.defaultProps = {
    headingFormat: 'h1-format',
};

export default connect(({ editContainer, breakpoint }) => ({
    isMobile: breakpoint < SMALL,
    ...editContainer,
}))(ContentBlock);
