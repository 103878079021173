import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as styles from './base.scss';

class ScrollContainer extends React.Component {
    constructor(props) {
        super(props);
        this.scrollRef = null;
        this.scrollWidth = 0;
        this.scrollHeight = 0;
        this.lastUpdate = null;
        this.state = {
            up: false,
            down: false,
            left: false,
            right: false,
        };
    }

    setScrollRef = (node) => {
        if (this.scrollRef != null) this.unregisterListener(this.scrollRef);

        this.scrollRef = node;
        this.props.onSetScrollRef(node);

        if (node) {
            this.scrollWidth = node.offsetWidth - node.clientWidth;
            this.scrollHeight = node.offsetHeight - node.clientHeight;
            this.registerListener(this.scrollRef);
        }
    };

    componentDidMount() {
        this.checkUpdateState(this.scrollRef);
    }

    componentWillUnmount() {
        if (this.scrollRef != null) this.unregisterListener(this.scrollRef);
    }

    registerListener = (element) => {
        element.addEventListener('scroll', this.onScroll);
    };

    unregisterListener = (element) => {
        element.removeEventListener('scroll', this.onScroll);
    };

    onScroll = (event) => {
        requestAnimationFrame((snapshot) => {
            if (this.lastUpdate === snapshot) return;
            this.checkUpdateState(event.target);
            this.lastUpdate = snapshot;
        });
    };

    checkUpdateState = (element) => {
        if (!element) return;

        const prevDown = this.state.down;
        const prevUp = this.state.up;
        const prevLeft = this.state.left;
        const prevRight = this.state.right;
        const { scrollTop, scrollLeft, scrollHeight, scrollWidth, clientHeight, clientWidth } = element;

        const up = scrollTop > 0;
        const down = scrollTop + clientHeight < scrollHeight;
        const left = scrollLeft > 0;
        const right = scrollLeft + clientWidth < scrollWidth;

        const upChange = up !== prevUp;
        const downChange = down !== prevDown;
        const leftChange = left !== prevLeft;
        const rightChange = right !== prevRight;
        const anyChange = upChange || downChange || leftChange || rightChange;

        if (leftChange) {
            this.props.onLeftChange(left, this.scrollRef);
        }

        if (rightChange) {
            this.props.onRightChange(right, this.scrollRef);
        }

        if (upChange) {
            this.props.onTopChange(up, this.scrollRef);
        }

        if (downChange) {
            this.props.onBottomChange(down, this.scrollRef);
        }

        if (anyChange) {
            this.setState({ up, down, left, right });
        }
    };

    render() {
        const { children, className, scrollClassName } = this.props;
        const { up, down, left, right } = this.state;
        const verticalStyles = { width: `calc(100% - ${this.scrollWidth}px)` };
        return (
            <div className={styles.container}>
                <div ref={this.setScrollRef} className={classnames(scrollClassName, styles.scroll)}>
                    <div className={classnames(styles.container, className)}>{children}</div>
                </div>
                <div className={classnames(styles.fadeUp, { [styles.visible]: up })} style={verticalStyles}></div>
                <div className={classnames(styles.fadeLeft, { [styles.visible]: left })}></div>
                <div className={classnames(styles.fadeDown, { [styles.visible]: down })} style={verticalStyles}></div>
                <div className={classnames(styles.fadeRight, { [styles.visible]: right })}></div>
            </div>
        );
    }
}

ScrollContainer.propTypes = {
    children: PropTypes.node.isRequired,
    onSetScrollRef: PropTypes.func,
    onLeftChange: PropTypes.func,
    onRightChange: PropTypes.func,
    onTopChange: PropTypes.func,
    onBottomChange: PropTypes.func,
};

ScrollContainer.defaultProps = {
    onSetScrollRef: (node) => {},
    onLeftChange: (state) => {},
    onRightChange: (state) => {},
    onTopChange: (state) => {},
    onBottomChange: (state) => {},
};

export default ScrollContainer;
