import { getJson } from 'Shared/server';
import { getStore, mapStoreResponse } from './api';
import { searchByQuery, searchComplete, searchError, hidePayAndCollect, updateSelectedStoreDetails } from './actions';
import { PAY_AND_COLLECT_ACTION, PAY_AND_COLLECT_SEARCH, CART_UPDATE } from 'Shared/action-types';
import { checkoutTypes } from 'Shared/checkout-types';
import { setCheckoutType, add, showAddedToCart } from '../Cart/actions';
import { differentQuantity } from '../Cart/middleware';
import { addedToCartMessage, addedToCartInStoreMessage } from '../Cart/Components/Added';
import { removeTrailingSlash } from 'Shared/text-formatting';
import { delay } from '../Shared/delay';

const modalDelayMs = 600;
let actionActive = false;

export const payAndCollectMiddleware = (redux) => (next) => (action) => {
    if (action.type === PAY_AND_COLLECT_SEARCH) {
        getJson(action.url).then(
            (response) => redux.dispatch(searchComplete(response)),
            () => redux.dispatch(searchError()),
        );
    }
    if (action.type === PAY_AND_COLLECT_ACTION) {
        const state = redux.getState();
        const { checkoutType, product, store } = action.data;
        const cart = state.cart.data;
        const storeId = store && store.id;

        let promise = Promise.resolve(null);
        let addedToCart = false;
        let updatedStore = false;

        actionActive = true;

        if (product) {
            addedToCart = true;
            updatedStore = store && storeId !== cart.selectedStore;
            promise = chain(add(product.code, 1, checkoutType, storeId), promise, redux);
        } else {
            const checkoutTypeChanged = checkoutType !== cart.checkoutType;
            const storeChanged = store && storeId !== cart.selectedStore;

            if (checkoutTypeChanged || storeChanged) {
                updatedStore = storeChanged;
                promise = chain(
                    setCheckoutType(
                        cart.cartType,
                        checkoutType || storeChanged ? checkoutTypes.collect : checkoutTypes.online,
                        storeId,
                    ),
                    promise,
                    redux,
                );
            }
        }

        promise.then(() => redux.dispatch(hidePayAndCollect()));

        if (updatedStore) {
            promise
                .then(() => delay(modalDelayMs))
                .then(() => getSelectedStore(state, storeId))
                .then((storeDetails) => redux.dispatch(updateSelectedStoreDetails(storeDetails)));
        }

        if (addedToCart) {
            const message = getAddedMessage(product, store);
            promise.then(() => delay(modalDelayMs)).then(() => redux.dispatch(showAddedToCart(message, true, product)));
        }

        promise.then(
            () => (actionActive = false),
            () => (actionActive = false),
        );
    }
    if (action.type === CART_UPDATE) {
        const state = redux.getState();
        const { cart, payAndCollect } = state;

        // NOTE: This method updates the state for payAndCollect if cart is modified on checkout
        // Method is a band-aid and should eventually be replaced with proper state handling

        // Only update availability if cart is modified on checkout page
        // Also make sure no other action is running
        if (!actionActive && isCheckout(cart) && differentQuantity(state, action)) {
            const { availableIn } = payAndCollect;

            // Don't update availability if availableIn isn't set (<CartAvailableIn> isn't displayed)
            if (availableIn !== null && availableIn !== undefined) {
                const { items, selectedStore } = action.data;
                const storeId = selectedStore && selectedStore.id;
                redux.dispatch(searchByQuery(items, null, storeId, null, 1));
            }
        }
    }
    return next(action);
};

const chain = (action, promise, store) => {
    return promise.then(() => unwrap(action, store));
};

const unwrap = (action, store) => {
    return action(store.dispatch);
};

const getSelectedStore = (state, id) => {
    const { payAndCollect } = state;
    let store = payAndCollect.stores.find((store) => store.OrgUnitNumber == id);
    if (store) return Promise.resolve(store);
    return getStore(id).then((result) => mapStoreResponse(result));
};

const getAddedMessage = (product, store) => {
    if (store) return addedToCartInStoreMessage(product.name, store.name);
    return addedToCartMessage(product.name);
};

const isCheckout = (cart) => {
    const { checkoutUrl } = cart;
    const { pathname } = window.location;
    return removeTrailingSlash(pathname).endsWith(removeTrailingSlash(checkoutUrl));
};
