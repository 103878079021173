export const flatTree = (source, selector) => {
    if (!source) return [];
    if (Array.isArray(source)) return mapTreeChildren(source, selector);

    const children = selector(source);
    if (!children) return [source];

    return [source].concat(flatTree(children, selector));
};

const mapTreeChildren = (source, selector) => {
    if (!source) return [];
    return source.map((x) => flatTree(x, selector)).reduce((a, b) => a.concat(b), []);
};
