import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'Shared/translate';
import { loadMenu, toggleMobileNavigation } from '../../actions';
import { toggleMiniCart } from '../../../../Cart/actions';
import { toggleQuickSearch } from '../../../../Search/SearchBar/actions';
import { resetPageContent } from '../../util';
import { SMALL } from 'Shared/breakpoints';
import { PENDING } from '../../reducer';
import { menuClickMobile } from '../../events';

class Hamburger extends React.Component {
    static get propTypes() {
        return {
            open: PropTypes.bool.isRequired,
            breakpoint: PropTypes.number.isRequired,
            toggleMobileNavigation: PropTypes.func.isRequired,
            toggleMiniCart: PropTypes.func.isRequired,
            toggleQuickSearch: PropTypes.func.isRequired,
        };
    }

    constructor(props) {
        super(props);
        this.state = {
            locked: false,
        };
    }

    componentDidUpdate(prevProps) {
        const { breakpoint, open } = this.props;

        // Close menu if going above desktop break
        if (prevProps.breakpoint < SMALL && breakpoint >= SMALL && open) {
            this.setOpenState(false);
            resetPageContent();
        }
    }

    handleClick = (event) => {
        const { open } = this.props;
        const { locked } = this.state;

        if (locked) return;

        event.preventDefault();
        menuClickMobile();

        const { status, loadMenu } = this.props;
        if (status === PENDING) {
            loadMenu();
        }

        this.setOpenState(!open);
    };

    setOpenState(open) {
        const { toggleMiniCart, toggleQuickSearch, toggleMobileNavigation } = this.props;
        if (open) {
            toggleMiniCart(false);
            toggleQuickSearch(false);
        }
        toggleMobileNavigation(open);

        // Block interaction for a bit, to not make animations look bad.
        this.setState({ locked: true }, () => setTimeout(() => this.setState({ locked: false }), 150));
    }

    render() {
        const { open } = this.props;
        const { locked } = this.state;
        return (
            <button
                type="button"
                class="mobile-burger-btn"
                aria-label="Menu"
                disabled={locked}
                onClick={this.handleClick}
            >
                {this.renderBurgerIcon()}
                {this.renderLabel(translate('/Header/Nav/Menu'), !open)}
                {this.renderLabel(translate('/Header/Nav/Close'), open)}
            </button>
        );
    }

    renderBurgerIcon() {
        const { open } = this.props;
        const slices = [null, null, null];
        return (
            <div className={classnames('burgerIcon', { open: open })}>
                {slices.map((data, index) => this.renderBurgerSlice(data, index))}
            </div>
        );
    }

    renderLabel(text, visible) {
        return <span className={classnames('label', { visible: visible })}>{text}</span>;
    }

    renderBurgerSlice(data, index) {
        return <span key={index}></span>;
    }
}

export default connect(
    ({ menu, breakpoint }) => ({
        breakpoint,
        open: menu.showMobile,
        status: menu.status,
    }),
    (dispatch) => ({
        loadMenu: (...args) => dispatch(loadMenu(...args)),
        toggleMobileNavigation: (...args) => dispatch(toggleMobileNavigation(...args)),
        toggleMiniCart: (...args) => dispatch(toggleMiniCart(...args)),
        toggleQuickSearch: (...args) => dispatch(toggleQuickSearch(...args)),
    }),
)(Hamburger);
