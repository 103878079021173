import { trackSearch } from 'Shared/tracking-peerius';
import { quickSearchFocusEventV4, productClickEventV4 } from 'Shared/googleTagManagerFunctionsV4';
import { trackRecommendation } from 'Shared/tracking-peerius';

export function searchView(data) {
    trackSearch(data);
}

export function searchPage(data) {
    trackSearch(data);
}

export function quickSearch(query, result) {
    const { products } = result;
    try {
        trackSearch({
            form: {
                q: query,
                page: 1,
                totalCount: products.totalMatching,
            },
            products,
        });
    } catch (error) {
        console.error(error);
    }
}

export function productClicked(data) {
    productClickEventV4(data, `Search_result`, `search_result`);
    trackRecommendation(data);
}

export function focusQuickSearch() {
    quickSearchFocusEventV4();
}

export function searchFiltered(data) {}

export function searchFilter(data) {}

export function searchTypeSelected(data) {}
