import {
    CART_PENDING,
    CART_FULFILL,
    CART_REJECT,
    CART_UPDATE,
    DISPLAY_MINICART,
    DISPLAY_ADDED_MODAL,
    HIDE_ADDED_MODAL,
    MINICART_HOVER,
} from 'Shared/action-types';
import { setCartSession, hasCartSession, cartSession } from 'Shared/session-helper';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

const sessionExists = hasCartSession();
const checkoutInfo = window.CHECKOUT_INFO;

export const initialState = {
    status: FULFILLED,
    errors: [],
    data: checkoutInfo ? checkoutInfo.checkoutInfo.shoppingCart : sessionExists ? cartSession() : {},
    miniCartTotalPrice: 0,
    miniCartTotalItems: 0,
    miniCartVisible: false,
    miniCartHover: false,
    addedToCart: {
        visible: false,
        message: null,
        showRelated: false,
    },
    checkoutUrl: window.CHECKOUTURL,
    success: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CART_PENDING:
            return Object.assign({}, state, {
                status: PENDING,
            });
        case CART_FULFILL:
            return Object.assign({}, state, {
                status: FULFILLED,
            });
        case CART_REJECT:
            return Object.assign({}, state, {
                status: REJECTED,
                errors: (action.error || {}).errors,
            });
        case CART_UPDATE:
            setCartSession(action.data);
            return {
                ...state,
                data: { ...state.data, ...action.data, ...reduceSellMoreItems(state.data, action.data) },
                success: true,
            };
        case DISPLAY_MINICART:
            return Object.assign({}, state, {
                miniCartVisible: action.miniCartVisible,
            });
        case DISPLAY_ADDED_MODAL:
            return Object.assign({}, state, {
                addedToCart: {
                    visible: true,
                    message: action.message,
                    item: action.item,
                    showRelated: action.showRelated,
                    showSavings: true,
                },
            });
        case HIDE_ADDED_MODAL:
            return Object.assign({}, state, {
                addedToCart: {
                    ...state.addedToCart,
                    visible: false,
                },
            });
        case MINICART_HOVER:
            return Object.assign({}, state, {
                miniCartHover: action.miniCartHover,
            });
        default:
            return state;
    }
}

const reduceSellMoreItems = (state, action) => {
    if (action.sellMoreHeading) {
        return {
            sellMoreItems: action.sellMoreItems,
            sellMoreHeading: action.sellMoreHeading,
        };
    }

    return { sellMoreItems: state.sellMoreItems, sellMoreHeading: state.sellMoreHeading };
};
