import { purchaseEventV4 } from 'Shared/googleTagManagerFunctionsV4';

const orderDetails = window.ORDER_DETAILS;
const trackOrder = window.TRACK_ORDER;

window.addEventListener('load', function () {
    const orderConfirmationWrapper = document.querySelector('#OrderConfirmationWrapper');
    if (orderConfirmationWrapper && orderDetails) {
        if (trackOrder) {
            purchaseEventV4(orderDetails);
        }
    }
});
