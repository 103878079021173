import { useEffect, useMemo, useState } from 'react';

export const useCurrentCrop = (crops) => {
    if (crops.length < 1) return null;
    const queries = useMemo(() => getQueries(crops), [crops]);
    const [currentCrop, setCurrentCrop] = useState(getQueryIndex(queries));
    const handleQueryChange = (event) => {
        if (!event.matches) return;
        const newCrop = findQueryIndex(event, queries);
        setCurrentCrop(newCrop);
    };
    useEffect(() => {
        queries.forEach((query) => query.addEventListener('change', handleQueryChange));
        return () => queries.forEach((query) => query.removeEventListener('change', handleQueryChange));
    }, [crops]);
    return crops[currentCrop];
};

const getQueries = (crops) => {
    let queries = new Array(crops.length);
    for (let i = 0; i < queries.length; i++) {
        queries[i] = window.matchMedia(crops[i].mediaQuery);
    }
    return queries;
};

const getQueryIndex = (queries) => {
    for (let i = 0; i < queries.length; i++) {
        if (queries[i].matches) {
            return i;
        }
    }
    return 0;
};

const findQueryIndex = (event, queries) => {
    for (let i = 0; i < queries.length; i++) {
        const match = queries[i].media === event.target.media;
        if (match) return i;
    }
};
