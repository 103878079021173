import { PRODUCT_PENDING, PRODUCT_FULFILL, PRODUCT_REJECT, PRODUCT_UPDATE } from 'Shared/action-types';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

const initialState = {
    status: FULFILLED,
    data: window.PRODUCT_DATA || null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case PRODUCT_PENDING:
            return Object.assign({}, state, {
                status: PENDING,
            });
        case PRODUCT_FULFILL:
            return Object.assign({}, state, {
                status: FULFILLED,
            });
        case PRODUCT_REJECT:
            return Object.assign({}, state, {
                status: REJECTED,
            });
        case PRODUCT_UPDATE:
            return Object.assign({}, state, {
                data: action.data,
            });
        default:
            return state;
    }
}
