import { registerCartEvents } from '../Cart/events';

const locale = 'nb-NO';
let tracker = null;
let contactId = null;

export function registerVoyadoTracking() {
    window.onload = () => {
        tracker = window.va;
        if (!tracker) return;

        contactId = window.voyadoVal;
        if (!contactId) return;

        registerCartEvents(cartUpdate, cartCleared);
    };
}

const cartUpdate = (event) => {
    const cart = event.details;
    const data = {
        contactId,
        locale,
        cartRef: cart.id,
        items: mapItems(cart.items),
    };
    tracker('cart', data);
};

const cartCleared = (event) => {
    const cart = event.details;
    const data = {
        contactId,
        cartRef: cart.id,
    };
    tracker('emptyCart', data);
};

const mapItems = (items) => {
    if (!items) return [];
    return items.filter((x) => x.code).map((item) => mapItem(item));
};

const mapItem = (item) => {
    return {
        itemId: item.code,
        quantity: item.quantity,
    };
};
