import React from 'react';
import classnames from 'classnames';
import * as styles from './base.scss';
import PropTypes from 'prop-types';

const Plus = ({ onClick, open, className, baseClass }) => {
    return (
        <div className={classnames(className, styles.plus)} onClick={onClick}>
            <div className={classnames(styles.wrap, { [styles.open]: open })}>
                <i className={classnames(baseClass, 'fa-plus', { [styles.visible]: !open })}></i>
                <i className={classnames(baseClass, 'fa-minus', { [styles.visible]: open })}></i>
            </div>
        </div>
    );
};

Plus.propTypes = {
    className: PropTypes.string,
    baseClass: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
};

Plus.defaultProps = {
    baseClass: 'fa',
};

export default Plus;
