import { parseProps } from 'Shared/dom';

const cartStorageKey = 'cartState';
const authStorageKey = 'authenticationState';
const sessionStorageKey = 'userSession';
const preferenceStorageKey = 'sitePreferences';

export const hasAuthSession = () => {
    if (!window.sessionStorage) return false;
    if (getSessionHash() !== getCookieHash()) {
        clearSessionState();
        return false;
    }
    return localStorage.getItem(authStorageKey) !== null;
};

export const hasCartSession = () => {
    if (!window.sessionStorage) return false;
    return sessionStorage.getItem(cartStorageKey) !== null;
};

export const authSession = () => {
    const value = localStorage.getItem(authStorageKey);
    return parseProps(value);
};

export const cartSession = () => {
    const value = sessionStorage.getItem(cartStorageKey);
    return parseProps(value);
};

export const clearSessionState = () => {
    if (!window.sessionStorage) return;
    localStorage.removeItem(authStorageKey);
    clearCartSession();
};

export const clearCartSession = () => {
    if (!window.sessionStorage) return;
    sessionStorage.removeItem(cartStorageKey);
};

export const clearPreferences = () => {
    if (!window.localStorage) return;
    localStorage.removeItem(preferenceStorageKey);
};

export const setCartSession = (cart) => {
    if (!cart) return;
    if (!window.sessionStorage) return;
    sessionStorage.setItem(cartStorageKey, JSON.stringify(cart));
};

export const setAuthState = (state) => {
    if (!state) return;
    if (!window.localStorage) return;
    localStorage.setItem(authStorageKey, JSON.stringify(state));
    localStorage.setItem(sessionStorageKey, getCookieHash());
};

export const getPreference = (key, fallback) => {
    const value = localPreferences[key];
    if (value === undefined || value === null) return fallback;
    return value;
};

export const setPreference = (key, value) => {
    if (!window.localStorage) return;
    var existing = getPreferences();
    const preferences = {
        ...existing,
        [key]: value,
    };
    setPreferences(preferences);
};

const getSessionHash = () => {
    return localStorage.getItem(sessionStorageKey);
};

const getCookieHash = () => {
    return getCookie(sessionStorageKey);
};

const getCookie = (name) => {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
    return '';
};

const getPreferences = () => {
    const value = localStorage.getItem(preferenceStorageKey);
    return parseProps(value);
};

let localPreferences = getPreferences();

const setPreferences = (preferences) => {
    if (!preferences) return;
    if (!window.localStorage) return;
    localPreferences = preferences;
    localStorage.setItem(preferenceStorageKey, JSON.stringify(preferences));
};
