import React from 'react';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import * as styles from './item.scss';
import Quantity from './Quantity';
import Image from 'Shared/Image';
import { resize } from 'Shared/url';
import { getValidationMessage, getValidationIssue } from './Validation';
import { formatCurrency } from 'Shared/text-formatting';

export default class Item extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            quantity: this.getQuantityFromProps(props),
            isRemoving: false,
            isPending: false,
            isEditing: false,
        };
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.cartIsPending && this.state.isPending && !nextProps.cartIsPending) {
            // Wait for all requests to finish until we remove the pending state.
            const nextQuantity = this.getQuantityFromProps(nextProps);
            this.setState({ isPending: false });
            if (nextQuantity > 0 && this.state.isRemoving) {
                // This means that the remove request failed so restore the optimistically updated ui.
                this.setState({ isRemoving: false });
            }
            if (nextQuantity !== this.state.quantity) {
                // Sync local state with server incase they differ after all promises resolved
                this.setState({ quantity: nextQuantity });
            }
        }
    }

    getQuantityFromProps(props) {
        return props.quantity || props.item.quantity;
    }

    handleIncrement = () => {
        this.setState(
            (state) => ({ quantity: state.quantity + 1, isPending: true }),
            () => {
                this.props.update(this.props.item.lineId, this.state.quantity);
            },
        );
    };
    handleDecrement = () => {
        if (this.state.quantity === 1) {
            this.handleRemoveSingle();
        } else {
            this.setState(
                (state) => ({ quantity: state.quantity - 1, isPending: true }),
                () => {
                    this.props.update(this.props.item.lineId, this.state.quantity);
                },
            );
        }
    };
    handleRemoveSingle = () => {
        this.setState({
            isRemoving: true,
            isPending: true,
        });
        const { lineId } = this.props.item;
        this.props.remove(lineId);
    };
    handleRemove = () => {
        this.setState({
            isRemoving: true,
            isPending: true,
        });
        const { lineId, groupedIds } = this.props.item;
        const ids = [lineId, ...groupedIds];
        this.props.remove(ids);
    };

    calculateQuantity = () => {
        const { adjustQuantity } = this.props;
        let quantity = this.getQuantityFromProps(this.props);

        if (adjustQuantity) quantity += adjustQuantity;
        return quantity;
    };

    getMaxQuantity = () => {
        const { max, useItemQuantities, item } = this.props;
        const { availableQuantity } = item;
        if (!useItemQuantities) return max;
        if (this.isNotSet(availableQuantity)) return max;
        if (this.isNotSet(max)) return availableQuantity;
        return Math.min(availableQuantity, max);
    };

    getMinQuantity = () => {
        const { min, useItemQuantities, item } = this.props;
        const { availableQuantity, quantity } = item;
        if (!useItemQuantities) return min;
        if (availableQuantity === 0) return quantity;
        return Math.max(1, min);
    };

    isNotSet = (value) => {
        return value === null || value === undefined;
    };

    render() {
        const { showValidation, item } = this.props;
        const {
            productName,
            productUrl,
            price,
            netAmount,
            discountAmount,
            priceInclDiscount,
            description,
            brand,
            brandUrl,
            hideBasePrice,
            deliveryTime,
            validationErrorCode,
            validationIssueCode,
            availableQuantity,
        } = item;
        const isPending = this.state.isPending;
        const isRemoving = this.state.isRemoving;
        const isDiscount = discountAmount > 0;
        const hasError = showValidation && validationErrorCode > 0;
        const hasIssue = showValidation && validationIssueCode > 0;
        const maxQuantity = this.getMaxQuantity();
        const minQuantity = this.getMinQuantity(maxQuantity);
        return (
            <div
                className={classnames(styles.productItemWrapper, {
                    [styles.isRemoving]: isRemoving,
                    [styles.bigThumbnail]: this.props.bigThumbnails,
                    [styles.hasError]: hasError,
                    [styles.hasIssue]: hasIssue,
                })}
            >
                <div className={styles.productItem}>
                    <a href={productUrl} className={styles.productImage}>
                        {this.renderImage()}
                    </a>
                    <div className={styles.productInfoArea}>
                        <div className={styles.productInfo}>
                            {this.props.showBrand && brand && (
                                <a className={styles.name} href={brandUrl}>
                                    {brand}
                                </a>
                            )}
                            <a className={styles.name} href={productUrl}>
                                {productName}
                            </a>
                            {description && <span>{description}</span>}
                            {this.props.showUnitPrice ? (
                                <span className={styles.unitPrice}>{formatCurrency(price)}</span>
                            ) : (
                                <span className={styles.quantity}>
                                    {translate('/Header/MiniCart/Quantity')}: {this.calculateQuantity()}
                                </span>
                            )}
                            {this.props.showDelivery && (
                                <span className={styles.delivery}>{!this.props.placeholdDelivery && deliveryTime}</span>
                            )}
                        </div>
                        <div className={styles.quantityPriceWrapper}>
                            <div className={styles.priceCell}>
                                <div
                                    className={classnames({
                                        [styles.updatingSubTotal]: isPending && !isRemoving,
                                        [styles.discount]: isDiscount,
                                    })}
                                >
                                    <span className={styles.netAmountPrice}>
                                        {isDiscount ? formatCurrency(priceInclDiscount) : formatCurrency(netAmount)}
                                    </span>
                                </div>
                                {!hideBasePrice && isDiscount && (
                                    <div
                                        className={classnames(styles.discountPrice, {
                                            [styles.discountPriceUpdating]: isPending && !isRemoving,
                                        })}
                                    >
                                        {formatCurrency(netAmount)}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={styles.break}></div>
                    <div className={styles.actionWrapper}>
                        {this.renderCompactRemoveFromCart()}
                        {showValidation &&
                            this.renderErrorMessage(validationErrorCode, validationIssueCode, availableQuantity)}
                        {this.props.showQuantityButtons && (
                            <Quantity
                                adjustQuantity={this.props.adjustQuantity}
                                quantity={this.state.quantity}
                                min={minQuantity}
                                max={maxQuantity}
                                error={hasError}
                                onIncrement={this.handleIncrement}
                                onDecrement={this.handleDecrement}
                            />
                        )}
                    </div>
                    {this.renderBigRemoveFromCart()}
                </div>
            </div>
        );
    }

    renderErrorMessage = (errorCode, issueCode, maxQuantity) => {
        return (
            <div className={styles.error}>
                {errorCode > 0 && (
                    <span className={styles.errorMessage}>{getValidationMessage(errorCode, maxQuantity)}</span>
                )}
                {errorCode === 0 && issueCode > 0 && (
                    <span className={styles.issueMessage}>{getValidationIssue(issueCode)}</span>
                )}
            </div>
        );
    };

    renderImage = () => {
        const { bigThumbnails, item } = this.props;
        const { productName, description, image } = item;
        const imgAlt = productName + (description ? ' - ' + description : '');
        const imageUrl = bigThumbnails ? resize(image.url, 200, 150, 'pad', 75) : resize(image.url, 89, 89, 'min', 75);
        return <Image src={imageUrl} className={styles.image} alt={imgAlt} />;
    };

    renderCompactRemoveFromCart = () => {
        const { hideRemoveLink, compactRemoveLinks } = this.props;

        if (hideRemoveLink) return null;
        if (!compactRemoveLinks) return null;

        return (
            <button onClick={this.handleRemove} className={styles.removeFromCartCompact}>
                {translate('/Header/MiniCart/RemoveShort')}
            </button>
        );
    };

    renderBigRemoveFromCart = () => {
        const { hideRemoveLink, compactRemoveLinks } = this.props;
        if (hideRemoveLink) return null;
        if (compactRemoveLinks) return null;

        return (
            <div className={styles.removeFromCartWrapper}>
                <div onClick={this.handleRemove} className={styles.removeFromCart}>
                    {translate('/Header/MiniCart/Remove')}
                </div>
            </div>
        );
    };
}
