import { useEffect, useState } from 'react';

const hasSupport = window.IntersectionObserver;

export const useIntersectionObserver = (ref, options = {}) => {
    const [entry, setEntry] = useState({});
    const { threshold, root, rootMargin, freezeOnceVisible } = options;
    const frozen = entry && entry.isIntersecting && freezeOnceVisible;

    useEffect(() => {
        const node = ref && ref.current;

        if (!hasSupport || frozen || !node) return;

        const params = { threshold, root, rootMargin };
        const observer = new IntersectionObserver(([entry]) => {
            setEntry(entry);
        }, params);

        observer.observe(node);

        return () => observer.disconnect();
    }, [ref && ref.current, threshold, root, rootMargin, frozen]);

    return entry;
};
