import 'url-search-params-polyfill';

const HTTP_SUCCESS = 200;
const HTTP_CLIENT_ERROR = 400;
const HTTP_FORBIDDEN = 403;

const acceptedBodyTypes = [Blob, FormData, URLSearchParams, ArrayBuffer];

function load(url, method, body, headers = {}, keepalive = null, signal = null) {
    const requestHeaders = { ...defaultJsonHeaders, ...headers };
    return explicitLoad(url, method, body, requestHeaders, keepalive, signal);
}

function explicitLoad(url, method, body, headers = {}, keepalive = null, signal = null) {
    // Fetch API pollyfilled with whatwg-fetch
    // Json stringify if `body` is truly but not a string nor an instance of any of the accepted types
    if (body && typeof body !== 'string' && acceptedBodyTypes.every((t) => !t.prototype.isPrototypeOf(body))) {
        body = JSON.stringify(body);
    }
    return fetch(url, {
        method,
        body,
        credentials: 'same-origin',
        headers,
        keepalive,
        signal,
    });
}

export function isOK(status) {
    return HTTP_SUCCESS <= status && status < HTTP_CLIENT_ERROR;
}

export function json(response) {
    if (isOK(response.status)) {
        return response.json();
    } else {
        if (response.status === HTTP_FORBIDDEN) {
            return Promise.reject({
                status: response.status,
            });
        }
        return response.json().then((j) =>
            Promise.reject({
                status: response.status,
                errors: Array.isArray(j.errors) ? j.errors : null,
            }),
        );
    }
}

export function text(response) {
    if (isOK(response.status)) {
        return response.text();
    } else {
        return Promise.reject({
            status: response.status,
        });
    }
}

export function get(url, headers, keepalive, signal) {
    return load(url, 'get', null, headers, keepalive, signal);
}
export function getJson(url, headers, keepalive, signal) {
    return get(url, headers, keepalive, signal).then(json);
}
export function getJsonRaw(url, headers, keepalive, signal) {
    return explicitLoad(url, 'get', null, headers || defaultJsonHeaders, keepalive, signal).then(json);
}
export function getText(url, headers, keepalive, signal) {
    return get(url, headers, keepalive, signal).then(text);
}
export function post(url, body, headers, keepalive, signal) {
    return load(url, 'post', body, headers, keepalive, signal);
}
export function postJson(url, body, headers, keepalive, signal) {
    return post(url, body, headers, keepalive, signal).then(json);
}
export function postText(url, body, headers, keepalive, signal) {
    return post(url, body, headers, keepalive, signal).then(text);
}
export function del(url, body, headers, keepalive, signal) {
    return load(url, 'delete', body, headers, keepalive, signal);
}
export function delJson(url, body, headers, keepalive, signal) {
    return del(url, body, headers, keepalive, signal).then(json);
}
export function delText(url, body, headers, keepalive, signal) {
    return del(url, body, headers, keepalive, signal).then(text);
}

export const ajaxHeaders = {
    'X-Requested-With': 'XMLHttpRequest',
};

export const jsonHeaders = {
    'Content-Type': 'application/json; charset=utf-8',
};

export const noCacheHeaders = {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
};

const defaultJsonHeaders = {
    ...ajaxHeaders,
    ...jsonHeaders,
};
