import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { translate } from 'Shared/translate';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import * as styles from './base.scss';
import { checkoutTypes } from 'Shared/checkout-types';
import { displayPayAndCollect, searchByQuery } from '../../../PayAndCollect/actions';

const CartAvailableIn = ({
    cartItems,
    displayPayAndCollect,
    selectedStore,
    checkoutType,
    availableIn,
    className,
    searchByQuery,
    onAvailabilityClick,
}) => {
    const checkRefresh = () => {
        const storeId = selectedStore && selectedStore.id;
        if (availableIn === null || availableIn === undefined) {
            searchByQuery(cartItems, null, storeId, null, 1); // refresh store count for cart
        }
    };

    useEffect(() => {
        checkRefresh();
    }, []);

    if (availableIn === null || availableIn === undefined) return null;
    if (availableIn === 0) {
        if (checkoutType === checkoutTypes.collect)
            return <p className={styles.message}>{translate('/Cart/Validation/ImpossibleCart')}</p>;
        return <p>{translate('/Cart/CartAvailableInStores', availableIn)}</p>;
    }
    return (
        <a
            className={classnames(styles.link, className)}
            href={'#'}
            onClick={() => {
                displayPayAndCollect();
                if (onAvailabilityClick) onAvailabilityClick();
            }}
        >
            {translate('/Cart/CartAvailableInStores', availableIn)}
        </a>
    );
};

CartAvailableIn.propTypes = {
    cartItems: PropTypes.array.isRequired,
    displayPayAndCollect: PropTypes.func.isRequired,
    checkoutType: PropTypes.number,
    selectedStore: PropTypes.object,
    availableIn: PropTypes.number,
    className: PropTypes.string,
    onAvailabilityClick: PropTypes.func,
};

export default connect(
    ({ cart, payAndCollect }) => ({
        availableIn: payAndCollect.availableIn,
        selectedStore: cart.data.selectedStore,
        checkoutType: cart.data.checkoutType,
        cartItems: cart.data.items,
    }),
    (dispatch) => ({
        displayPayAndCollect: (code, orderOnline) => dispatch(displayPayAndCollect(code, orderOnline)),
        searchByQuery: (...args) => dispatch(searchByQuery(...args)),
    }),
)(CartAvailableIn);
