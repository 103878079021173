import { ORDER_PENDING, ORDER_FULFILL, ORDER_REJECT, ORDER_UPDATE, AGREED_ON_TERMS } from 'Shared/action-types';
import { PENDING, FULFILLED, REJECTED } from '../reducer';

export const initialState = {
    status: FULFILLED,
    hasAgreedOnTerms: false,
    orderNumber: null,
    success: false,
    message: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case ORDER_PENDING:
            return { ...state, status: PENDING };

        case ORDER_FULFILL:
            return { ...state, status: FULFILLED, ...action.data };

        case ORDER_REJECT:
            return { ...state, status: REJECTED, ...action.data };

        case ORDER_UPDATE:
            return { ...state, ...action.data };

        case AGREED_ON_TERMS:
            return { ...state, hasAgreedOnTerms: action.isChecked };

        default:
            return state;
    }
}
