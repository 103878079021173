import React from 'react';
import classnames from 'classnames';
import Spinner, { sizeApperance, colorApperance } from 'Shared/Spinner';
import { CHECKMARK, CROSS } from 'Shared/icons';
import * as styles from 'Styles/_buttons.scss';

export const appearanceTypes = {
    secondary: styles.btnsecondary,
    white: styles.btnwhite,
    invert: styles.btninvert,
    accordionPrimary: styles.btnaccordionprimary,
    accordionSecondary: styles.btnaccordionsecondary,
    accordionTertiary: styles.btnaccordiontertiary,
    link: styles.btnLink,
    bare: styles.bare,
    accordionNoPadding: styles.btnaccordionnopadding,
    round: styles.round,
    roundError: styles.roundError,
};

export default function Button(props) {
    const passProps = Object.assign({}, props);
    const buttonClass = passProps.appearance ? props.appearance : styles.btnprimary;

    delete passProps.appearance;
    delete passProps.pendingType;
    delete passProps.isSecondary;
    delete passProps.children;
    delete passProps.className;
    delete passProps.isPending;
    delete passProps.isFulfill;
    delete passProps.isRejected;
    delete passProps.isFeedbackButton;
    delete passProps.renderIcon;

    const classname = classnames(buttonClass, props.className, {
        [styles.fulfill]: props.isFulfill,
        [styles.rejected]: props.isRejected,
    });

    if (passProps.href) {
        return (
            <a className={classname} href={passProps.href} {...passProps}>
                {props.children}
            </a>
        );
    }

    const hasFeedbackState = props.isPending || props.isFulfill || props.isRejected;
    const spinnerColor = props.appearance === appearanceTypes.white ? colorApperance.dark : null;

    return (
        <button
            className={classname}
            {...passProps}
            onClick={(e) => {
                if (props.isPending) {
                    return false;
                }
                !!props.onClick && props.onClick(e);
            }}
            type={passProps.type || 'button'}
        >
            {props.isFeedbackButton || hasFeedbackState ? (
                <span className={classnames(styles.contentWrapper, { [styles.hiddenText]: hasFeedbackState })}>
                    {props.children}
                    {!hasFeedbackState && props.renderIcon && props.renderIcon()}
                </span>
            ) : (
                <>
                    {props.children}
                    {props.renderIcon && props.renderIcon()}
                </>
            )}
            {props.isPending && <Spinner colorApperance={spinnerColor} sizeApperance={sizeApperance.medium} />}
            {props.isFulfill && <IconInline inlineSvg={CHECKMARK} />}
            {props.isRejected && <IconInline className={styles.reject} inlineSvg={CROSS} />}
        </button>
    );
}

function IconInline(props) {
    return (
        <span
            className={classnames(styles.feedbackIcon, props.className)}
            dangerouslySetInnerHTML={{ __html: props.inlineSvg }}
        />
    );
}
