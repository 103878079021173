import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import { add, toggleMiniCart } from '../../../Cart/actions';
import { SMALL } from 'Shared/breakpoints';
import { hightlightCart } from 'Shared/customfunctions';
import { PriceArea } from '../../../Product/Components/PriceArea';
import Image from 'Shared/Image';
import classnames from 'classnames';
import LipScore, { displayStyles } from 'Shared/LipScore';
import { resize } from 'Shared/url';
import ProductBadge from 'Shared/ProductBadge';
import ProductLabel from 'Shared/ProductLabel';
import Variants from '../Variants';
import { getProductAlt, mapBadge, mapLabel } from '../../utils';
import AlwaysLowPriceLabel from 'Shared/AlwaysLowPriceLabel';
import EnergyLabel from 'Shared/EnergyLabel';

class BuyableProduct extends React.Component {
    constructor(props) {
        super(props);

        const { displayName, buyable } = props;
        this.state = {
            isPending: false,
            isRemoving: false,
            isHovering: false,
            productDisplayName: displayName,
            buyableIcon: buyable ? 'buyable' : '',
        };
    }

    componentDidUpdate(prevProps) {
        const { miniCartHover, miniCartVisible, toggleMiniCart } = this.props;

        if (!miniCartHover && prevProps.miniCartHover && miniCartVisible && this.miniCartHideBlockedByHover) {
            this.miniCartHideBlockedByHover = false;
            toggleMiniCart(false);
        }
    }

    componentDidMount() {
        // Note: Removed logic that appended script tags to page head, that might have broken something.
    }

    isMobile() {
        return this.props.breakpoint < SMALL;
    }

    callbackOnAddToCart = () => {
        if (this.isMobile()) {
            this.props.updateRecentlyAddedMessage(
                translate('/ProductPage/Added', this.state.productDisplayName),
                translate('/Header/MiniCart/Button/GoToCheckout'),
                this.props.checkoutUrl,
            );
        } else {
            this.props.toggleMiniCart(true);
            setTimeout(() => {
                if (this.props.miniCartHover) {
                    this.miniCartHideBlockedByHover = true;
                } else {
                    this.props.toggleMiniCart(false);
                }
            }, 2000);
        }
    };

    getInStockQuantity() {
        const { productInventory } = this.props;
        const { availableQuantity } = productInventory;
        if (availableQuantity < 0) return 0;
        return availableQuantity;
    }

    addToCart = (e) => {
        e.preventDefault();

        this.setState({ isPending: true });

        this.props
            .addToCart(this.props.code)
            .then(() => {
                this.setState({ isPending: false });

                if (this.state.productDisplayName) {
                    this.callbackOnAddToCart();
                }

                hightlightCart();
            })
            .catch(() => this.setState({ isPending: false }));
    };

    getLipscoreProps() {
        const { rating, itemNumber } = this.props;
        let result = {
            displayStyle: displayStyles.narrow,
            productId: itemNumber,
        };

        if (rating !== undefined && rating !== null) result = { ...result, ...rating };
        return result;
    }

    onProductClick = (e) => {
        e.preventDefault();
        const { onClick } = this.props;

        if (onClick) {
            onClick();
        }

        const { promo } = this.props;
        window.location =
            promo && promo.variantCode && promo.variantCode !== promo.productCode
                ? e.currentTarget.href + '?VariationId=' + promo.variantCode
                : e.currentTarget.href;
    };

    onVariantClick = (variant) => {
        const { onVariantClick } = this.props;
        if (onVariantClick) {
            onVariantClick(variant);
        }
    };

    onMouseEnter = (e) => {
        const { imageType } = this.props;
        if (imageType !== 'environment') return;
        this.setState({ isHovering: true });
    };

    onMouseLeave = (e) => {
        const { imageType } = this.props;
        if (imageType !== 'environment') return;
        this.setState({ isHovering: false });
    };

    getImageUrlWithDimensions(src, isEnvironment) {
        const aspect = 260 / 348;
        const width = 393;
        const height = Math.round(width * aspect);
        const mode = isEnvironment ? 'crop' : 'min';
        return resize(src, width, height, mode, 75, 'jpg');
    }

    render() {
        const { className, productListBlock, contentBlock, contentLink, hidden, inRelatedItems } = this.props;
        return (
            <div
                className={classnames(styles.product, className, {
                    [styles.productListBlock]: productListBlock,
                    [styles.contentBlock]: contentBlock,
                    [styles.hidden]: hidden,
                    [styles.inRelatedItems]: inRelatedItems,
                })}
                {...(window.PAGE_IS_EDITMODE && { 'data-epi-block-id': contentLink })}
                onMouseEnter={this.onMouseEnter}
                onMouseLeave={this.onMouseLeave}
            >
                {this.renderProductCard()}
            </div>
        );
    }

    renderProductCard() {
        const topContents = this.renderProductCardContentsTop();

        return (
            <div className={styles.content}>
                {this.renderProductLink(topContents)}
                {this.renderProductCardContentsBottom()}
            </div>
        );
    }

    renderProductCardContentsTop() {
        const {
            image,
            environmentImage,
            brand,
            displayName,
            description,
            price,
            hideBasePrice,
            numberOfVariants,
            imageType,
            promo,
            alwaysLowPrice,
            energyLabel,
        } = this.props;
        const { isHovering } = this.state;
        const prefersEnvironment = imageType === 'environment';
        const shouldUseEnvironment = prefersEnvironment && environmentImage;
        const useEnvironment = shouldUseEnvironment && !isHovering;
        const resolvedImage = useEnvironment ? environmentImage : image;
        const img = {
            src: this.getImageUrlWithDimensions(resolvedImage, useEnvironment),
            alt: getProductAlt(displayName, description),
        };
        const badge = mapBadge(this.props);
        const label = mapLabel(this.props);

        return (
            <>
                <div className={classnames(styles.productImage, { [styles.environment]: shouldUseEnvironment })}>
                    <EnergyLabel type={energyLabel} className={styles.energyLabel} orientation={'right'} />
                    <Image {...img} />
                    <div className={styles.productOverlayLeftWrapper}>
                        <ProductBadge {...badge} />
                    </div>
                    <div className={styles.productOverlayRightWrapper}>
                        <ProductLabel {...label} />
                    </div>
                </div>
                <div className={styles.productDetails}>
                    <div className={styles.detailWrapper}>
                        {<p className={styles.brand}>{brand}</p>}
                        {<p className={styles.displayName}>{displayName}</p>}
                    </div>
                    <div className={styles.informationRow}>
                        {price && (
                            <div className={styles.priceArea}>
                                <PriceArea
                                    price={price}
                                    numberOfVariants={numberOfVariants}
                                    hideBasePrice={hideBasePrice}
                                    hasDiscount={promo && promo.discountedPercentage}
                                    alignLeft={true}
                                />
                            </div>
                        )}
                        <div className={styles.labelArea}>
                            {alwaysLowPrice && (
                                <div className={styles.lowPriceLabel}>
                                    <AlwaysLowPriceLabel />
                                </div>
                            )}
                            <LipScore className={styles.lipscore} {...this.getLipscoreProps()} />
                        </div>
                    </div>
                </div>
            </>
        );
    }

    renderProductCardContentsBottom() {
        const { variantSelectorTotalCount, variantSelectorSizesCount, variantThumbs } = this.props;
        const descriptor = { sizes: variantSelectorSizesCount };
        if (!variantThumbs) return null;
        return (
            <>
                <div className={styles.productDetailsBottom}>
                    <div className={styles.variants}>
                        <Variants
                            variants={variantThumbs || []}
                            descriptor={descriptor}
                            count={variantSelectorTotalCount}
                            onClick={this.onVariantClick}
                        />
                    </div>
                </div>
            </>
        );
    }

    renderProductLink(contents) {
        const { isUnknown } = this.props;
        return isUnknown ? (
            contents
        ) : (
            <a href={this.props.url} className={styles.productLink} onClick={this.onProductClick}>
                {contents}
            </a>
        );
    }

    renderTruncated(text, length) {
        if (text.length <= length) return <>{text}</>;
        return <>{text.substr(0, length)}&hellip;</>;
    }
}

export default connect(
    (state) => ({
        miniCartHover: state.cart.miniCartHover,
        breakpoint: state.breakpoint,
    }),
    (dispatch) => ({
        addToCart: (code) => dispatch(add(code)),
        updateRecentlyAddedMessage: (...args) => dispatch(updateRecentlyAddedMessage(...args)),
        toggleMiniCart: (...args) => dispatch(toggleMiniCart(...args)),
    }),
)(BuyableProduct);
