import React from 'react';
import * as styles from './spinner.scss';
import classnames from 'classnames';

export const colorApperance = {
    dark: styles.colorApperanceDark,
    light: styles.colorApperanceLight,
    gray: styles.colorApperanceGray,
};

export const sizeApperance = {
    big: styles.sizeApperanceBig,
    medium: styles.sizeApperanceMedium,
};

export default (props) => (
    <div className={classnames(props.extend ? styles.spinnerExtend : styles.spinner, props.colorApperance, props.sizeApperance, props.className)}></div>
);
