// NOTE: The edit view widget ('epi-cms/contentediting/PageDataController')
// contains a debounce on rendering, callbacks should match, otherwise changes might get rolled back.
export const epiRenderDebounce = 100;
export const hasSupport = 'MutationObserver' in window;

export const observerOptions = {
    childList: true,
    attributes: false,
    subtree: true,
};
