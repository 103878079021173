import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { cumulativeOffset } from 'Shared/offset';
import { SMALL } from 'Shared/breakpoints';

const breakHeight = 60;

class PortalNav extends React.Component {
    static get propTypes() {
        return {
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    icon: PropTypes.string,
                    url: PropTypes.string.isRequired,
                    name: PropTypes.string.isRequired,
                }),
            ).isRequired,
            className: PropTypes.string,
            portal: PropTypes.string.isRequired,
            breakpoint: PropTypes.number.isRequired,
        };
    }

    constructor(props) {
        super(props);
        this.portalElement = document.getElementById(props.portal);
        this.componentRef = null;
        this.state = {
            offset: null,
        };
    }

    setComponentRef = (node) => {
        this.componentRef = node;
    };

    componentDidMount() {
        this.updateOffset();
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('load', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('load', this.handleResize);
    }

    updateOffset = () => {
        const { offset } = this.state;
        const currentOffset = this.getOffset();

        if (offset != currentOffset) this.setState({ offset: currentOffset });
    };

    getOffset = () => {
        if (!this.componentRef) return null;
        return cumulativeOffset(this.componentRef).top;
    };

    componentHanging = () => {
        const { offset } = this.state;
        return offset > breakHeight;
    };

    handleResize = (event) => {
        this.updateOffset();
    };

    render() {
        if (this.portalElement && this.componentHanging()) {
            return ReactDOM.createPortal(this.renderItems(), this.portalElement);
        } else {
            return this.renderItems();
        }
    }

    renderItems() {
        const { items, breakpoint, className } = this.props;
        if (breakpoint < SMALL) return null;
        return (
            <div ref={this.setComponentRef} className={className}>
                {items.map((item, index) => this.renderItem(item, index))}
            </div>
        );
    }

    renderItem(item, index) {
        return (
            <div key={index} className={classnames('block', 'list-1')}>
                <a className={'icon-link'} href={item.url}>
                    <div>
                        <span>{item.name}</span>
                    </div>
                </a>
            </div>
        );
    }
}

export default connect(
    ({ breakpoint }) => ({
        breakpoint,
    }),
    (dispatch) => ({}),
)(PortalNav);
