import React from 'react';
import classnames from 'classnames';
import FeedbackButton from 'Shared/Button/Feedback';
import * as styles from './submit.scss';
import { elementProps, submitProps } from '../props';

export default class Submit extends React.Component {
    handleChange = (event) => {
        const { onChange } = this.props;
        const { value } = event.target;

        this.setState({ value });
        if (onChange) onChange(event);
    };

    render() {
        const { name, label, className, onClick } = this.props;
        return (
            <FeedbackButton
                name={name}
                type="button"
                className={classnames(styles.submit, className)}
                onClick={onClick}
            >
                {label}
            </FeedbackButton>
        );
    }
}

Submit.propTypes = { ...elementProps, submitProps };
