import { postJson } from 'Shared/server';
import { getQueryString } from 'Shared/url';

export const loginUrl = window.LOGIN_URL || '/login/';

export const getCode = (phone) => {
    return postJson(`${loginUrl}sendcode`, { phone });
};

export const validateCode = (phone, code, requestedUrl) => {
    return postJson(`${loginUrl}validatecode${getQueryString({ requestedUrl })}`, { phone, code });
};
