import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import CategoryLink from '../CategoryLink';
import * as styles from './base.scss';
import { getSwiperParams } from './swiper';

const CategoryList = ({ categories, className, renderSwiper }) => {
    if (renderSwiper) {
        return (
            <Swiper containerClass={cn(className, styles.categoryList, styles.slider)} {...getSwiperParams()}>
                {renderCategories(categories)}
            </Swiper>
        );
    }

    return <div className={cn(className, styles.categoryList)}>{renderCategories(categories)}</div>;
};

const renderCategories = (categories) => {
    return categories.map((category, index) => <CategoryLink key={index} className={styles.category} {...category} />);
};

CategoryList.propTypes = {
    categories: PropTypes.arrayOf(PropTypes.shape(CategoryLink.propTypes)).isRequired,
    className: PropTypes.string,
    renderSwiper: PropTypes.bool,
};

export default CategoryList;
export { getSwiperParams };
