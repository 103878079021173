import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as styles from './base.scss';

const ProgressBar = ({ color, amount, total }) => {
    const percentage = (Math.max(0, Math.min(amount, total)) / total) * 100;
    const inlineStyles = {
        backgroundColor: color,
        width: `${percentage.toFixed(2)}%`,
    };
    return (
        <div className={cn(styles.bar)}>
            <div className={styles.progress} style={inlineStyles}></div>
        </div>
    );
};

ProgressBar.propTypes = {
    color: PropTypes.string,
    amount: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
};

ProgressBar.defaultProps = {
    color: '#EF7C00',
};

export default ProgressBar;
