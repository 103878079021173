import React from 'react';
import cn from 'classnames';
import * as styles from './base.scss';
import { renderHtml } from 'Shared/text-formatting';
import { translate } from 'Shared/translate';

const AlwaysLowPriceLabel = ({ className, link, expandLink }) => {
    return (
        <div className={cn(styles.wrapper, className)}>
            {renderLink(translate('/Product/AlwaysLowPrice/Title'), link, expandLink)}
        </div>
    );
};

const renderLink = (html, link, expandLink) => {
    return renderHtml(
        html,
        { className: cn(styles.label, { [styles.expandLink]: expandLink }), href: link },
        link ? 'a' : 'span',
    );
};

export default AlwaysLowPriceLabel;
