import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import * as styles from './base.scss';
import { renderHtml } from 'Shared/text-formatting';

const CategoryLink = ({ title, name, url, className }) => {
    return (
        <a className={cn(styles.category, className)} href={url}>
            {renderHtml(title || name)}
        </a>
    );
};

CategoryLink.propTypes = {
    title: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
};

export default CategoryLink;
