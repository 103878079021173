import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import store from 'Shared/store';

export { observeBlocks } from './blockObserver';

export const EditContainer = ({ isInEditMode, routedContentLink, contentLink, children, dispatch, ...props }) => {
    const shouldHideAttributes = hideAttributes(isInEditMode, routedContentLink, contentLink);
    const shouldRenderContent = props.propertyName;

    if (shouldHideAttributes) {
        if (shouldRenderContent) return <>{children}</>;
        else return null;
    }

    if (!children) {
        const { propertyName } = props;
        children = <p>{propertyName}</p>;
    }

    return <div {...getAttributes(props)}>{children}</div>;
};

export const editAttributes = (propertyName, editProps) => {
    const { contentLink, ...props } = editProps || {};
    const { editContainer } = store.getState();
    const { isInEditMode, routedContentLink } = editContainer;
    if (hideAttributes(isInEditMode, routedContentLink, contentLink)) return {};
    return getAttributes({ propertyName, ...props });
};

export const blockAttributes = (editProps) => {
    const { contentLink } = editProps || {};
    const { editContainer } = store.getState();
    const { isInEditMode } = editContainer;
    if (!isInEditMode) return {};
    return { 'data-epi-block-id': contentLink };
};

export const editingCurrentPage = (isInEditMode, routedContentLink, editProps) => {
    if (!isInEditMode) return false;
    if (!editProps) return false;
    const { contentLink } = editProps;
    return routedContentLink === contentLink;
};

const hideAttributes = (isInEditMode, routedContentLink, contentLink) => {
    return !isInEditMode || routedContentLink !== contentLink;
};

const getAttributes = (props) => {
    const propNames = Object.keys(props);
    let attributes = {};
    for (const propName of propNames) {
        const name = attributeNames[propName] || propName;
        const value = props[propName];
        attributes[name] = value;
    }
    return attributes;
};

EditContainer.propTypes = {
    ...editorDetailsShape,
    propertyName: PropTypes.string,
    propertyDisabled: PropTypes.bool,
    propertyEditor: PropTypes.string,
    propertyRender: PropTypes.oneOf(['inline', 'none']),
    editType: PropTypes.oneOf(['floating', 'flyout', 'inline', 'webcontrol']),
    useOverlay: PropTypes.bool,
    editorSettings: PropTypes.object,
    renderSettings: PropTypes.object,
};

export const editorDetailsShape = {
    contentLink: PropTypes.string,
};

const attributeNames = {
    propertyName: 'data-epi-property-name',
    propertyDisabled: 'data-epi-property-disabled',
    propertyEditor: 'data-epi-property-editor',
    propertyRender: 'data-epi-property-render',
    editType: 'data-epi-property-edittype',
    useOverlay: 'data-epi-useoverlay',
    editorSettings: 'data-epi-property-editorsettings',
    renderSettings: 'data-epi-property-rendersettings',
};

export default connect(({ editContainer }) => ({
    isInEditMode: editContainer.isInEditMode,
    routedContentLink: editContainer.routedContentLink,
}))(EditContainer);
