export const BREAKPOINT_UPDATE = 'BREAKPOINT_UPDATE';

export const ACCEPT_COOKIES = 'ACCEPT_COOKIES';

export const BUNDLE_PENDING = 'BUNDLE_PENDING';
export const BUNDLE_FULFILL = 'BUNDLE_FULFILL';
export const BUNDLE_REJECT = 'BUNDLE_REJECT';
export const BUNDLE_UPDATE = 'BUNDLE_UPDATE';

export const SEARCH_PENDING = 'SEARCH_PENDING';
export const SEARCH_FULFILL = 'SEARCH_FULFILL';
export const SEARCH_REJECT = 'SEARCH_REJECT';
export const SEARCH_UPDATE = 'SEARCH_UPDATE';

export const CART_PENDING = 'CART_PENDING';
export const CART_FULFILL = 'CART_FULFILL';
export const CART_REJECT = 'CART_REJECT';
export const CART_UPDATE = 'CART_UPDATE';

export const DISPLAY_MINICART = 'DISPLAY_MINICART';
export const DISPLAY_ADDED_MODAL = 'DISPLAY_ADDED_MODAL';
export const HIDE_ADDED_MODAL = 'HIDE_ADDED_MODAL';
export const MINICART_HOVER = 'MINICART_HOVER';

export const DISPLAY_PAY_AND_COLLECT = 'DISPLAY_PAY_AND_COLLECT';
export const HIDE_PAY_AND_COLLECT = 'HIDE_PAY_AND_COLLECT';

export const PAY_AND_COLLECT_ACTION = 'PAY_AND_COLLECT_ACTION';
export const PAY_AND_COLLECT_STORE_UPDATE = 'PAY_AND_COLLECT_STORE_UPDATE';

export const PAY_AND_COLLECT_SEARCH = 'PAY_AND_COLLECT_SEARCH';
export const PAY_AND_COLLECT_SEARCH_COMPLETE = 'PAY_AND_COLLECT_SEARCH_COMPLETE';
export const PAY_AND_COLLECT_SEARCH_ERROR = 'PAY_AND_COLLECT_SEARCH_ERROR';

export const FACET_EXPAND = 'FACET_EXPAND';

export const RECENTLYADDED_UPDATE = 'RECENTLYADDED_UPDATE';
export const RECENTLYADDED_CLEAR = 'RECENTLYADDED_CLEAR';

export const FINANCING_UPDATE = 'FINANCING_UPDATE';
export const FINANCING_ERROR = 'FINANCING_UPDATE';

export const REGISTER_PENDING = 'REGISTER_PENDING';
export const REGISTER_FULFILL = 'REGISTER_FULFILL';
export const REGISTER_REJECT = 'REGISTER_REJECT';
export const REGISTER_UPDATE = 'REGISTER_UPDATE';
export const REGISTER_CHANGE = 'REGISTER_CHANGE';
export const REGISTER_CLEAN = 'REGISTER_CLEAN';

export const PRODUCT_PENDING = 'PRODUCT_PENDING';
export const PRODUCT_FULFILL = 'PRODUCT_FULFILL';
export const PRODUCT_REJECT = 'PRODUCT_REJECT';
export const PRODUCT_UPDATE = 'PRODUCT_UPDATE';

export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_FULFILL = 'LOGIN_FULFILL';
export const LOGIN_REJECT = 'LOGIN_REJECT';
export const LOGIN_UPDATE = 'LOGIN_UPDATE';

export const PAYMENT_SET = 'PAYMENT_SET';
export const PAYMENT_UPDATE = 'PAYMENT_UPDATE';

export const DELIVERY_PENDING = 'DELIVERY_PENDING';
export const DELIVERY_FULFILL = 'DELIVERY_FULFILL';
export const DELIVERY_REJECT = 'DELIVERY_REJECT';
export const DELIVERY_UPDATE = 'DELIVERY_UPDATE';

export const DELIVERY_SELECTION_PENDING = 'DELIVERY_SELECTION_PENDING';
export const DELIVERY_SELECTION_FULFILL = 'DELIVERY_SELECTION_FULFILL';
export const DELIVERY_SELECTION_REJECT = 'DELIVERY_SELECTION_REJECT';
export const DELIVERY_SELECTION_UPDATE = 'DELIVERY_SELECTION_UPDATE';
export const DELIVERY_SELECTION_SELECT = 'DELIVERY_SELECTION_SELECT';
export const DELIVERY_SELECTION_SELECT_STRATEGY = 'DELIVERY_SELECTION_SELECT_STRATEGY';

export const ADDITIONAL_SERVICE_UPDATE = 'ADDITIONAL_SERVICE_UPDATE';
export const DELIVERY_SELECTION_STORE_UPDATE = 'DELIVERY_SELECTION_STORE_UPDATE';

export const DELIVERY_SERVICELOCATION_PENDING = 'DELIVERY_SERVICELOCATION_PENDING';
export const DELIVERY_SERVICELOCATION_FULFILL = 'DELIVERY_SERVICELOCATION_FULFILL';
export const DELIVERY_SERVICELOCATION_REJECT = 'DELIVERY_SERVICELOCATION_REJECT';
export const DELIVERY_SERVICELOCATION_UPDATE = 'DELIVERY_SERVICELOCATION_UPDATE';

export const ADDRESS_PENDING = 'ADDRESS_PENDING';
export const ADDRESS_FULFILL = 'ADDRESS_FULFILL';
export const ADDRESS_REJECT = 'ADDRESS_REJECT';
export const ADDRESS_UPDATE = 'ADDRESS_UPDATE';

export const CATEGORYPAGE_UPDATE = 'CATEGORYPAGE_UPDATE';
export const CATEGORYPAGE_UPDATE_PRODUCTLIST = 'CATEGORYPAGE_UPDATE_PRODUCTLIST';

export const SEARCHPAGE_UPDATE = 'SEARCHPAGE_UPDATE';
export const SEARCHPAGE_PENDING = 'SEARCHPAGE_PENDING';
export const SEARCHPAGE_ERROR = 'SEARCHPAGE_PENDING';
export const SEARCHPAGE_CHANGE_TYPE = 'SEARCHPAGE_CHANGE_TYPE';
export const SEARCHPAGE_UPDATE_TYPE = 'SEARCHPAGE_UPDATE_TYPE';

export const ARTICLELISTPAGE_UPDATE = 'ARTICLELISTPAGE_UPDATE';

export const ORDER_PENDING = 'ORDER_PENDING';
export const ORDER_FULFILL = 'ORDER_FULFILL';
export const ORDER_REJECT = 'ORDER_REJECT';
export const ORDER_UPDATE = 'ORDER_UPDATE';
export const ORDER_FORM_HAS_ERRORS = 'ORDER_FORM_HAS_ERRORS';
export const ORDER_UPDATE_PAYMENT_TENDERLINE = 'ORDER_UPDATE_PAYMENT_TENDERLINE';
export const ORDER_TOGGLE_FORM_SUBMIT = 'ORDER_TOGGLE_FORM_SUBMIT';
export const ORDER_RESET_STATUS = 'ORDER_RESET_STATUS';

export const ORDER_HISTORY_PENDING = 'ORDER_HISTORY_PENDING';
export const ORDER_HISTORY_FULFILL = 'ORDER_HISTORY_FULFILL';
export const ORDER_HISTORY_REJECT = 'ORDER_HISTORY_REJECT';
export const ORDER_HISTORY_UPDATE = 'ORDER_HISTORY_UPDATE';

export const AGREED_ON_TERMS = 'AGREED_ON_TERMS';
export const COLLECT_AGREED_ON_TERMS = 'COLLECT_AGREED_ON_TERMS';

export const SUBSCRIBE_NEWSLETTER = 'SUBSCRIBE_NEWSLETTER';

export const PROFILEPAGE_PENDING = 'PROFILEPAGE_PENDING';
export const PROFILEPAGE_FULFILL = 'PROFILEPAGE_FULFILL';
export const PROFILEPAGE_REJECT = 'PROFILEPAGE_REJECT';
export const PROFILEPAGE_UPDATE = 'PROFILEPAGE_UPDATE';
export const PROFILEPAGE_CHANGE = 'PROFILEPAGE_CHANGE';

export const RECOMMENDATIONS_UPDATE = 'RECOMMENDATIONS_UPDATE';
export const RECOMMENDATIONS_PENDING = 'RECOMMENDATIONS_PENDING';
export const RECOMMENDATIONS_REJECTED = 'RECOMMENDATIONS_REJECTED';

export const PROFILEPAGE_CONSENTS_PENDING = 'PROFILEPAGE_CONSENTS_PENDING';
export const PROFILEPAGE_CONSENTS_FULFILL = 'PROFILEPAGE_CONSENTS_FULFILL';
export const PROFILEPAGE_CONSENTS_REJECT = 'PROFILEPAGE_CONSENTS_REJECT';
export const PROFILEPAGE_CONSENTS_UPDATE = 'PROFILEPAGE_CONSENTS_UPDATE';
export const PROFILEPAGE_CONSENTS_CHANGE = 'PROFILEPAGE_CONSENTS_CHANGE';

export const PROFILEPAGE_CLEAR_MESSAGE = 'PROFILEPAGE_CLEAR_MESSAGE';

export const PROFILE_ADDRESS_PENDING = 'PROFILE_ADDRESS_PENDING';
export const PROFILE_ADDRESS_FULFILL = 'PROFILE_ADDRESS_FULFILL';
export const PROFILE_ADDRESS_UPDATE = 'PROFILE_ADDRESS_UPDATE';
export const PROFILE_ADDRESS_REJECT = 'PROFILE_ADDRESS_REJECT';
export const PROFILE_ADDRESS_CHANGE = 'PROFILE_ADDRESS_CHANGE';
export const PROFILE_ADDRESS_ADD = 'PROFILE_ADDRESS_ADD';
export const PROFILE_ADDRESS_REMOVE = 'PROFILE_ADDRESS_REMOVE';

export const TOGGLE_QUICKSEARCH = 'TOGGLE_QUICKSEARCH';
export const UPDATE_SEARCHQUERY = 'UPDATE_SEARCHQUERY';

export const TOGGLE_MOBILE_NAVIGATION = 'TOGGLE_MOBILE_NAVIGATION';
export const NAVIGATION_UPDATE = 'NAVIGATION_UPDATE';
export const NAVIGATION_REJECT = 'NAVIGATION_REJECT';

export const CHANGE_PASSWORD_PENDING = 'CHANGE_PASSWORD_PENDING';
export const CHANGE_PASSWORD_FULFILL = 'CHANGE_PASSWORD_FULFILL';
export const CHANGE_PASSWORD_REJECT = 'CHANGE_PASSWORD_REJECT';
export const CHANGE_PASSWORD_UPDATE = 'CHANGE_PASSWORD_UPDATE';

export const SORTAREA_EXPAND = 'SORTAREA_EXPAND';

export const TOGGLE_DIMMED_AREA = 'TOGGLE_DIMMED_AREA';

export const PRODUCTPAGE_UPDATE = 'PRODUCTPAGE_UPDATE';

export const PAYMENT_CONDITIONS_UPDATE = 'PAYMENT_CONDITIONS_UPDATE';

export const SEARCH_BY_REGION_EXPAND = 'SEARCH_BY_REGION_EXPAND';

export const SEARCH_BY_CITIES_AND_ZIP_EXPAND = 'SEARCH_BY_CITIES_AND_ZIP_EXPAND';
export const SEARCH_BY_CITIES_AND_ZIP_SETADDRESS = 'SEARCH_BY_CITIES_AND_ZIP_SETADDRESS';

export const CURRENT_CITYANDZIP_CHANGED = 'CURRENT_CITYANDZIP_CHANGED';
export const CURRENT_DEPARTMENTS_CHANGED = 'CURRENT_DEPARTMENTS_CHANGED';
export const CURRENT_ATTRIBUTES_CHANGED = 'CURRENT_ATTRIBUTES_CHANGED';

export const CURRENT_STORES_CHANGED = 'CURRENT_STORES_CHANGED';

export const SEARCH_BY_FILTERS_EXPAND = 'SEARCH_BY_FILTERS_EXPAND';
export const SEARCH_BY_FILTERS__SET_CURRENT_DEP_FILTERS = 'SEARCH_BY_FILTERS__SET_CURRENT_DEP_FILTERS';
export const SEARCH_BY_FILTERS__SET_CURRENT_FAC_FILTERS = 'SEARCH_BY_FILTERS__SET_CURRENT_FAC_FILTERS';

export const SHOW_SPINNER = 'SHOW_SPINNER';
export const HIDE_SPINNER = 'HIDE_SPINNER';
export const LOGIN_HAS_ERROR = 'LOGIN_HAS_ERROR';
export const LOGIN_CLEAN = 'LOGIN_CLEAN';

export const BRANDLIST_UPDATE = 'BRANDLIST_UPDATE';
export const BRANDLIST_FILL = 'BRANDLIST_FILL';

export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_CURRENT_POSITION = 'SET_CURRENT_POSITION';
export const SET_CLOSEST_STORES_STATE = 'SET_CLOSEST_STORES_STATE';
export const RESET_FILTERS = 'RESET_FILTERS';

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';
export const SET_BTN_TEXT = 'SET_BTN_TEXT';
export const SET_IS_POSITION_SHARED = 'SET_IS_POSITION_SHARED';
export const SET_CONTENT_STATE = 'SET_CONTENT_STATE';
export const GEO_LOADING = 'GEO_LOADING';
export const GEO_CURRENT_POSITION = 'GEO_CURRENT_POSITION';
export const SET_MODAL_DATA = 'SET_MODAL_DATA';
export const SET_MESSAGE = 'SET_MESSAGE';

// TODO: Maybe remove the following
export const REGISTER_ENABLE_VALIDATION = 'REGISTER_ENABLE_VALIDATION';
export const REGISTER_HAS_ERROR = 'REGISTER_HAS_ERROR';
export const CHANGE_BOOL = 'CHANGE_BOOL';

export const PROFILEPAGE_UPDATING = 'PROFILEPAGE_UPDATING';
export const PROFILEPAGE_UPDATING_FIELD = 'PROFILEPAGE_UPDATING_FIELD';
export const PROFILEPAGE_FIELD_UPDATED = 'PROFILEPAGE_FIELD_UPDATED';
export const PROFILEPAGE_UPDATE_FINISHED = 'PROFILEPAGE_UPDATE_FINISHED';
export const PROFILEPAGE_STATES_UPDATE = 'PROFILEPAGE_STATES_UPDATE';
export const PROFILEPAGE_UPDATE_ADDRESSLIST = 'PROFILEPAGE_UPDATE_ADDRESSLIST';
export const PROFILEPAGE_UPDATE_SELECTED_ADDRESS = 'PROFILEPAGE_UPDATE_SELECTED_ADDRESS';

export const NEWSLETTER_PENDING = 'NEWSLETTER_PENDING';
export const NEWSLETTER_SENDING = 'NEWSLETTER_SENDING';
export const NEWSLETTER_SENDING_FINISHED = 'NEWSLETTER_SENDING_FINISHED';

export const DISPLAY_LOGIN_MODAL = 'DISPLAY_LOGIN_MODAL';

export const COLLECT_ADDRESS_CUSTOMER_UPDATE = 'COLLECT_ADDRESS_CUSTOMER_UPDATE';
export const COLLECT_ADDRESS_CUSTOMER_INFO_UPDATE = 'COLLECT_ADDRESS_CUSTOMER_INFO_UPDATE';
