import React from 'react';

const whiteSpace = /\s/g;
const trailingSlash = /\/$/;

const decimalFormat = new Intl.NumberFormat('no-NB', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
});

const integerFormat = new Intl.NumberFormat('no-NB', {
    style: 'decimal',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
});

const domParser = new DOMParser();

export const formatDate = (dateString) => {
    var dateTime = Date.parse(dateString);
    if (dateTime === NaN) return dateString;
    return new Date(dateTime).toLocaleDateString('no-NB');
};

export const removeTrailingSlash = (input) => {
    if (input === undefined || input === null || typeof input !== 'string') return input;
    return input.replace(trailingSlash, '');
};

export const startCapital = (input) => {
    if (input === undefined || input === null || typeof input !== 'string') return input;
    if (input.length < 2) return input.toLocaleUpperCase();
    return input[0].toLocaleUpperCase() + input.substr(1);
};

export const formatNumber = (amount, truncate) => {
    if (truncate) amount = Math.floor(amount);
    if (amount % 1 === 0) return integerFormat.format(amount);
    return decimalFormat.format(amount);
};

export const formatCurrency = (amount) => {
    if (amount === undefined || amount === null || isNaN(amount)) return '';
    if (amount === 0) return '-';
    return `${formatNumber(amount)} kr`;
};

export function insertNbsp(input) {
    return input.replace(whiteSpace, '&nbsp;');
}

export function injectNbsp(input) {
    return { __html: insertNbsp(input) };
}

export function injectHtml(input) {
    return { __html: input };
}

export function renderNbsp(input, props, element) {
    if (!input) return null;
    const Element = element || 'span';
    return <Element dangerouslySetInnerHTML={injectNbsp(input)} {...props} />;
}

export function renderHtml(input, props, element) {
    if (!input) return null;
    return renderElement(input, props, element);
}

export function renderElement(input, props, element) {
    const Element = element || 'span';
    return <Element dangerouslySetInnerHTML={injectHtml(input)} {...props} />;
}

export const renderCurrency = (amount, props, element) => {
    return renderNbsp(formatCurrency(amount), props, element);
};

export function toCamelCase(key, value) {
    if (value && typeof value === 'object') {
        for (var k in value) {
            if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
                value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
                delete value[k];
            }
        }
    }
    return value;
}

export function stripHtml(input) {
    var doc = domParser.parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
}
