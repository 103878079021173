import { TOGGLE_DIMMED_AREA } from '../action-types';

const initialState = {
    show: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case TOGGLE_DIMMED_AREA:
        return Object.assign({}, state, {
            show: action.show,
        });
    default:
        return state;
    }
}
