import React from 'react';
import { WindowPanel } from 'Shared/OverlayPanels';
import * as styles from './base.scss';

function ActionWindow({ className, message, status, details, actions, children }) {
    const headingClass = React.isValidElement(message) ? null : styles.headingWrapper;
    return (
        <div className={className}>
            <div className={styles.area}>
                <div className={headingClass}>
                    <div className={styles.message}>
                        {status && <div className={styles.status}>{status}</div>}
                        {message}
                    </div>
                    {details && <div className={styles.details}>{details}</div>}
                </div>
                {actions && <div className={styles.actions}>{actions}</div>}
            </div>
            {children}
        </div>
    );
}

export default function ActionWindowModal({ visible, toggleModal, disabled, ...rest }) {
    return (
        <WindowPanel isOpen={visible} toggleModal={toggleModal} disabled={disabled}>
            <ActionWindow {...rest} />
        </WindowPanel>
    );
}
