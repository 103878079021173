let positions = null;
let collected = false;

export const widgetIsRegistered = (widgetPosition) => {
    if (!collected) collectPositions();
    return positions.has(widgetPosition);
};

export const anyWidgetIsRegistered = () => {
    if (!collected) collectPositions();
    return positions.size > 0;
};

export const registerWidgetPosition = (widgetPosition) => {
    if (!collected) collectPositions();
    registerWidget(widgetPosition);
};

export const unregisterWidgetPosition = (widgetPosition) => {
    unregisterWidget(widgetPosition);
};

const collectPositions = () => {
    positions = new Map();
    collected = true;
    const widgetAttribute = 'data-recommendations';
    const elements = document.querySelectorAll(`[${widgetAttribute}]`);

    for (const element of elements) {
        const attribute = element.getAttribute(widgetAttribute);
        registerWidget('any');
        if (attribute === '') continue;
        registerWidget(attribute);
    }
};

const registerWidget = (widgetPosition) => {
    if (!positions.has(widgetPosition)) {
        positions.set(widgetPosition, 1);
        return;
    }
    const value = positions.get(widgetPosition);
    positions.set(widgetPosition, value + 1);
};

const unregisterWidget = (widgetPosition) => {
    if (!positions.has(widgetPosition)) return;
    const value = positions.get(widgetPosition);
    if (value > 1) positions.set(widgetPosition, value - 1);
    else positions.delete(widgetPosition);
};
