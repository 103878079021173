const attributeName = 'data-adjust-width';
const needsUpdate = new Set();

export function updateParents(className) {
    requestAnimationFrame(() => {
        for (const element of needsUpdate) {
            setNewWidth(element, className);
        }
    });
}

export function setNewWidth(element, className, adjustment, skipParents) {
    if (!adjustment) adjustment = 0;

    const delay = getComputedStyle(element).getPropertyValue('transition-delay');
    const duration = getComputedStyle(element).getPropertyValue('transition-duration');
    const width = element.style.width || getComputedStyle(element).getPropertyValue('width');

    element.style.transitionDelay = '0';
    element.style.transitionDuration = '0';
    element.style.width = 'auto';

    const adjustedWidth = parseInt(element.getAttribute(attributeName)) + adjustment || 0;
    const newWidth = element.scrollWidth + adjustedWidth + 'px';

    element.style.width = width;

    element.scrollWidth; // force reflow before re-enabling transitions

    element.style.transitionDelay = delay;
    element.style.transitionDuration = duration;
    element.style.width = newWidth;

    if (!skipParents) adjustParents(element, className, width, newWidth);

    return parseFloat(newWidth);
}

export function forceWidth(element, className, forcedWidth, initialized, skipParents) {
    const width = element.style.width || getComputedStyle(element).getPropertyValue('width');
    const newWidth = `${forcedWidth}px`;

    element.scrollWidth; // force reflow before re-enabling transitions
    element.style.width = newWidth;

    if (!skipParents) adjustParents(element, className, initialized ? width : newWidth, newWidth);
}

function adjustParents(element, className, width, newWidth) {
    element.setAttribute(attributeName, 0);
    needsUpdate.delete(element);

    if (width != newWidth) {
        const widthDifference = parseInt(newWidth) - parseInt(width);
        let parent = element.parentElement;

        while (parent) {
            if (parent.className.includes(className)) {
                const existingAdjustment = parseInt(parent.getAttribute(attributeName)) || 0;
                const parentWidth = parseFloat(
                    parent.style.width || getComputedStyle(parent).getPropertyValue('width'),
                );
                let adjustment = existingAdjustment + widthDifference;

                if (parentWidth + adjustment < 0) adjustment = 0;

                parent.setAttribute(attributeName, adjustment);

                if (!needsUpdate.has(parent)) needsUpdate.add(parent);
                parent = null;
            } else {
                parent = parent.parentElement;
            }
        }
    }
}
