import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { translate } from 'Shared/translate';
import store from 'Shared/store';
import AddedToCart from './AddedToCart';
import CommerceEnabled from 'Shared/CommerceEnabled';

export function embed(container) {
    render(
        <CommerceEnabled>
            <Provider store={store}>
                <AddedToCart />
            </Provider>
        </CommerceEnabled>,
        container,
    );
}

export function resolveAddedMessage(product, store) {
    if (store) return addedToCartInStoreMessage(product.displayName, store.name);
    return addedToCartMessage(product.displayName);
}

export function addedToCartMessage(productName) {
    return translate('/Cart/AddedToCart/AddedToCart', productName);
}

export function addedToCartInStoreMessage(productName, storeName) {
    return translate('/Cart/AddedToCart/AddedToCartInStore', productName, storeName);
}
