import React from 'react';
import classnames from 'classnames';
import { formProps } from '../props';
import Input from './Input';
import Switch from './Switch';
import Submit from './Submit';
import * as styles from './base.scss';

export default class Form extends React.Component {
    handleSubmit = (event) => {};

    handleChange = (name, value) => {};

    render() {
        return (
            <form className={classnames(styles.form, className)} onSubmit={this.handleSubmit}>
                {this.renderElements()}
            </form>
        );
    }

    renderElements = () => {
        const { values } = this.props;
        return values.map((value) => this.renderElement(value));
    };

    renderElement = (value) => {
        const { type, name, ...props } = value;
        const Component = this.getComponentType(type);
        return <Component key={name} name={name} onChange={this.handleChange} {...props} />;
    };

    getComponentType = (type) => {
        switch (type) {
            case 'input':
                return Input;
            case 'checkbox':
                return Switch;
            case 'submit':
                return Submit;
            default:
                return Input;
        }
    };
}

Form.propTypes = formProps;
