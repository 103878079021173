import {
    ADDRESS_PENDING,
    ADDRESS_FULFILL,
    ADDRESS_REJECT,
    ADDRESS_UPDATE,
    SUBSCRIBE_NEWSLETTER,
} from 'Shared/action-types';
import { defaultLanguageCode } from 'Shared/GlobalConstants';
import { PENDING, FULFILLED, REJECTED } from '../reducer';

const pageData = (window.CHECKOUT_INFO && window.CHECKOUT_INFO.checkoutInfo) || {};
const paymentAddress = (window.CHECKOUT_INFO && window.CHECKOUT_INFO.paymentAddress) || {};

const defaultCustomerInfo = {
    firstName: '',
    lastName: '',
    city: '',
    street: '',
    zipCode: pageData.preSelectedPostalCode ? pageData.preSelectedPostalCode : '',
    email: '',
    phone: '',
    country: defaultLanguageCode,
};

export const initialState = {
    status: FULFILLED,
    errors: [],
    subscribeForNewsletter: false,
    data: {
        states: [],
        customerInfo: { ...defaultCustomerInfo, ...pageData.customerInfo, ...paymentAddress },
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case ADDRESS_PENDING:
        return Object.assign({}, state, {
            status: PENDING,
        });
    case ADDRESS_FULFILL:
        return Object.assign({}, state, {
            status: FULFILLED,
        });
    case ADDRESS_REJECT:
        return Object.assign({}, state, {
            status: REJECTED,
            errors: !action.error.errors ? [...action.error] : [...action.error.errors],
        });
    case ADDRESS_UPDATE:
        return Object.assign({}, state, {
            data: Object.assign({}, state.data, {
                customerInfo: Object.assign({}, state.data.customerInfo, action.data),
            }),
        });
    case SUBSCRIBE_NEWSLETTER:
        return Object.assign({}, state, {
            subscribeForNewsletter: action.isChecked,
        });

    default:
        return state;
    }
}
