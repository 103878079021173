import { getJsonRaw } from 'Shared/server';

const key = window.LIPSCORE_API_KEY;
const filter = /[0-9]+/;

export function lipScoreActive() {
    if (key === undefined) return false;
    if (key === null) return false;
    if (typeof key !== 'string') return false;
    if (key.length < 1) return false;
    return true;
}

export function ratings(productId) {
    const id = getInternalId(productId);
    const url = `//wapi.lipscore.com/initial_data/products/show?api_key=${key}&internal_id=${id}&widgets=r`;
    return getJsonRaw(url, { Accept: 'application/json, text/javascript, */*' });
}

export function serviceRating(sourceId) {
    const sourceIdParam = sourceId ? `&source_id=${sourceId}` : '';
    const url = `//wapi.lipscore.com/initial_data/products/show?api_key=${key}&internal_id=service_review&widgets=srw_r_s${sourceIdParam}`;
    return getJsonRaw(url, { Accept: 'application/json, text/javascript, */*' });
}

export function testimonials(sourceId) {
    const sourceIdParam = sourceId ? `&source_id=${sourceId}` : '';
    const url = `//wapi.lipscore.com/initial_data/products/show?api_key=${key}&internal_id=service_review&widgets=srw_t${sourceIdParam}`;
    return getJsonRaw(url, { Accept: 'application/json, text/javascript, */*' });
}

function getInternalId(productId) {
    if (!filter.test(productId)) return null;
    return filter.exec(productId)[0];
}
