import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { lock, unlock, clearBodyLocks } from 'tua-body-scroll-lock';
import * as styles from './base.scss';

const customStyles = {
    overlay: {
        zIndex: '4',
        background: '#0000004c',
        position: 'absolute',
    },
};

export class Panel extends React.Component {
    constructor(props) {
        super(props);
        this.targetElement = null;
        this.state = { scrollLocked: false };
    }

    componentWillUnmount() {
        clearBodyLocks();
    }

    componentDidUpdate(prevProps) {
        const { isOpen } = this.props;
        const { scrollLocked } = this.state;

        if (prevProps.isOpen && !isOpen && scrollLocked) {
            this.onClose();
        }
    }

    handleClose = () => {
        const { toggleModal } = this.props;
        if (toggleModal) toggleModal();
        this.onClose();
    };

    handleOpen = () => {
        this.onOpen();
    };

    onOpen = () => {
        this.targetElement = document.querySelector(`.${styles.overlayPanel} > .${this.getChildrenStyleName()}`);
        this.setState({ scrollLocked: true }, () => lock(this.targetElement));
    };

    onClose = () => {
        this.setState({ scrollLocked: false }, () => unlock(this.targetElement));
    };

    setChildRef = (node) => {
        const { setChildRef } = this.props;
        if (setChildRef) setChildRef(node);
    };

    getChildrenStyleName = () => {
        const { childrenClassName } = this.props;
        return childrenClassName || styles.children;
    };

    render() {
        const { isOpen, children, className, style, isWide, disabled } = this.props;
        const compoundStyles = mergeStyles(customStyles, style);
        return (
            <Modal
                style={compoundStyles}
                className={classnames(styles.overlayPanel, className, 'panelComponent', {
                    slidePanelComponent__wide: isWide,
                })}
                isOpen={isOpen}
                onAfterOpen={this.handleOpen}
                onRequestClose={this.handleClose}
                ariaHideApp={false}
                closeTimeoutMS={500}
            >
                <button
                    className={styles.closeButton}
                    disabled={disabled}
                    type="button"
                    onClick={this.handleClose}
                    aria-label="Close"
                >
                    <i className={'far fa-times'}></i>
                </button>
                <div ref={this.setChildRef} className={this.getChildrenStyleName()}>
                    {children}
                </div>
            </Modal>
        );
    }
}

Panel.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    className: PropTypes.string,
    childrenClassName: PropTypes.string,
    toggleModal: PropTypes.func,
    setChildRef: PropTypes.func,
    isWide: PropTypes.bool,
};

Panel.defaultProps = {
    style: {},
};

export function SlidePanel({ children, ...props }) {
    return (
        <Panel
            className={classnames(styles.slidePanel, 'slidePanelComponent', {
                [styles.slidePanel__wide]: props.isWide,
            })}
            {...props}
        >
            {children}
        </Panel>
    );
}

export function WindowPanel({ children, ...props }) {
    return (
        <Panel className={classnames(styles.windowPanel, 'windowPanelComponent')} {...props}>
            {children}
        </Panel>
    );
}

export function CenteredPanel({ className, children, style, ...props }) {
    const compoundStyle = mergeStyles(style, {
        overlay: {
            position: 'fixed',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 11,
        },
    });
    return (
        <Panel
            className={classnames(className, styles.centeredPanel, 'centeredPanelComponent')}
            {...props}
            style={compoundStyle}
        >
            {children}
        </Panel>
    );
}

export class ScrollPanel extends React.Component {
    constructor(props) {
        super(props);
    }

    setScrollRef = (node) => {
        this.scrollRef = node;
        if (node) {
            this.checkUpdateScroll();
        }
    };

    setChildScroll = (x, y) => {
        if (!this.scrollRef) return;

        const element = this.scrollRef;
        const parent = element.parentElement;
        const width = parent.offsetWidth;
        const height = parent.offsetHeight;

        element.scrollLeft = x * element.scrollWidth - width * (1 - x);
        element.scrollTop = y * element.scrollHeight - height * (1 - y);
    };

    checkUpdateScroll = () => {
        const { scrollX, scrollY, isOpen } = this.props;
        if (!isOpen) return;
        if (!scrollX || !scrollY) return;
        this.setChildScroll(scrollX, scrollY);
    };

    componentDidUpdate(prevProps) {
        const { scrollX, scrollY, isOpen } = this.props;
        if (!isOpen) return;
        if (prevProps.scrollX === scrollX && prevProps.scrollY === scrollY) return;
        this.checkUpdateScroll();
    }

    render() {
        const { children, style, ...props } = this.props;

        return (
            <CenteredPanel childrenClassName={styles.scroll} setChildRef={this.setScrollRef} {...props}>
                {children}
            </CenteredPanel>
        );
    }
}

const mergeStyles = (a, b) => {
    if (!a) return b;
    if (!b) return a;
    return {
        overlay: { ...(a.overlay || {}), ...(b.overlay || {}) },
        content: { ...(a.content || {}), ...(b.content || {}) },
    };
};

export default SlidePanel;
