import {
    DELIVERY_SELECTION_PENDING,
    DELIVERY_SELECTION_FULFILL,
    DELIVERY_SELECTION_REJECT,
    DELIVERY_SELECTION_UPDATE,
    DELIVERY_SELECTION_SELECT,
    DELIVERY_SELECTION_SELECT_STRATEGY,
    DELIVERY_SELECTION_STORE_UPDATE,
    ADDITIONAL_SERVICE_UPDATE,
} from 'Shared/action-types';
import { PENDING, FULFILLED, REJECTED } from '../reducer';

export const initialState = {
    status: FULFILLED,
    errors: [],
    data: {
        strategies: [],
        deliveries: [],
        pickUpStores: [],
        services: [],
        storeId: null,
    },
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case DELIVERY_SELECTION_PENDING:
            return { ...state, status: PENDING, errorMessage: null };
        case DELIVERY_SELECTION_FULFILL:
            return { ...state, status: FULFILLED };
        case DELIVERY_SELECTION_REJECT:
            return {
                ...state,
                status: REJECTED,
                errors: (action.error || {}).errors || [],
                errorMessage: action.errorMessage,
            };
        case DELIVERY_SELECTION_UPDATE:
            const data = action.data.deliverySelection || action.data;
            return {
                ...state,
                data: {
                    ...state.data,
                    ...data,
                },
            };
        case DELIVERY_SELECTION_SELECT:
            return {
                ...state,
                data: {
                    ...state.data,
                    deliveries: setSelectedMethod(action.index, action.data.code, state.data.deliveries),
                },
            };

        case DELIVERY_SELECTION_SELECT_STRATEGY:
            return {
                ...state,
                data: { ...state.data, strategies: setSelectedIndex(action.index, state.data.strategies) },
            };

        case DELIVERY_SELECTION_STORE_UPDATE:
            return {
                ...state,
                data: { ...state.data, storeId: action.data.storeId },
            };

        case ADDITIONAL_SERVICE_UPDATE:
            return {
                ...state,
                data: {
                    ...state.data,
                    services: toggleItem(action.data.code, action.data.active, state.data.services),
                },
            };

        default:
            return state;
    }
}

const toggleItem = (item, enabled, array) => {
    const index = array.findIndex((x) => x === item);
    if (enabled && index < 0) return [...array, item];
    if (!enabled && index >= 0) return [...array.slice(0, index), ...array.slice(index + 1)];
    return [...array];
};

const setSelection = (item, selected = false) => {
    return { ...item, selected };
};

const setSelectedMethod = (index, code, deliveries) => {
    const codeIndex = deliveries[index].options.findIndex((x) => x.code === code);
    const delivery = { ...deliveries[index], options: setSelectedIndex(codeIndex, deliveries[index].options) };

    return combinedArray(index, delivery, deliveries);
};

const setSelectedIndex = (index, array) => {
    const result = [...array.map((item) => setSelection(item, false))];
    if (index < 0) return result;
    result[index] = setSelection(result[index], true);
    return result;
};

const combinedArray = (index, value, array) => {
    const result = [...array];
    result[index] = value;
    return result;
};
