export const groupCartItems = (items) => {
    let groups = [];
    if (!items) return groups;

    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const existingItem = groups.find((group) => item.productId === group.productId);

        if (existingItem) {
            existingItem.quantity += item.quantity;
            existingItem.priceInclDiscount += item.priceInclDiscount;
            existingItem.netAmount += item.netAmount;
            existingItem.discountAmount += item.discountAmount;
            existingItem.groupedIds.push(item.lineId);
            if (item.validationErrorCode !== 0 && existingItem.validationErrorCode === 0)
                existingItem.validationErrorCode = item.validationErrorCode;
        } else {
            groups.push({ ...item, originalQuantity: item.quantity, groupedIds: [] });
        }
    }

    return groups;
};
