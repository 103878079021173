import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as styles from './base.scss';
import { resize } from 'Shared/url';
import Image from 'Shared/Image';
import { moreVariantsLabel } from '../../../Product/Components/VariantsDescriptor';

const variantShape = {
    code: PropTypes.string,
    url: PropTypes.string,
    imageThumb: PropTypes.string,
    name: PropTypes.string.isRequired,
};

const defaultState = {
    fittingElements: 0,
};

const minElementWidth = 35;
const elementSpacing = 12;
const maxElements = 4;

export default class VariantThumbs extends React.Component {
    static get propTypes() {
        return {
            variants: PropTypes.arrayOf(PropTypes.shape(variantShape)),
            variantDescriptor: PropTypes.shape({ sizes: PropTypes.number }),
            count: PropTypes.number,
            onClick: PropTypes.func,
        };
    }

    static defaultProps = {
        variants: [],
        count: null,
    };

    constructor(props) {
        super(props);
        this.state = defaultState;
        this.wrapperRef = null;
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
        this.updateFittingElements();
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    componentDidUpdate() {
        this.updateFittingElements();
    }

    updateFittingElements = () => {
        const { fittingElements } = this.state;
        var currentFittingElements = this.getFittingElements();
        if (fittingElements !== currentFittingElements) {
            this.setState({ fittingElements: currentFittingElements });
        }
    };

    handleResize = (event) => {
        this.updateFittingElements();
    };

    handleClick = (event, variant) => {
        const { onClick } = this.props;
        if (!onClick) return;

        event.preventDefault();
        onClick(variant);
        window.location.href = event.currentTarget.href;
    };

    getWrapperWidth = () => {
        if (!this.wrapperRef) return 0;
        return this.wrapperRef.offsetWidth;
    };

    getFittingElements = () => {
        const width = this.getWrapperWidth();
        return Math.floor(width / (minElementWidth + elementSpacing));
    };

    getVariantsToRender = (variants) => {
        const { fittingElements } = this.state;
        if (fittingElements < 1) return variants;
        if (fittingElements >= variants.length && variants < maxElements) {
            return variants;
        }

        var take = Math.min(fittingElements - 1, maxElements);
        return [...variants.slice(0, take)];
    };

    getShouldRenderText = () => {
        const { descriptor } = this.props;
        return descriptor.sizes > 1;
    };

    render() {
        const { count } = this.props;
        const shouldRender = count > 1;
        if (!shouldRender) return null;
        return (
            <>
                {this.renderVariantList()}
                {this.renderTextRepresentation()}
            </>
        );
    }

    renderVariantList = () => {
        const { variants, count } = this.props;
        if (variants.length < 2) return null;

        const { fittingElements } = this.state;
        const variantsToRender = this.getVariantsToRender(variants);
        const hiddenVariants = variants.length - variantsToRender.length;
        const variantCount = count > 0 ? count - variantsToRender.length : hiddenVariants;
        const renderCount = variantCount > 0 && !this.getShouldRenderText();

        let fittingVariants = fittingElements;
        if (renderCount) fittingVariants--;

        return (
            <ul
                ref={this.setWrapperRef}
                className={classnames(styles.variants, { [styles.long]: variants.length > fittingVariants })}
            >
                {variantsToRender.map((variant, index) => this.renderVariant(variant, index))}
                {renderCount && (
                    <li key={'overflow'} className={styles.frame}>
                        +{variantCount}
                    </li>
                )}
            </ul>
        );
    };

    renderTextRepresentation = () => {
        const { descriptor, count } = this.props;
        if (!this.getShouldRenderText()) return null;
        return <p className={styles.description}>{moreVariantsLabel(descriptor, count)}</p>;
    };

    renderVariant = (variant, index) => {
        return <li key={index}>{this.renderLinkWrapper(variant, this.renderImageTag(variant))}</li>;
    };

    renderImageTag = (variant) => {
        const dimension = Math.ceil(minElementWidth * 1.75);
        return <Image src={resize(variant.imageThumb, dimension, dimension, 'pad', 75)} alt={variant.name} />;
    };

    renderLinkWrapper = (variant, content) => {
        if (!variant || !variant.url) return <>{content}</>;
        return (
            <a href={variant.url} onClick={(e) => this.handleClick(e, variant)}>
                {content}
            </a>
        );
    };
}
