import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { translate, renderTranslation } from 'Shared/translate';
import * as styles from './base.scss';
import { isLoggedIn } from 'Shared/helpers';
import { Price } from 'Shared/Price';
import HeightTransition from 'Shared/HeightTransition';
import PhoneLogin from '../../../Login/Components/PhoneLogin';
import { pathsEqual, withQueryString } from 'Shared/url';

const loginUrl = window.LOGIN_URL || '/login/';
const registerUrl = window.REGISTERURL || '/registrer/';
const checkoutUrl = window.CHECKOUTURL || '/checkout/';

const onCheckoutPage = () => {
    return pathsEqual(location.pathname, checkoutUrl);
};

function CartSavings({ cart, isLoggedIn }) {
    const [loginOpen, setLoginOpen] = useState(false);

    function onToggle(loginOpen) {
        if (loginOpen) setLoginOpen(false);
        else if (!loginOpen) setLoginOpen(true);
    }

    const renderLoginAction = (isLoggedIn) => {
        if (isLoggedIn) return null;
        return renderTranslation('/Cart/Savings/CallToJoin', renderRegisterButton(), renderLoginButton());
    };

    const renderRegisterButton = () => {
        const registerWithReturnUrl = withQueryString(registerUrl, { requestedUrl: window.location.pathname });
        return <a href={registerWithReturnUrl}>{translate('/Cart/Savings/Register')}</a>;
    };

    const renderLoginButton = () => {
        if (onCheckoutPage()) {
            return (
                <a href="#" onClick={() => onToggle(loginOpen)} className={styles.loginBtn}>
                    {translate('/Cart/Savings/Login')}
                </a>
            );
        }

        return (
            <a href={loginUrl} className={styles.loginBtn}>
                {translate('/Cart/Savings/Login')}
            </a>
        );
    };

    if (!cart.clubSavingsTotalAmount) return null;
    return (
        <div className={styles.savings}>
            <div className={styles.savingsMsg}>
                {renderSavings(cart.clubSavingsTotalAmount, isLoggedIn)}
                {renderLoginAction(isLoggedIn)}
            </div>
            {logInDropDown(loginOpen)}
        </div>
    );
}

const logInDropDown = (loginOpen) => {
    return (
        <HeightTransition isOpened={loginOpen}>
            <div className={styles.loginWrapper}>
                <PhoneLogin />
            </div>
        </HeightTransition>
    );
};

const renderSavings = (amount, isLoggedIn) => {
    const showDecimals = onCheckoutPage();

    if (isLoggedIn) {
        return (
            <div className={styles.member}>
                {renderTranslation(
                    '/Cart/Savings/YouSave',
                    <span className={styles.savingsAmount}>
                        <Price price={amount} showDecimals={showDecimals} />
                    </span>,
                )}
            </div>
        );
    }

    return (
        <>
            {renderTranslation(
                '/Cart/Savings/MembersSave',
                <span className={styles.savingsAmount}>
                    <Price price={amount} showDecimals={showDecimals} />
                </span>,
            )}
            <br />
        </>
    );
};

CartSavings.propTypes = {
    cart: PropTypes.shape({
        clubSavingsTotalAmount: PropTypes.number,
    }),
};

export default connect(
    (state) => ({
        cart: state.cart.data,
        isLoggedIn: isLoggedIn(state),
    }),
    (dispatch) => ({}),
)(CartSavings);
