import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import * as styles from './base.scss';
import ProgressBar from 'Shared/ProgressBar';
import { connect } from 'react-redux';
import { translate } from 'Shared/translate';
import { renderHtml } from 'Shared/text-formatting';
import { formatCurrency } from 'Shared/text-formatting';
import AutoWidth from '../../../Shared/AutoWidth';

const sizes = ['full', 'limited'];

const FreeInstallmentProgress = ({ className, amount, total, size }) => {
    if (!total) return null;
    var remaining = total - Math.max(0, Math.min(amount, total));

    const heading =
        amount < total
            ? translate('/Cart/FreeInstallments/AmountLeft', `<b>${formatCurrency(remaining)}</b>`)
            : translate('/Cart/FreeInstallments/DealUnlocked');

    return (
        <div className={cn(className, styles.container, styles[size])}>
            <AutoWidth className={styles.wrapper} adjustment={10}>
                {renderHtml(heading, { className: styles.heading }, 'div')}
                <ProgressBar amount={amount} total={total} />
                <div className={styles.details}>
                    {formatCurrency(amount)}&nbsp;/&nbsp;{formatCurrency(total)}
                </div>
            </AutoWidth>
        </div>
    );
};

FreeInstallmentProgress.propTypes = {
    className: PropTypes.string,
    amount: PropTypes.number,
    total: PropTypes.number,
    size: PropTypes.oneOf(sizes),
};

FreeInstallmentProgress.defaultProps = {
    size: 'full',
};

export default connect(({ cart }) => ({
    total: cart.data.freeInstallmentAmount,
    amount: cart.data.subtotalAmount,
}))(FreeInstallmentProgress);
