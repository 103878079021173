import { RECENTLYADDED_UPDATE, RECENTLYADDED_CLEAR } from 'Shared/action-types';

const initialState = {
    displayRecentlyAddedMessage: false,
    messageType: null,
    textToDisplay: null,
    displayRecentlyAddedMessage: false,
    recentlyAddedButtonText: '',
    recentlyAddedText: '',
    recentlyAddedUrl: '',
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case RECENTLYADDED_UPDATE:
            return Object.assign({}, state, {
                messageType: action.messageType,
                textToDisplay: action.textToDisplay,
                displayRecentlyAddedMessage: true,
                recentlyAddedButtonText: action.recentlyAddedButtonText,
                recentlyAddedText: action.recentlyAddedText,
                recentlyAddedUrl: action.recentlyAddedUrl,
            });
        case RECENTLYADDED_CLEAR:
            return Object.assign({}, state, {
                displayRecentlyAddedMessage: false,
                recentlyAddedButtonText: '',
                recentlyAddedText: '',
                recentlyAddedUrl: '',
            });
        default:
            return state;
    }
}
