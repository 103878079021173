import {
    CURRENT_STORES_CHANGED,
    CURRENT_CITYANDZIP_CHANGED,
    CURRENT_DEPARTMENTS_CHANGED,
    CURRENT_ATTRIBUTES_CHANGED,
    SET_IS_LOADING,
    SET_CURRENT_POSITION,
    SET_CLOSEST_STORES_STATE,
    RESET_FILTERS,
} from 'Shared/action-types';

const initialState = {
    currentStores: window.ALL_STORES,
    cityAndZip: {
        lat: 0,
        lng: 0,
    },
    departments: [],
    attributes: [],
    currentPosition: null,
    isLoading: false,
    isClosestStoresDisplayed: false,
    filtersReset: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CURRENT_STORES_CHANGED:
            return Object.assign({}, state, {
                currentStores: action.currentStores,
            });
        case CURRENT_CITYANDZIP_CHANGED:
            return Object.assign({}, state, {
                cityAndZip: action.cityAndZip,
            });
        case CURRENT_DEPARTMENTS_CHANGED:
            return Object.assign({}, state, {
                departments: action.departments,
            });
        case CURRENT_ATTRIBUTES_CHANGED:
            return Object.assign({}, state, {
                attributes: action.attributes,
            });
        case SET_CURRENT_POSITION:
            return Object.assign({}, state, {
                currentPosition: action.currentPosition,
            });
        case SET_IS_LOADING:
            return Object.assign({}, state, {
                isLoading: action.isLoading,
            });
        case SET_CLOSEST_STORES_STATE:
            return Object.assign({}, state, {
                isClosestStoresDisplayed: action.isClosestStoresDisplayed,
            });
        case RESET_FILTERS:
            return Object.assign({}, state, {
                cityAndZip: {
                    lat: 0,
                    lng: 0,
                },
                departments: [],
                attributes: [],
                filtersReset: action.filtersReset,
            });
        default:
            return state;
    }
}
