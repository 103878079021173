import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from 'Shared/store';
import QuickSearch from './components/QuickSearch';

export function embed(container) {
    const quicksearchurl = container.dataset.quicksearchurl;
    const searchurl = container.dataset.searchurl;

    render(
        <Provider store={store}>
            <QuickSearch url={quicksearchurl} searchPageUrl={searchurl} />
        </Provider>,
        container,
    );
}
