import { RECOMMENDATIONS_UPDATE, RECOMMENDATIONS_PENDING, RECOMMENDATIONS_REJECTED } from 'Shared/action-types';

export const PENDING = 'PENDING';
export const FULFILL = 'FULFILL';
export const REJECT = 'REJECT';

const pageState = window.RECOMMENDATIONS;
const initialState = {
    widgets: {
        any: [],
        basketWidget: [],
        categoryWidget: [],
        homeWidget: [],
        productAlternativesWidget: [],
        productCrossSellsWidget: [],
        searchWidget: [],
        ...pageState,
    },
    status: '',
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case RECOMMENDATIONS_PENDING:
            return {
                ...state,
                status: PENDING,
            };
        case RECOMMENDATIONS_REJECTED:
            return {
                ...state,
                status: REJECT,
            };
        case RECOMMENDATIONS_UPDATE:
            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    ...(!state.widgets.any.length && { any: action.data }),
                    [action.position]: action.data,
                },
                status: FULFILL,
            };
        default:
            return state;
    }
}
