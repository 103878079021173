import {
    SEARCHPAGE_UPDATE,
    SEARCHPAGE_CHANGE_TYPE,
    SEARCHPAGE_ERROR,
    SEARCHPAGE_PENDING,
    SEARCHPAGE_UPDATE_TYPE,
} from 'Shared/action-types';
import { getSelectedType } from './resultHelpers';

export const PENDING = 'PENDING';
export const UPDATING = 'UPDATING';
export const FAILED = 'FAILED';

export const emptyResult = { items: [], totalMatching: 0 };
const pageState = window.SEARCH_DATA || {};

const initialState = {
    data: {
        products: emptyResult,
        brands: emptyResult,
        articles: emptyResult,
        stores: emptyResult,
        ...pageState,
    },
    pending: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SEARCHPAGE_UPDATE:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.data,
                },
                pending: false,
            };
        case SEARCHPAGE_PENDING:
            return {
                ...state,
                pending: true,
            };
        case SEARCHPAGE_ERROR:
            return {
                ...state,
                pending: false,
            };
        case SEARCHPAGE_CHANGE_TYPE:
            return {
                ...state,
                data: {
                    ...state.data,
                    form: {
                        ...state.data.form,
                        type: action.searchType,
                    },
                    hasNextPage: false,
                },
            };
        case SEARCHPAGE_UPDATE_TYPE:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...reduceSelectedType(action.data),
                },
                pending: false,
            };
        default:
            return state;
    }
}

const reduceSelectedType = (data) => {
    var type = getSelectedType(data);
    return {
        [type]: {
            ...data[type],
        },
    };
};
