import React from 'react';
import { connect } from 'react-redux';
import { SMALL } from 'Shared/breakpoints';
import MobileNav from '../MobileNav';
import DesktopNav from '../DesktopNav';
import PropTypes from 'prop-types';
import { itemShape, linkShape } from '../../propTypes';
import { loadMenu } from '../../actions';

const Navigation = (props) => {
    const { showMobile } = props;
    const isMobileTablet = props.breakpoint < SMALL;

    return (
        <div className="navigationWrapper">
            {isMobileTablet ? (
                <MobileNav {...props} isMobileTablet={isMobileTablet} open={showMobile} />
            ) : (
                <DesktopNav {...props} isMobileTablet={isMobileTablet} />
            )}
        </div>
    );
};

Navigation.propTypes = {
    commerceHierarchy: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
    roomsHierarchy: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
    cmsHierarchy: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
    links: PropTypes.arrayOf(PropTypes.shape(linkShape)),
    breakpoint: PropTypes.number.isRequired,
};

export default connect(
    ({ breakpoint, menu }) => ({
        breakpoint,
        ...menu,
    }),
    (dispatch) => ({
        loadMenu: (...args) => {
            dispatch(loadMenu(...args));
        },
        toggleOverlay: (...args) => {
            dispatch(toggleOverlay(...args));
        },
    }),
)(Navigation);
