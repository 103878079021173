import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { debounce } from 'Shared/debounce';
import * as styles from './base.scss';

const showEventName = 'infocircleshow';

export default class InfoCircle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            adjustPosition: null,
        };
        this.circleRef = null;
        this.messageRef = null;
    }

    setCircleRef = (node) => {
        if (this.circleRef) this.circleRef.removeEventListener('mouseover', this.handleHover);
        this.circleRef = node;
        if (this.circleRef) this.circleRef.addEventListener('mouseover', this.handleHover);
    };

    setMessageRef = (node) => {
        this.messageRef = node;
        if (node) this.handleResize();
    };

    componentDidMount() {
        document.addEventListener(showEventName, this.handleUpdate);
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('touchstart', this.handleClickOutside);
        window.addEventListener('resize', this.handleResizeDebounced);
    }

    componentWillUnmount() {
        document.removeEventListener(showEventName, this.handleUpdate);
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('touchstart', this.handleClickOutside);

        if (this.circleRef) this.circleRef.removeEventListener('mouseover', this.handleHover);
        window.removeEventListener('resize', this.handleResizeDebounced);
    }

    handleClickOutside = (event) => {
        if (!this.circleRef) return;
        if (this.circleRef.contains(event.target)) return;

        const { open } = this.state;
        if (!open) return;

        this.setState({ open: false });
    };

    handleUpdate = (event) => {
        const openId = event.details && event.details.id;
        const { id } = this.props;
        const { open } = this.state;

        if (openId !== id && open) {
            this.setState({ open: false });
        }
    };

    handleResize = () => {
        if (!this.messageRef) return;
        const rect = this.messageRef.getBoundingClientRect();
        const { adjustPosition } = this.state;
        const { innerWidth } = window;
        let newAdjustPosition = null;

        if (rect.right > innerWidth) {
            newAdjustPosition = -(rect.right - innerWidth);
        } else if (rect.left < 0) {
            newAdjustPosition = -rect.left;
        }
        if (newAdjustPosition !== adjustPosition) {
            this.setState({ adjustPosition: newAdjustPosition });
        }
    };
    handleResizeDebounced = debounce(200, (event) => this.handleResize(event));

    handleHover = () => {
        this.dispatchShowEvent();
    };

    handleClick = (event) => {
        event.stopPropagation();
        event.preventDefault();

        this.dispatchShowEvent();

        const { open } = this.state;
        this.setState({ open: !open });
    };

    dispatchShowEvent() {
        const showEvent = this.createEvent();
        document.dispatchEvent(showEvent);
    }

    render() {
        const { message, className } = this.props;
        const { open, adjustPosition } = this.state;
        const messageStyles = {
            left: adjustPosition,
        };
        return (
            <a
                ref={this.setCircleRef}
                className={classnames(styles.info, className, { [styles.open]: open })}
                onClick={this.handleClick}
                role="tooltip"
            >
                <i className="far fa-info-circle" aria-hidden="true"></i>
                <div className={styles.tooltip}>
                    <div ref={this.setMessageRef} className={styles.message} style={messageStyles}>
                        {message}
                    </div>
                </div>
            </a>
        );
    }

    createEvent = () => {
        const { id } = this.props;
        let event = document.createEvent('Event');
        event.initEvent(showEventName, true, true);
        event.details = { id };
        return event;
    };
}

InfoCircle.propTypes = {
    id: PropTypes.string,
    message: PropTypes.string.isRequired,
    className: PropTypes.string,
};
