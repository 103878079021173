import { hasAuthSession, authSession } from 'Shared/session-helper';
import { registerPageEvents } from './events';
import { registerCartEvents } from '../Cart/events';
import { updateRecommendations } from '../Recommendations/actions';

const authExists = hasAuthSession();
const user = authExists ? authSession() : null;
const lang = 'nb-NO';
const currency = 'NOK';
const callbacks = window.PeeriusCallbacks;
const site = window.PeeriusSite;
const clientToken = window.PeeriusClientToken;
const recsDisabled = window.PeeriusRecommendationsDisabled;
const currentURI = window.location.href;
const previousURI = document.referrer || '';

let tracker = null;
let session = null;
let cuid = null;

export function registerPeeriusTracking() {
    if (!callbacks) return;
    if (!site) return;
    if (recsDisabled) return;

    callbacks.smartRecs = smartRecommendations;
    callbacks.info = testInfo;
    callbacks.apiVersion = 'v1_4';

    registerPageEvents((event) => trackPage(event.details));
    registerCartEvents(trackBasket);

    window.addEventListener('DOMContentLoaded', () => {
        tracker = window.Peerius;
        if (!tracker) return;
        checkExistingSession();
        //console.log('Peerius cuid', cuid);
        //console.log('Peerius session', session);
    });
}

const checkExistingSession = () => {
    const existingSession = tracker.getSessionId();
    if (!existingSession) {
        setNewSession();
        return;
    }
    session = existingSession;
    const userCookie = tracker.rCookie('peerius_user');
    if (!userCookie) return;
    const cookieParts = userCookie.split(':');
    if (cookieParts.length < 2) return;
    cuid = cookieParts[1];
};

const setNewSession = () => {
    session = 'new';
    cuid = 'new';
};

export const trackProduct = (data) => {
    const product = mapProduct(data);
    if (!product) return;
    send('product', product);
};

export const trackCategory = (data) => {
    const category = mapCategory(data);
    if (!category) return;
    send('category', category);
};

export const trackRecommendation = (data) => {
    const { recommendationId } = data || {};
    if (!recommendationId) return;
    if (!tracker) return;
    tracker.smartRecsClick(recommendationId);
};

export const trackImpressions = (data) => {
    const impressions = mapImpressions(data);
    if (!impressions) return;
    if (impressions.length < 1) return;
    sendImpressions(impressions);
};

export const trackCheckout = (data) => {
    const checkout = mapCheckout(data);
    if (!checkout) return;
    send('checkout', checkout);
};

export const trackBasket = (event) => {
    const cart = mapCart(event && event.details);
    if (!cart) return;
    send('basket', cart);
};

export const trackSearch = (data) => {
    const search = mapSearch(data);
    if (!search) return;
    send('searchresults', search);
};

export const trackBrand = (data) => {
    const brand = mapBrand(data);
    if (!brand) return;
    send('brand', brand);
};

const trackPage = (details) => {
    const { type, data } = details;
    switch (type) {
        case 'StartPage':
            return trackHome(data);
        case 'RoomPage':
            return trackCategory(data);
        case 'WebNode':
            return trackCategory(data);
        case 'WebProduct':
            return trackProduct(data);
        case 'BrandPage':
            return trackBrand(data);
        case 'OrderConfirmationPage':
            return trackOrder(data);
        case 'CheckoutPage':
            return trackCheckout(data);
        case 'SearchPage':
            return trackSearch(data);
        default:
            return trackOther(data);
    }
};

const trackHome = () => {
    send('home');
};

const trackOther = () => {
    send('other');
};

const trackOrder = (data) => {
    if (!data) return;
    const order = mapOrder(data);
    if (!order) return;
    send('order', order);
};

const smartRecommendations = (data) => {
    //console.log('Smart recommendations', data);
    if (recsDisabled) return;
    if (!data) return;
    for (const { position, recs } of data) {
        if (!position) continue;
        if (!recs) continue;
        updateRecommendations(position, recs);
    }
};

const testInfo = (data) => {
    //console.log('A/B testing info', data);
};

const send = (type, data) => {
    if (!callbacks) return;
    const parameters = getParameters(type, data);
    callbacks.track = parameters;
    if (!tracker) return;

    const url = getRestPath(parameters);
    try {
        tracker.sendAjax(url);
    } catch (error) {
        console.error(error);
    }
};

const sendImpressions = (impressions) => {
    if (!tracker) return;
    const url = getShownPath(impressions);
    try {
        tracker.sendAjax(url);
    } catch (error) {
        console.error(error);
    }
};

const getRestPath = (parameters) => {
    const json = JSON.stringify(parameters);
    return `rest.pagex?jd=${encodeURIComponent(json)}`;
};

const getShownPath = (impressions) => {
    return `${site}/rec/shown.pagex?ids=${impressions.join()}`;
};

const mapSearch = (data) => {
    const { form, products } = data;
    if (!form) return null;
    if (!products) return null;
    if (form.type !== undefined && form.type !== 'Products') return null;
    const productItems = products.items || [];

    return {
        term: form.q,
        totalNumberOfResults: form.totalCount,
        pageNumber: form.page,
        resultsOnPage: productItems.length,
        topResults: mapSearchResults(productItems),
        filters: mapSearchFilters(products),
    };
};

const mapSearchResults = (data) => {
    const top = data.slice(0, 3);
    return top.map((item) => mapProduct(item));
};

const mapSearchFilters = (data) => {
    if (!data) return [];
    if (!data.facets) return [];
    let facets = {};
    for (const facetGroup of data.facets) {
        for (const facet of facetGroup.facets) {
            const key = facetGroup.groupFieldName;
            if (!facet.selected) continue;
            if (!facets[key]) {
                facets[key] = [];
            }
            facets[key].push(facet.key);
        }
    }

    let filters = [];
    for (const key in facets) {
        filters.push({
            filter: key,
            operator: 'IN',
            values: facets[key],
        });
    }
    return filters;
};

const mapProduct = (data) => {
    return {
        refCode: data.variantCode || data.code,
    };
};

const mapOrder = (data) => {
    if (!data) return null;
    return {
        orderNo: data.referenceId,
        items: data.items ? data.items.map((item) => mapOrderItem(item)) : [],
        currency,
        subtotal: data.subtotalWithoutCurrency,
        shipping: data.chargeWithoutCurrency,
        total: data.totalWithoutCurrency,
    };
};

const mapOrderItem = (data) => {
    const qty = data.quantity;
    return {
        ...mapProduct(data),
        //...mapSku(data),
        qty,
        price: data.priceIncDiscountWithoutCurrency / qty, // NOTE: This price is expected to be "per unit" in Peerius.
    };
};

const mapCart = (data) => {
    if (!data) return null;
    return {
        items: data.items ? data.items.map((item) => mapCartItem(item)) : [],
        currency,
    };
};

const mapImpressions = (data) => {
    if (!data) return null;
    return data.reduce((a, b) => {
        if (b.recommendationId) {
            a.push(b.recommendationId);
        }
        return a;
    }, []);
};

const mapCheckout = (data) => {
    if (!data) return null;
    return {
        ...mapCart(data),
        subtotal: data.subtotalAmount,
        shipping: data.estimatedShippingAmount,
        total: data.totalAmount,
    };
};

const mapCartItem = (data) => {
    if (!data) return null;
    const qty = data.quantity;
    return {
        ...mapProduct(data),
        //...mapSku(data),
        qty,
        price: data.priceInclDiscount / qty, // NOTE: This price is expected to be "per unit" in Peerius.
    };
};

const mapCategory = (data) => {
    if (!data.categoryIdentifier) return null;
    return data.categoryIdentifier;
};

const mapBrand = (data) => {
    if (!data.brand) return null;
    return data.brand;
};

const mapUser = (user) => {
    return {
        id: user.customerId,
    };
};

const getParameters = (type, data) => {
    let parameters = {
        site,
        type,
        lang,
        clientToken,
        currentURI,
        previousURI,
        recContent: 'refCodeOnly',
        smartRecs: {
            markRecsAsShown: false,
        },
    };
    if (session) parameters.session = session;
    if (cuid) parameters.cuid = cuid;
    if (type === 'searchresults') type = 'searchResults'; // Remap odd casing in searchResults type
    if (data) parameters[type] = data;
    if (user && user.customerId) parameters.user = mapUser(user);
    return parameters;
};
