import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ProductList from './index';
import * as styles from './base.scss';
import { PENDING, FULFILL, REJECT } from '../../../../Recommendations/reducer';
import { recommendationsVisible } from '../../../../Recommendations/events';
import { registerWidgetPosition, unregisterWidgetPosition } from '../../../../Recommendations/widgets';
import { useIntersectionObserver } from 'Shared/Hooks';

const anyWidgetPosition = 'any';

const ProductRecommendationsList = ({ status, products, widgetPosition, isMobile }) => {
    const ref = useRef();
    const entry = useIntersectionObserver(ref, { threshold: 0.5, freezeOnceVisible: true });
    useEffect(() => {
        registerWidgetPosition(widgetPosition);
        return () => unregisterWidgetPosition(widgetPosition);
    }, []);
    useEffect(() => {
        if (status !== FULFILL) return;
        if (!entry.isIntersecting) return;
        if (!products || !products.items || !products.items.length) return;
        recommendationsVisible(products.items);
    }, [status, entry.isIntersecting]);
    if (status === PENDING) {
        return (
            <div ref={ref}>
                <div className={styles.loader}></div>
            </div>
        );
    }
    return (
        <div className={styles.recommendations} ref={ref}>
            <ProductList products={products} isMobile={isMobile} />
        </div>
    );
};

ProductRecommendationsList.propTypes = {
    ...ProductList.propTypes,
    status: PropTypes.oneOf([PENDING, FULFILL, REJECT, '']),
    widgetPosition: PropTypes.string,
};

ProductRecommendationsList.defaultProps = {
    products: {
        totalMatching: 0,
        items: [],
    },
    widgetPosition: anyWidgetPosition,
    disableSwiper: false,
};

export default connect(({ recommendations }, ownProps) => {
    const items = recommendations.widgets[ownProps.widgetPosition || anyWidgetPosition] || [];
    return {
        products: {
            totalMatching: items.length,
            items,
        },
        status: recommendations.status,
    };
})(ProductRecommendationsList);
