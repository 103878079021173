import React from 'react';
import { connect } from 'react-redux';
import { update, remove, updateCartInfo, toggleMiniCart, miniCartHover } from './actions';
import ItemList from './Components/ItemList';
import FreeInstallmentProgress from './Components/FreeInstallmentProgress';
import { PENDING } from './reducer';
import { SMALL } from 'Shared/breakpoints';
import { translate } from 'Shared/translate';
import Button from 'Shared/Button';
import classnames from 'classnames';
import * as styles from './miniCart.scss';
import { toggleDimmedArea } from 'Shared/DimmedArea/actions';
import { Icon } from 'Shared/icon';
import { outsideClickHandler, hideAllOverlays } from 'Shared/customfunctions';
import { removeFromCartEventV4, viewCartV4 } from '../Shared/googleTagManagerFunctionsV4';
import { removeTrailingSlash } from 'Shared/text-formatting';

class MiniCart extends React.Component {
    constructor(props) {
        super(props);

        this.cartArea = null;
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmout() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    handleClickOutside = outsideClickHandler(this, () => {
        if (this.props.miniCartVisible) {
            this.props.toggleDimmedArea(false);
            this.props.toggleMiniCart(false);
        }
    });

    componentWillReceiveProps(nextProps) {
        const cartItemsExist = nextProps.cart.items && nextProps.cart.items.length > 0;

        if (!cartItemsExist) {
            this.props.toggleDimmedArea(false);
            this.props.toggleMiniCart(false);

            return;
        }

        if (this.props.breakpoint < SMALL && nextProps.cart.items.length <= 0 && this.props.miniCartVisible) {
            this.props.toggleDimmedArea(!this.props.miniCartVisible);
        }
    }

    componentDidUpdate() {
        if (this.props.cart && this.itemsInCart(this.props.cart.items) <= 0) {
            const header = document.querySelector('.mainMenu--open');

            if (this.props.searchVisible || header) {
                return;
            }

            this.props.toggleDimmedArea(false);
        }
    }

    toggleMiniCart = () => {
        hideAllOverlays('MINI_CART');
        this.props.toggleMiniCart(!this.props.miniCartVisible);

        if (this.itemsInCart(this.props.cart.items) > 0 && this.props.breakpoint < SMALL) {
            this.props.toggleDimmedArea(!this.props.miniCartVisible);
        }

        if (!this.props.miniCartVisible) {
            viewCartV4(this.props.cart);
        }
    };

    calculateHeight() {
        return window.innerHeight;
    }

    itemsInCart(items) {
        return (items && items.reduce((count, item) => count + item.quantity, 0)) || 0;
    }

    isCheckout() {
        const pathName = removeTrailingSlash(window.location.pathname);
        const checkouturl = removeTrailingSlash(this.props.checkouturl);
        return pathName.endsWith(checkouturl);
    }

    updateItemFromCart = (lineId, quantity) => {
        this.props.update(this.props.cart.cartType, lineId, quantity);
    };

    removeItemFromCart = (lineId) => {
        const cartItem = this.props.cart.items.find((i) => i.lineId == lineId);
        this.props.remove(this.props.cart.cartType, lineId).then((data) => {
            removeFromCartEventV4(cartItem);
            this.props.updateCartInfo(data || {});
        });
    };

    render() {
        if (this.isCheckout()) {
            return null;
        }

        const totalItemsInCart = this.itemsInCart(this.props.cart.items);
        const minicartVisible = this.props.miniCartVisible && totalItemsInCart > 0;
        const isMobile = this.props.breakpoint < SMALL;
        const cartIsEmpty = Object.keys(this.props.cart).length === 0;

        return (
            <div
                ref={this.setWrapperRef}
                className={styles.miniCart}
                onMouseEnter={() => this.props.miniCartHover(true)}
                onMouseLeave={() => this.props.miniCartHover(false)}
            >
                <button
                    className={styles.cartButton}
                    onClick={this.toggleMiniCart}
                    aria-label={translate('/Header/MiniCart/Title')}
                >
                    <span className={styles.iconWithCounter}>
                        <i className="fontIcon far fa-shopping-cart" />
                        {totalItemsInCart > 0 && (
                            <span className={styles.totalInHeader}>
                                <span className={styles.totalItemsInCart}>{`${totalItemsInCart}`}</span>
                            </span>
                        )}
                    </span>
                </button>
                {!cartIsEmpty && (
                    <div
                        ref={(cartArea) => (this.cartArea = cartArea)}
                        className={classnames(styles.cartContent, {
                            [styles.displayCartContent]: minicartVisible,
                        })}
                    >
                        {!isMobile && (
                            <div>
                                <div className={styles.borderArrowPrimary} />
                                <div className={styles.borderArrowSecondary} />
                            </div>
                        )}
                        <div className={styles.titleArea}>
                            <span className={styles.title}>{translate('/Header/MiniCart/Title')}</span>

                            {isMobile && (
                                <button className={styles.closeButton} onClick={this.toggleMiniCart} aria-label="Close">
                                    <Icon size={'md-14'} className={'closeicon'} />
                                </button>
                            )}
                        </div>

                        <div className={styles.cartContentWrapper}>
                            <div className={styles.cartMiddleContent}>
                                <ItemList
                                    items={this.props.cart.items}
                                    update={this.updateItemFromCart}
                                    remove={this.removeItemFromCart}
                                    breakpoint={this.props.breakpoint}
                                    cartIsPending={this.props.isPending}
                                    showBrand
                                    isMobileView
                                />
                            </div>
                        </div>
                        <div className={styles.cartBottomContent}>
                            <div className={styles.totalSum}>
                                <span>{translate('/Cart/Summary/CartTotal')}</span>
                                <span>{totalItemsInCart > 0 ? this.props.cart.subtotalWithCurrency : 0}</span>
                            </div>
                            <FreeInstallmentProgress className={styles.freeInstallmentProgress} size={'limited'} />
                        </div>
                        <Button className={styles.goToCheckout} href={this.props.checkouturl}>
                            {translate('/Header/MiniCart/Button/GoToCheckout')}
                        </Button>
                    </div>
                )}
            </div>
        );
    }
}

export default connect(
    (state) => ({
        status: state.cart.status,
        cart: state.cart.data,
        isPending: state.cart.status === PENDING,
        breakpoint: state.breakpoint,
        miniCartVisible: state.cart.miniCartVisible,
        searchVisible: state.searchResults.show,
    }),
    (dispatch) => ({
        update: (...args) => dispatch(update(...args)),
        remove: (...args) => dispatch(remove(...args)),
        toggleMiniCart: (displayMiniCart) => dispatch(toggleMiniCart(displayMiniCart)),
        updateCartInfo: (data) => dispatch(updateCartInfo(data)),
        toggleDimmedArea: (show) => dispatch(toggleDimmedArea(show)),
        miniCartHover: (hover) => dispatch(miniCartHover(hover)),
    }),
)(MiniCart);
