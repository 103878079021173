import { matches } from './dom';

const captureEvents = ['focus', 'blur'];

export function on(event, target, callback) {
    if (arguments.length === 2) {
        // eslint-disable-line no-magic-numbers
        [target, callback] = [callback, target];
    }

    const handler = (e) => {
        const el = e.target;
        if (typeof target !== 'string' || matches(el, target)) {
            callback(e);
        }
    };

    document.addEventListener(event, handler, captureEvents.indexOf(event) !== -1);

    // Returns a function which removes the event listener
    return () => document.removeEventListener(event, handler);
}

export function one(event, target, callback) {
    if (arguments.length === 2) {
        // eslint-disable-line no-magic-numbers
        [target, callback] = [callback, target];
    }

    const unsubscribe = on(event, target, (e) => {
        unsubscribe();
        callback(e);
    });

    return unsubscribe;
}

export const createEvent = (eventName, data) => {
    let event = document.createEvent('Event');
    event.initEvent(eventName, true, true);
    event.details = data;
    return event;
};

export function addListener(name, callback) {
    if (!name) return;
    if (!callback) return;
    document.addEventListener(name, callback);
}

export function removeListener(name, callback) {
    if (!name) return;
    if (!callback) return;
    document.removeEventListener(name, callback);
}

export function dispatchEvent(name, data) {
    const event = createEvent(name, data);
    document.dispatchEvent(event);
}

const pageViewEventName = 'onPageView';

export function pageView() {
    const data = window.PAGE_DATA;
    const type = window.PAGE_TYPE;
    if (!type) return;
    dispatchEvent(pageViewEventName, { type, data });
}

export function registerPageEvents(pageViewCallback) {
    addListener(pageViewEventName, pageViewCallback);
}

export function unregisterPageEvents(pageViewCallback) {
    removeListener(pageViewEventName, pageViewCallback);
}
