import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { FromPrice, MemberPrice } from 'Shared/Price';
import { renderNbsp } from 'Shared/text-formatting';
import { priceShape } from 'Shared/Price';
import * as styles from './base.scss';

export function PriceArea(props) {
    const { className } = props;
    return <div className={classnames(styles.priceArea, className)}>{renderPrice(props)}</div>;
}

const getClasses = (props) => {
    const { alignLeft, alignRight, noMargin, largerFont, inline } = props;
    return {
        [styles.left]: alignLeft,
        [styles.right]: alignRight,
        [styles.noMargin]: noMargin,
        [styles.largerFont]: largerFont,
        [styles.inline]: inline,
    };
};

const renderPrice = (props) => {
    const { price } = props;
    if (price.isMemberPrice && price.isPriceAdjusted) return renderMemberPrice(props);
    if (price.isPriceAdjusted) return renderAdjustedPrice(props);
    return renderBasePrice(props);
};

const renderMemberPrice = (props) => {
    const { className, price, hideBasePrice, numberOfVariants } = props;
    return (
        <div className={classnames(styles.productPrice, className, getClasses(props))}>
            {!hideBasePrice && (
                <span className={styles.basePrice}>
                    <FromPrice price={price.basePrice} numberOfVariants={numberOfVariants} />
                </span>
            )}
            <span className={classnames(styles.adjustedPrice, styles.memberPrice)}>
                <MemberPrice price={price.adjustedPrice} numberOfVariants={numberOfVariants} />
            </span>
        </div>
    );
};

const renderAdjustedPrice = (props) => {
    const { className, price, hideBasePrice, numberOfVariants } = props;
    return (
        <div className={classnames(styles.productPrice, styles.discount, className, getClasses(props))}>
            <span className={styles.adjustedPrice}>
                <FromPrice price={price.adjustedPrice} numberOfVariants={numberOfVariants} />{' '}
            </span>
            {!hideBasePrice && <span className={styles.basePrice}>{renderNbsp(price.basePrice)}</span>}
        </div>
    );
};

const renderBasePrice = (props) => {
    const { className, price, numberOfVariants } = props;
    return (
        <div className={classnames(styles.productPrice, className, getClasses(props))}>
            <span className={styles.basePrice}>
                <FromPrice price={price.basePrice} numberOfVariants={numberOfVariants} />{' '}
            </span>
        </div>
    );
};

PriceArea.propTypes = {
    price: PropTypes.shape(priceShape),
    className: PropTypes.string,
    hideBasePrice: PropTypes.bool,
    numberOfVariants: PropTypes.number,
    alignLeft: PropTypes.bool,
    alignRight: PropTypes.bool,
    noMargin: PropTypes.bool,
    largerFont: PropTypes.bool,
    inline: PropTypes.bool,
};
