import { SEARCH_BY_REGION_EXPAND } from 'Shared/action-types';

const initialState = {
    expanded: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SEARCH_BY_REGION_EXPAND:
            return Object.assign({}, state, {
                expanded: action.expand,
            });
        default:
            return state;
    }
}
