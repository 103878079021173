import {
    PROFILEPAGE_PENDING,
    PROFILEPAGE_FULFILL,
    PROFILEPAGE_REJECT,
    PROFILEPAGE_UPDATE,
    PROFILEPAGE_CHANGE,
    PROFILEPAGE_CONSENTS_PENDING,
    PROFILEPAGE_CONSENTS_FULFILL,
    PROFILEPAGE_CONSENTS_REJECT,
    PROFILEPAGE_CONSENTS_UPDATE,
    PROFILEPAGE_CONSENTS_CHANGE,
    PROFILE_ADDRESS_PENDING,
    PROFILE_ADDRESS_FULFILL,
    PROFILE_ADDRESS_REJECT,
    PROFILE_ADDRESS_UPDATE,
    PROFILE_ADDRESS_CHANGE,
    PROFILE_ADDRESS_ADD,
    PROFILE_ADDRESS_REMOVE,
} from 'Shared/action-types';

export const PENDING = 'PENDING';
export const REJECTED = 'REJECTED';
export const UPDATING = 'UPDATING';
export const FAILED = 'FAILED';

const pageProfile = window.PROFILE || {};
const pageConsents = window.PROFILE_CONSENTS || {};
const pageAddresses = window.PROFILE_ADDRESSES;

const defaultProfileData = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    streetAddress: '',
    zipCode: '',
    city: '',
    favouriteStoreId: '',
};
const defaultConsentData = {
    consentInfoViaSms: false,
    consentInfoViaEmail: false,
    consentThirdPartyTracking: false,
};
const defaultDataState = {
    status: '',
    error: false,
    loading: false,
    unsavedChanges: false,
};
const defaultState = {
    profile: {},
    consents: {},
    addresses: [],
};
export const defaultAddress = {
    recordId: null,
    streetAddress: '',
    zipCode: '',
    city: '',
    unsavedChanges: false,
    loading: false,
    error: false,
};

const initialState = {
    ...defaultState,
    profile: {
        ...defaultDataState,
        data: {
            ...defaultProfileData,
            ...pageProfile,
        },
    },
    consents: {
        ...defaultDataState,
        data: {
            ...defaultConsentData,
            ...pageConsents,
        },
    },
    addresses: pageAddresses || defaultState.addresses,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case PROFILEPAGE_CHANGE:
            return reduce(state, 'profile', { unsavedChanges: true, error: false }, action.data);

        case PROFILEPAGE_PENDING:
            return reduce(state, 'profile', { status: PENDING, loading: true, error: false });

        case PROFILEPAGE_FULFILL:
            return reduce(state, 'profile', { status: FULFILLED, loading: false });

        case PROFILEPAGE_REJECT:
            return reduce(state, 'profile', { status: REJECTED, loading: false, error: true });

        case PROFILEPAGE_UPDATE:
            return reduce(state, 'profile', { loading: false, unsavedChanges: false }, action.data);

        case PROFILEPAGE_CONSENTS_CHANGE:
            return reduce(state, 'consents', { unsavedChanges: true, error: false }, action.data);

        case PROFILEPAGE_CONSENTS_PENDING:
            return reduce(state, 'consents', { status: PENDING, loading: true, error: false });

        case PROFILEPAGE_CONSENTS_FULFILL:
            return reduce(state, 'consents', { status: FULFILLED, loading: false });

        case PROFILEPAGE_CONSENTS_REJECT:
            return reduce(state, 'consents', { status: REJECTED, loading: false, error: true });

        case PROFILEPAGE_CONSENTS_UPDATE:
            return reduce(state, 'consents', { loading: false, unsavedChanges: false }, action.data);

        case PROFILE_ADDRESS_CHANGE:
            return reduceAddress(state, action, { unsavedChanges: true, error: false });

        case PROFILE_ADDRESS_PENDING:
            return reduceAddress(state, action, { loading: true });

        case PROFILE_ADDRESS_FULFILL:
            return reduceAddress(state, action, { loading: false });

        case PROFILE_ADDRESS_REJECT:
            return reduceAddress(state, action, { error: true, loading: false });

        case PROFILE_ADDRESS_UPDATE:
            return reduceAddress(state, action, { unsavedChanges: false });

        case PROFILE_ADDRESS_ADD:
            return {
                ...state,
                addresses: [...state.addresses, defaultAddress],
            };
        case PROFILE_ADDRESS_REMOVE:
            return {
                ...state,
                addresses: removeFromArray(state.addresses, action.index),
            };

        default:
            return state;
    }
}

const reduce = (state, section, props, data) => {
    props = props || {};
    if (!data) return { ...state, [section]: { ...state[section], ...props } };
    return { ...state, [section]: { ...state[section], ...props, data: { ...state[section].data, ...data } } };
};

const reduceAddress = (state, action, additional) => {
    const change = mergeData(action, additional || {});
    return { ...state, addresses: mergeArray(state.addresses, change) };
};

const mergeData = (existing, merge) => {
    return { ...existing, data: { ...existing.data, ...merge } };
};

const mergeArray = (existing, action) => {
    const { index, data } = action;
    let result = [...existing];

    if (result[index]) result[index] = { ...result[index], ...data };
    else result[index] = { ...data };

    return result;
};

const removeFromArray = (array, index) => {
    let result = [...array];
    result.splice(index, 1);

    return result;
};
