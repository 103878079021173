import React from 'react';
import { updateParents, setNewWidth, forceWidth } from './width';
import classnames from 'classnames';
import * as styles from './base.scss';

class AutoWidth extends React.Component {
    constructor(props) {
        super(props);
        this.elementRef;
        this.state = {
            rendered: false,
        };
    }

    static defaultProps = {
        element: 'div',
        adjustment: 0,
        autoUpdateParents: true,
    };

    componentDidMount() {
        this.updateWidth();
    }

    componentDidUpdate() {
        this.updateWidth();
    }

    setElementRef = (node) => {
        const { setElementRef } = this.props;
        this.elementRef = node;
        if (setElementRef) setElementRef(node);
    };

    updateWidth = () => {
        const element = this.elementRef;
        if (!element) return;

        const { forcedHeight, adjustment, autoUpdateParents } = this.props;
        const { rendered } = this.state;

        if (this.isDefined(forcedHeight)) {
            forceWidth(element, styles.autoWidth, forcedHeight, rendered, !autoUpdateParents);
        } else {
            setNewWidth(element, styles.autoWidth, adjustment, !autoUpdateParents);
        }

        if (autoUpdateParents) updateParents(styles.autoWidth);
        if (!rendered) this.setState({ rendered: true });
    };

    isDefined(value) {
        if (value === null) return false;
        if (value === undefined) return false;
        return true;
    }

    render() {
        const { props } = this;
        const { className, children, element } = props;
        const Element = element;

        return (
            <Element ref={this.setElementRef} className={classnames(styles.autoWidth, className)}>
                {children}
            </Element>
        );
    }
}

export default AutoWidth;
