import bezierEasing from 'bezier-easing';
import lerp from 'lerp';

const element = document.documentElement;
const frameRate = 60;
const pollingTime = 1000 / frameRate;

let pollingIntervalId = null;
let stopTimeoutId = null;

export const scrollImmediate = (position) => {
    element.style.scrollBehavior = 'auto';
    element.scrollTop = position;
    element.style.scrollBehavior = '';
};

export const scrollInterpolated = (targetTop, time, p1x, p1y, p2x, p2y) => {
    const easing = bezierEasing(p1x, p1y, p2x, p2y);
    const startTop = element.scrollTop;
    const startTime = new Date().getTime();

    element.style.scrollBehavior = 'auto';

    if (pollingIntervalId !== null) stopScrolling();

    pollingIntervalId = setInterval(
        () => (element.scrollTop = calculateEasing(easing, startTop, targetTop, startTime, time)),
        pollingTime,
    );

    stopTimeoutId = setTimeout(() => stopScrolling(), time);
};

const calculateEasing = (easing, startTop, targetTop, startTime, interval) => {
    return lerp(startTop, targetTop, easing(getAlpha(startTime, interval)));
};

const getAlpha = (startTime, interval) => {
    const currentTime = new Date().getTime();
    const endTime = startTime + interval;
    if (currentTime > endTime) return 1.0;
    if (currentTime < startTime) return 0.0;
    return (currentTime - startTime) / interval;
};

const stopScrolling = () => {
    element.style.scrollBehavior = '';
    clearInterval(pollingIntervalId);
    clearTimeout(stopTimeoutId);
    pollingIntervalId = null;
    stopTimeoutId = null;
};
