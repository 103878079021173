import {
    SHOW_SPINNER,
    HIDE_SPINNER,
} from 'Shared/action-types';

const initialState = {
    isLoading: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case SHOW_SPINNER:
        return { ...state, isLoading: true };
    case HIDE_SPINNER:
        return { ...state, isLoading: false };
    
    default:
        return state;
    }
}
