import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SMALL } from 'Shared/breakpoints';
import { translate } from 'Shared/translate';
import { flatTree } from '../../util';
import * as styles from './base.scss';

const profileMenu = window.PROFILE_MENU;

class LoginMenu extends React.Component {
    constructor(props) {
        super(props);
        this.hoverTimeout = null;
        this.wrapperRef = null;
        this.state = {
            menuIsOpen: false,
        };
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmout() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
            this.setState({ menuIsOpen: false });
        }
    };

    onMouseClickUserIcon = (event) => {
        const { isLoggedIn } = this.props;
        if (!isLoggedIn) return;
        if (!this.shouldShowMenu()) return;

        event.preventDefault();

        const { menuIsOpen } = this.state;

        this.setState({ menuIsOpen: !menuIsOpen });
    };

    debounceHover = (action) => {
        if (this.hoverTimeout) clearTimeout(this.hoverTimeout);
        this.hoverTimeout = setTimeout(action, 300);
    };

    shouldShowMenu = () => {
        const { hideForDesktop, hideForMobile, isMobile } = this.props;
        if (hideForMobile && isMobile) return false;
        if (hideForDesktop && !isMobile) return false;
        return true;
    };

    render() {
        const { isLoggedIn, isMobile } = this.props;
        const { signOutUrl } = this.props;
        const { menuIsOpen } = this.state;

        const renderMenu = this.shouldShowMenu() && isLoggedIn;
        const menu = renderMenu ? flatTree(profileMenu, (x) => x.children) : [];

        return (
            <div className={styles.loginMenu} ref={this.setWrapperRef}>
                {this.renderUserIcon()}
                {renderMenu && (
                    <div
                        className={classnames(styles.loginMenuContent, {
                            [styles.mobile]: isMobile,
                            [styles.show]: menuIsOpen,
                        })}
                    >
                        {menu.map((item, index) => this.renderMenuItem(item, index))}
                        {this.renderMenuButton(signOutUrl, translate('/Login/Menu/Logout'))}
                    </div>
                )}
            </div>
        );
    }

    renderUserIcon() {
        const { isMobile, isLoggedIn } = this.props;
        const icon = isMobile ? 'fa-user-circle' : 'fa-user';
        const label = isLoggedIn ? 'My Pages Link' : translate('/Header/Account/SignedIn');
        const linkUrl = isLoggedIn ? this.props.profileUrl : this.props.signInUrl;

        return (
            <a className={styles.loginButton} onClick={this.onMouseClickUserIcon} href={linkUrl} aria-label={label}>
                <i className={`fontIcon far ${icon}`} />
            </a>
        );
    }

    renderMenuItem(item, key) {
        const { url, name, isActive } = item;
        if (!url) return null;

        const href = isActive ? null : url;
        return (
            <a key={key} className={classnames(styles.menuItem, { [styles.active]: isActive })} href={href}>
                {name}
            </a>
        );
    }

    renderMenuButton(url, content) {
        if (!url) return null;
        return (
            <a className={styles.menuButton} href={url}>
                {content}
            </a>
        );
    }
}

LoginMenu.propTypes = {
    isLoggedIn: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
    loginStatus: PropTypes.any,
    searchVisible: PropTypes.bool,
    hideForMobile: PropTypes.bool,
    hideForDesktop: PropTypes.bool,
    profileUrl: PropTypes.string,
    signInUrl: PropTypes.string,
    signOutUrl: PropTypes.string,
};

LoginMenu.defaultProps = {
    hideForMobile: true,
};

export default connect(
    (state) => ({
        isMobile: state.breakpoint < SMALL,
        isLoggedIn: state.login.isAuthenticated && state.login.isRegistered,
        loginStatus: state.login.status,
        searchVisible: state.searchResults.show,
    }),
    (dispatch) => ({}),
)(LoginMenu);
