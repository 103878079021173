import {
    CART_PENDING,
    CART_UPDATE,
    CART_FULFILL,
    CART_REJECT,
    DISPLAY_MINICART,
    DISPLAY_ADDED_MODAL,
    HIDE_ADDED_MODAL,
    MINICART_HOVER,
} from 'Shared/action-types';
import genericRequestAction from 'Shared/generic-request-action';
import { getJson } from 'Shared/server';

export const request = genericRequestAction({
    pending: CART_PENDING,
    update: CART_UPDATE,
    fulfill: CART_FULFILL,
    reject: CART_REJECT,
});

export function getCart(skipEvents) {
    return (dispatch) => {
        return getJson('/Cart/GetCart').then(
            (data) => {
                dispatch({ type: CART_UPDATE, data, skipEvents });
            },
            () => {
                dispatch({ type: CART_REJECT });
                return Promise.reject(null);
            },
        );
    };
}

export function add(code, quantity = 1, checkoutType = 0, storeId = null) {
    return request('/Cart/AddToCart', { code, quantity, storeId, checkoutType });
}

export function addManyToCart(codes) {
    return request('/Cart/AddManyToCart', { codes });
}

export function addBundle(kitCode, codes) {
    return request('/Cart/AddBundleToCart', { kitCode, codes });
}

export function remove(cartType, lineIds) {
    return request('/Cart/RemoveItems', { lineIds: Array.isArray(lineIds) ? lineIds : [lineIds], cartType });
}

export function update(cartType, lineId, quantity) {
    return request('/Cart/UpdateItems', { items: [{ lineId, quantity }], cartType });
}

export function validateCart() {
    return request('/Cart/ValidateCart', { useCache: false });
}

export function setCheckoutType(cartType, checkoutType, storeId) {
    return request('/Cart/SetCheckoutType', { cartType, checkoutType, storeId });
}

export function addCouponCode(code) {
    return request('/Checkout/AddCouponCode', { couponCode: code });
}

export function removeCouponCode(code) {
    return request('/Checkout/RemoveCouponCode', { couponCode: code });
}

export function updateCartInfo(data) {
    return (dispatch) => {
        dispatch({
            type: CART_UPDATE,
            data,
        });
    };
}

export function showAddedToCart(message, showRelated, item) {
    return (dispatch) => {
        dispatch({
            type: DISPLAY_ADDED_MODAL,
            item,
            message,
            showRelated,
        });
    };
}

export function hideAddedToCart() {
    return (dispatch) => {
        dispatch({
            type: HIDE_ADDED_MODAL,
        });
    };
}

export function toggleMiniCart(displayMiniCart) {
    return (dispatch) => {
        dispatch({
            type: DISPLAY_MINICART,
            miniCartVisible: displayMiniCart,
        });
    };
}

export function miniCartHover(miniCartHover) {
    return (dispatch) => {
        dispatch({
            type: MINICART_HOVER,
            miniCartHover,
        });
    };
}
