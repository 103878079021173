import { cartUpdated, cartCleared } from './events';
import { CART_UPDATE, PAYMENT_UPDATE } from 'Shared/action-types';

export const cartMiddleware = (store) => (next) => (action) => {
    if (action.type === CART_UPDATE && hasValidCart(action)) {
        const { skipEvents } = action;
        const state = store.getState();
        const totalUpdate = differentTotal(state, action);
        if (!skipEvents && (totalUpdate || differentQuantity(state, action))) {
            if (isCartCleared(state, action.data)) {
                cartCleared(action.data);
            } else {
                cartUpdated(action.data);
            }
        }
        if (totalUpdate) {
            store.dispatch({ type: PAYMENT_UPDATE, total: action.data.totalAmount });
        }
    }
    return next(action);
};

export const differentTotal = (state, action) => {
    const newTotal = action.data.totalAmount;
    const oldTotal = state.cart.data && state.cart.data.totalAmount;

    return newTotal !== oldTotal;
};

export const differentQuantity = (state, action) => {
    const newCartItems = (action && action.data && action.data.items) || [];
    const oldCartItems = (state && state.cart && state.cart.data && state.cart.data.items) || [];

    if (newCartItems.length !== oldCartItems.length) return true;

    const newQuantity = getQuantitySum(newCartItems);
    const oldQuantity = getQuantitySum(oldCartItems);

    return newQuantity !== oldQuantity;
};

const getQuantitySum = (cartItems) => {
    return cartItems.reduce((count, item) => count + item.quantity, 0);
};

const hasValidCart = (action) => {
    return action && action.data && action.data.items;
};

const isCartCleared = (state, cart) => {
    const oldCartItems = (state.cart.data && state.cart.data.items) || [];
    return oldCartItems.length > 0 && cart.items.length === 0;
};
