import 'url-search-params-polyfill';
import { removeTrailingSlash } from './text-formatting';

export const actionUrl = (action) => {
    const controller = window.location.pathname;
    return controllerUrl(controller, action);
};

export const controllerUrl = (path, action) => {
    const location = removeTrailingSlash(path);
    return location + '/' + action;
};

export const pathsEqual = (first, second) => {
    if (typeof first === 'string') first = first.toLowerCase();
    if (typeof second === 'string') second = second.toLowerCase();

    return removeTrailingSlash(first) === removeTrailingSlash(second);
};

export const getUrlParameters = (encodedParameters) => {
    const searchParams = new URLSearchParams(encodedParameters);

    let decodedParams = {};
    searchParams.forEach(function (value, key) {
        decodedParams[key] = decodeURIComponent(value);
    });

    return decodedParams;
};

export const getQueryString = (parameterObject) => {
    const cleanedParameters = clean(parameterObject);
    const count = Object.keys(cleanedParameters).length;
    if (count < 1) return '';

    let encodedParams = {};
    for (const key in cleanedParameters) {
        encodedParams[key] = encodeURIComponent(cleanedParameters[key]);
    }

    return '?' + buildQuery(encodedParams);
};

export const getUpdatedQueryString = (parameterObject) => {
    const { href, search } = window.location;
    const url = stripQueryStringAndHashFromPath(href);
    const parameters = join(getUrlParameters(search), parameterObject);
    const queryString = getQueryString(parameters);
    return url + queryString;
};

export const withQueryString = (href, parameterObject) => {
    const url = stripQueryStringAndHashFromPath(href);
    const queryString = getQueryString(parameterObject);
    return url + queryString;
};

export const updateQueryString = (parameterObject, stateObject = {}) => {
    const url = getUpdatedQueryString(parameterObject);
    history.replaceState(stateObject, document.title, url);
};

export const resize = (url, width, height, rmode, quality, format) => {
    let bgcolor = null;
    if (rmode == 'pad') bgcolor = 'FFFFFF';
    return resizedUrl(url, { width, height, rmode, bgcolor, quality, format });
};

export const resizedUrl = (url, params) => {
    if (!url) return '';
    const path = stripQueryStringAndHashFromPath(url);
    if (!path) return '';
    const query = stripPathFromQuery(url);
    const queryParams = getUrlParameters(query);
    const queryString = getQueryString(join(queryParams, params));
    return path + queryString;
};

export const join = (first, second) => {
    return { ...clean(first, second), ...clean(second) };
};

export const clean = (object, filters) => {
    let result = {};
    const filterKeys = (filters && Object.keys(filters)) || [];
    for (const [key, value] of Object.entries(object)) {
        if (!value) continue;
        if (filterKeys.includes(key)) continue;
        result[key] = value;
    }
    return result;
};

export const fill = (object) => {
    let result = {};
    for (const [key, value] of Object.entries(object)) {
        result[key] = value || '';
    }
    return result;
};

export const stripQueryStringAndHashFromPath = (url) => {
    return url.split('?')[0].split('#')[0];
};

export const stripPathFromQuery = (url) => {
    if (!url) return '';
    if (url.indexOf('?') < 0) return '';
    return '?' + url.split('?')[1];
};

const buildQuery = (object) => {
    let result = '';
    let first = true;
    for (const [key, value] of Object.entries(object)) {
        if (isUndefined(value)) continue;
        if (!first) result += '&';
        else first = false;
        result += `${key}=${value}`;
    }
    return result;
};

const isUndefined = (value) => {
    if (value === undefined || value === null) return true;
    return false;
};
