export const getWidthRatio = (blockSize) => {
    switch (blockSize) {
        case 'halfWidth':
            return 0.5;
        case 'oneThirdWidth':
            return 0.3333;
        case 'twoThirdsWidth':
            return 0.6667;
        case 'oneQuarterWidth':
            return 0.25;
        case 'threeQuartersWidth':
            return 0.75;
        default:
            return 1.0;
    }
};
