import React from 'react';
import classnames from 'classnames';
import Button, { appearanceTypes } from 'Shared/Button';
import * as styles from './quantity.scss';

const quantity = ({
    min,
    max,
    quantity,
    adjustQuantity,
    error,
    className,
    buttonClass,
    iconClass,
    labelClass,
    onDecrement,
    onIncrement,
}) => {
    const calculatedQuantity = quantity + adjustQuantity;
    const appearance = error ? appearanceTypes.roundError : appearanceTypes.round;
    const icons = error ? 'fa' : iconClass || 'far';
    return (
        <div className={classnames(styles.base, className)}>
            <Button
                className={buttonClass}
                onClick={onDecrement}
                appearance={appearance}
                aria-label="Remove"
                disabled={isDefined(min) && calculatedQuantity <= min}
            >
                <i className={classnames(icons, 'fa-minus')} />
            </Button>
            <span className={classnames(styles.quantity, labelClass, { [styles.error]: error })}>
                {calculatedQuantity}
            </span>
            <Button
                className={buttonClass}
                onClick={onIncrement}
                appearance={appearance}
                aria-label="Add"
                disabled={isDefined(max) && calculatedQuantity >= max}
            >
                <i className={classnames(icons, 'fa-plus')} />
            </Button>
        </div>
    );
};

const isDefined = (value) => {
    return value !== null && value !== undefined;
};

export default quantity;
