import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { showAddedToCart, hideAddedToCart } from '../../actions';
import { translate } from 'Shared/translate';
import Thumbnail from '../Thumbnail';
import CartSavings from '../CartSavings';
import ItemQuantity from './ItemQuantity';
import Button, { appearanceTypes } from 'Shared/Button';
import RelatedItems from 'Shared/RelatedItems';
import ActionWindow from 'Shared/ActionWindow';
import FreeInstallmentProgress from '../FreeInstallmentProgress';
import { PENDING } from '../../reducer';
import * as styles from './base.scss';

function AddedToCart({ cart, showRelated, showSavings, item, checkoutUrl, hideAddedToCart, isPending, ...rest }) {
    const { sellMoreHeading, sellMoreItems } = cart;
    return (
        <ActionWindow
            disabled={isPending}
            toggleModal={hideAddedToCart}
            status={
                <div className={styles.icon}>
                    <i className={classnames('far', 'fa-check')}></i>
                </div>
            }
            details={
                <>
                    {item && <Thumbnail {...item} />}
                    {showSavings && (
                        <div className={styles.savingsWrapper}>
                            <CartSavings />
                        </div>
                    )}
                </>
            }
            actions={
                <>
                    <ItemQuantity item={item} />
                    <Button
                        appearance={appearanceTypes.invert}
                        onClick={() => (window.location.href = checkoutUrl)}
                        disabled={isPending}
                    >
                        {translate('/Cart/AddedToCart/GoToCheckout')}
                    </Button>
                    <Button appearance={appearanceTypes.white} onClick={hideAddedToCart} disabled={isPending}>
                        {translate('/Cart/AddedToCart/ContinueShopping')}
                    </Button>
                    <div className={styles.installmentProgress}>
                        <FreeInstallmentProgress size={'limited'} />
                    </div>
                </>
            }
            {...rest}
        >
            {showRelated && sellMoreItems && <RelatedItems heading={sellMoreHeading} items={sellMoreItems} />}
        </ActionWindow>
    );
}

export default connect(
    ({ cart }) => ({
        ...cart.addedToCart,
        checkoutUrl: cart.checkoutUrl,
        cart: cart.data,
        isPending: cart.status === PENDING,
    }),
    (dispatch) => ({
        showAddedToCart: (...params) => dispatch(showAddedToCart(...params)),
        hideAddedToCart: () => dispatch(hideAddedToCart()),
    }),
)(AddedToCart);
