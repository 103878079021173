import React from 'react';
import Star, { starType } from '../Star';

export const Stars = ({ className, starClassName, score }) => {
    return (
        <>
            {getStars(className, starClassName, score).map((star, index) => (
                <React.Fragment key={index}>
                    <Star {...star} />
                </React.Fragment>
            ))}
        </>
    );
};

Stars.defaultProps = {
    className: null,
    starClassName: null,
};

export const getStars = (className, starClassName, score) => {
    let stars = new Array(5);
    for (let i = 1; i <= 5; i++) {
        const type = getRenderStyle(i, score);
        stars[i - 1] = { type, className, starClassName };
    }
    return stars;
};

const getRenderStyle = (index, score) => {
    if (index > Math.ceil(score)) return starType.empty;
    if (index <= Math.ceil(score) && index > Math.floor(score)) return starType.half;
    return starType.filled;
};
