export const checkoutTypes = {
    online: 0,
    collect: 1,
};

export const getTypeName = (type) => {
    switch (type) {
        case checkoutTypes.collect : return 'collect';
        case checkoutTypes.online: return 'online';
    }
}