export const getSelectedType = (result) => {
    const { form } = result;
    const { type } = form;
    return type && type.toLowerCase();
};

export const getResultType = (result, type) => {
    return result[type || getSelectedType(result)] || {};
};

export const getResultCountExcluding = (result, ...excluding) => {
    return reduceResult(result, (type) => type.totalMatching || 0, ...excluding);
};

export const getResultItemsExcluding = (result, ...excluding) => {
    return reduceResult(result, (type) => (type.items && type.items.length) || 0, ...excluding);
};

export const getSortSelection = (data) => {
    const sort = data.sort || [];
    const selection = sort.find((x) => x.selected);
    if (!selection) return '0';
    return selection.value;
};

export const getFacetSelections = (data, selector) => {
    let selectedFacets = [];
    const facets = data.facets || [];
    facets.map((group) =>
        group.facets.map((facet) => {
            if (facet.selected) {
                selectedFacets.push(selector(group, facet));
            }
        }),
    );
    return selectedFacets;
};

export const hasResultType = (result, type) => {
    const resultType = getResultType(result, type);
    return resultType.items && resultType.items.length > 0 ? 1 : 0;
};

const reduceResult = (result, reducer, ...excluding) => {
    let count = 0;
    for (const type of Object.keys(result)) {
        if (excluding.includes(type)) continue;
        const resultType = getResultType(result, type);
        const reduction = reducer(resultType);
        count += reduction;
    }
    return count;
};

export const getMutableSearchData = (data, type) => {
    let form = { ...data.form, page: 1, type };
    let searchType = { ...getResultType(data, type) };
    return [form, searchType];
};
