import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'Shared/translate';
import cn from 'classnames';
import AutoHeight from 'Shared/AutoHeight';
import GlobalCategoryList from '../GlobalCategoryList';
import CategoryList from 'Shared/CategoryList';
import ProductRecommendationsList from '../ProductList/recommendations';
import CardList from '../CardList';
import ProductList from '../ProductList';
import StoreList from '../StoreList';
import LinkTable from '../LinkTable';
import * as styles from './base.scss';
import * as sharedStyles from '../../shared.scss';
import { debounce } from 'Shared/debounce';
import { SMALL } from 'Shared/breakpoints';
import { ENTER, ESC } from 'Shared/keyboard';
import { renderHtml } from 'Shared/text-formatting';
import { hasResultType, getResultType, getResultCountExcluding, getResultItemsExcluding } from '../../../resultHelpers';
import { search, updateSearchQuery, reset, toggleQuickSearch } from '../../actions';
import { lock, unlock, clearBodyLocks } from 'tua-body-scroll-lock';
import { focusQuickSearch } from '../../../events';
import { isReflowing } from './utils';
import { loadMenu } from 'Shared/Menu/actions';
import { cardSize } from '../Card';

class QuickSearch extends React.Component {
    constructor(props) {
        super(props);
        const { query } = props;
        this.state = { pending: false, focused: false };
        this.inputRef = createRef(null);
        this.containerRef = createRef(null);
        this.contentContainerRef = createRef(null);
        this.componentRef = createRef(null);
        this.queryRef = createRef(query);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('touchstart', this.handleClickOutside);
        document.addEventListener('focusin', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('touchstart', this.handleClickOutside);
        document.removeEventListener('focusin', this.handleClickOutside);
        clearBodyLocks();
    }

    componentDidUpdate(prevProps) {
        const { isMobile, show } = this.props;
        const showChanged = prevProps.show !== show;

        if (showChanged && isMobile) {
            if (!show) {
                unlock(this.containerRef.current);
            } else {
                lock(this.containerRef.current);
            }
        }
    }

    searchDebounce = debounce(500, () => this.handleSearch());

    handleClickOutside = (event) => {
        if (isReflowing(this.componentRef.current)) return;
        if (this.componentRef.current && this.componentRef.current.contains(event.target)) return;
        if (this.containerRef.current && this.containerRef.current.contains(event.target)) return;
        this.closeSearch(event);
    };

    setAutoHeightRef = (node) => {
        this.containerRef.current = node;
    };

    handleChange = (event) => {
        const { updateSearchQuery } = this.props;
        this.queryRef.current = event.target.value;
        if (this.queryRef.current.length > 1) this.setState({ pending: true });
        updateSearchQuery(this.queryRef.current);
        this.searchDebounce();
    };

    handleFocus = () => {
        const { menuStatus, loadMenu, toggleQuickSearch } = this.props;

        this.setState({ focused: true }, () => {
            focusQuickSearch();
            toggleQuickSearch(true);
        });

        if (menuStatus === 'PENDING') {
            loadMenu();
        }
    };

    closeSearch = () => {
        const { show, reset, toggleQuickSearch } = this.props;
        if (show) {
            reset();
            this.queryRef.current = null;
            this.setState({ pending: false }, () => toggleQuickSearch(false));
        }

        if (this.inputRef.current) {
            this.inputRef.current.blur();
        }
    };

    handleSearch = () => {
        const { url, search } = this.props;
        return search(url, this.queryRef.current, () => this.setState({ pending: false }));
    };

    submitSearch = () => {
        const { show, searchPageUrl } = this.props;
        const q = this.queryRef.current;
        if (!q || q.length < 2 || !show) return;
        window.location.href = `${searchPageUrl}?q=${q}`;
    };

    clearSearch = () => {
        const { updateSearchQuery } = this.props;
        this.queryRef.current = '';
        updateSearchQuery('');
    };

    handleKeyDown = (event) => {
        switch (event.keyCode) {
            case ENTER:
                event.preventDefault();
                this.submitSearch();
                break;
            case ESC:
                event.preventDefault();
                this.closeSearch(event);
                break;
            default:
                break;
        }
    };

    render() {
        const { show, result, status, query, isMobile } = this.props;
        const { pending, focused } = this.state;
        const placeholder = translate('/Header/Search/Placeholder');

        const forcedHeight = show ? null : 0;
        const hasQuery = this.queryRef.current && this.queryRef.current.length > 1;
        const hasResult = getHasResult(result);

        const totalResults = getResultCountExcluding(result, 'categories');
        const totalItems = getResultItemsExcluding(result, 'categories');
        const showButton = hasQuery && totalResults > totalItems && totalResults > 0;
        const showLoading = show && hasQuery && (status === 'PENDING' || pending);

        return (
            <div className={cn(styles.quickSearch, { [styles.open]: show })}>
                <div className={styles.container}>
                    <div ref={this.componentRef} className={cn(styles.searchInput, { [styles.open]: show })}>
                        {isMobile && (
                            <button
                                className={cn(styles.icon, styles.cancel)}
                                aria-label="cancel"
                                onClick={this.closeSearch}
                            >
                                <i className="fa fa-arrow-left"></i>
                            </button>
                        )}
                        <input
                            type="text"
                            ref={this.inputRef}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            onFocus={this.handleFocus}
                            placeholder={placeholder}
                            value={query}
                        />
                        {(!isMobile || !show) && (
                            <button type="submit" className={styles.icon} aria-label={placeholder}>
                                <i className="fas fa-search"></i>
                            </button>
                        )}
                        {isMobile && show && hasQuery && (
                            <button
                                className={cn(styles.icon, styles.clear)}
                                aria-label="clear"
                                onClick={this.clearSearch}
                            >
                                <i className="fa fa-times"></i>
                            </button>
                        )}
                    </div>
                    {focused && (
                        <AutoHeight
                            setElementRef={this.setAutoHeightRef}
                            forcedHeight={forcedHeight}
                            animationClass={styles.animating}
                            className={cn(styles.content, styles.animating, {
                                [styles.loading]: showLoading,
                                [styles.result]: hasResult,
                                [styles.ready]: hasResult && !showLoading,
                            })}
                        >
                            <div
                                className={cn(styles.loader, {
                                    [styles.visible]: showLoading,
                                })}
                            ></div>
                            <div
                                ref={this.contentContainerRef}
                                className={cn(styles.contentContainer, {
                                    [styles.hidden]: showLoading,
                                })}
                            >
                                {hasQuery ? (
                                    <>
                                        {renderSection(null, styles.categories, () =>
                                            renderCategories(result, isMobile),
                                        )}
                                        {renderSection(null, styles.message, () => renderMessage(result))}
                                        {renderSection(null, styles.suggestions, () =>
                                            renderSuggestions(result, isMobile, showLoading),
                                        )}
                                        {renderSection(null, styles.products, () => renderProducts(result, isMobile))}
                                        <div className={styles.columnContainer}>
                                            {renderSection(translate('/Header/Search/Brands'), styles.brands, () =>
                                                renderBrands(result, isMobile),
                                            )}
                                            {renderSection(translate('/Header/Search/Articles'), null, () =>
                                                renderArticles(result, isMobile),
                                            )}
                                            {renderSection(translate('/Header/Search/Stores'), null, () =>
                                                renderStores(result, isMobile),
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    renderPresearch(this.queryRef.current)
                                )}
                                {showButton && (
                                    <div className={styles.bottom}>
                                        <button className="component-button black" onClick={this.submitSearch}>
                                            {translate('/Header/Search/ShowAllResults')} ({totalResults})
                                        </button>
                                    </div>
                                )}
                            </div>
                        </AutoHeight>
                    )}
                </div>
            </div>
        );
    }
}

QuickSearch.propTypes = {
    url: PropTypes.string.isRequired,
    searchPageUrl: PropTypes.string.isRequired,
    search: PropTypes.func.isRequired,
    query: PropTypes.string,
    show: PropTypes.bool,
    status: PropTypes.string,
    menuStatus: PropTypes.string,
    isMobile: PropTypes.bool,
};

export default connect(mapStateToProps, (dispatch) => mapDispatchToProps(dispatch))(QuickSearch);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        query: state.searchResults.searchQuery,
        result: state.searchResults.searchResults,
        show: state.searchResults.show,
        status: state.searchResults.status,
        menuStatus: state.menu.status,
        isMobile: state.breakpoint < SMALL,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        search: (...params) => dispatch(search(...params)),
        reset: () => dispatch(reset()),
        toggleQuickSearch: (value) => dispatch(toggleQuickSearch(value)),
        updateSearchQuery: (query) => dispatch(updateSearchQuery(query)),
        loadMenu: () => dispatch(loadMenu()),
    };
}

const renderPresearch = () => {
    return (
        <>
            <p className={cn(styles.presearchTitle, 'h2-format')}>{translate('/QuickSearch/NoSearch/Heading')}</p>
            <GlobalCategoryList className={styles.presearchContent} />
        </>
    );
};

const renderSection = (title, className, renderer) => {
    const children = renderer();
    if (!children) return null;
    return (
        <div className={cn(styles.section, className)}>
            {renderHeading(title)}
            {children}
        </div>
    );
};

const renderHeading = (title) => {
    return <>{title && <h3 className={cn('h6-format-desktop', styles.heading)}>{title}</h3>}</>;
};

const getCardSize = (items, size, sizeItems, max) => {
    const length = max ? Math.min(items.length, max) : items.length;
    let result = [];
    for (let i = 0; i < length; i++) {
        if (!sizeItems || i < sizeItems) {
            result.push({
                ...items[i],
                size,
            });
        } else {
            result.push(items[i]);
        }
    }
    return result;
};

const getHasResult = (result) => {
    if (result && !Array.isArray(result)) return true;
    return false;
};

const renderMessage = (result) => {
    if (!result) return null;
    const { resultTitle, resultText } = result;

    if (!resultTitle) return null;
    return (
        <>
            <h2 className={cn('h2-format', styles.messageTitle)}>{resultTitle}</h2>
            {resultText && <p className={styles.messageBody}>{resultText}</p>}
        </>
    );
};

const renderSuggestions = (result, isMobile, isLoading) => {
    if (isLoading) return null;
    const totalResults = getResultCountExcluding(result);
    if (totalResults > 0) return null;
    return (
        <>
            <h3 className={cn('h6-format-desktop', styles.heading)}>{translate('/QuickSearch/ShopByCategory')}</h3>
            <GlobalCategoryList renderSwiper={true} isMobile={isMobile} />
            <div className={cn(styles.divider, styles.large)} />
            <ProductRecommendationsList widgetPosition={'searchWidget'} isMobile={isMobile} />
        </>
    );
};

const renderCategories = (result) => {
    const categoryResult = getResultType(result, 'categories');
    const categories = categoryResult.items || [];
    if (categories.length < 1) return null;
    return (
        <>
            <CategoryList categories={categories} />
            <div className={cn(styles.divider, styles.large)} />
        </>
    );
};

const renderProducts = (result, isMobile) => {
    if (!result) return null;
    const productsResult = getResultType(result, 'products');
    const products = productsResult.items || [];
    if (products.length < 1) return null;
    const followingResults = getResultCountExcluding(result, 'products', 'categories');
    const otherResultTypes = getResultItemsExcluding(result, 'products');
    return (
        <>
            <ProductList
                products={productsResult}
                isMobile={isMobile}
                centeredList={otherResultTypes < 1 && products.length < 5}
            />
            {followingResults > 0 && <div className={styles.divider} />}
        </>
    );
};

const renderBrands = (result, isMobile) => {
    const brands = getResultType(result, 'brands');
    const items = brands.items || [];
    if (items < 1) return null;
    const { description } = items[0];
    if (items > 1 || !description) {
        return renderTable(brands, 10);
    }
    const { name, url } = items[0];
    return (
        <div>
            <p>
                <a className={sharedStyles.contentLink} href={url}>
                    {renderHtml(name, { className: 'underline' }, 'span')}
                </a>
            </p>
            {renderHtml(description, null, 'p')}
            <p>
                <a className={cn(sharedStyles.allResultsLink, styles.link)} href={url}>
                    {translate('/Header/Search/ReadMore')}
                </a>
            </p>
        </div>
    );
};

const renderArticles = (result, isMobile) => {
    const articles = getResultType(result, 'articles');
    const { totalMatching, allResultsUrl } = articles;
    let items = articles.items || [];
    if (items.length < 1) return null;
    if (!isMobile) {
        const hasProductResults = hasResultType(result, 'products');
        const hasBrandResults = hasResultType(result, 'brands');
        const hasStoreResults = hasResultType(result, 'stores');

        if (!hasProductResults && !hasStoreResults && !hasBrandResults) {
            items = getCardSize(items, cardSize.medium, null, 4);
        } else if (!hasStoreResults && !hasBrandResults) {
            items = getCardSize(items, cardSize.medium, 1);
        } else if (!hasStoreResults) {
            items = getCardSize(items, cardSize.medium, 1, 4);
        } else if (hasStoreResults && hasBrandResults) {
            items = items.splice(0, 3);
        } else {
            items = items.splice(0, 6);
        }
    }

    const showLink = items.length < totalMatching;
    return (
        <>
            <CardList cards={items} renderSwiper={isMobile} />
            {showLink && (
                <a className={cn(sharedStyles.allResultsLink, styles.link)} href={allResultsUrl}>
                    {translate('/Header/Search/ShowMoreArticles')}
                    &nbsp;{`(${totalMatching})`}
                </a>
            )}
        </>
    );
};

const renderStores = (result, isMobile) => {
    const stores = getResultType(result, 'stores');
    const { totalMatching, allResultsUrl } = stores;
    let items = stores.items || [];
    if (items.length < 1) return null;
    if (!isMobile) {
        const hasProductResults = hasResultType(result, 'products');
        const hasBrandResults = hasResultType(result, 'brands');
        const hasArticleResults = hasResultType(result, 'articles');

        if (!hasProductResults && !hasArticleResults && !hasBrandResults) {
            items = getCardSize(items, cardSize.medium, null, 4);
        } else if (!hasArticleResults && !hasBrandResults) {
            items = getCardSize(items, cardSize.medium, 1);
        } else if (!hasArticleResults) {
            items = getCardSize(items, cardSize.medium, 1, 4);
        } else if (hasArticleResults && hasBrandResults) {
            items.length = 3;
        } else {
            items.length = 6;
        }
    }

    const showLink = items.length < totalMatching;
    return (
        <>
            <StoreList stores={items} isMobile={isMobile} />
            {showLink && (
                <a className={cn(sharedStyles.allResultsLink, styles.link)} href={allResultsUrl}>
                    {translate('/Header/Search/ShowAllStores')}
                    &nbsp;{`(${totalMatching})`}
                </a>
            )}
        </>
    );
};

const renderTable = (result, maxItems) => {
    const { items, allResultsUrl } = result;
    if (!items) return null;
    const count = items.length;
    const showLink = count > maxItems;
    return (
        <>
            <LinkTable columns={2} links={items} max={maxItems} />
            {showLink && (
                <a className={sharedStyles.allResultsLink} href={allResultsUrl}>
                    {translate('/Header/Search/ReadMore')}
                </a>
            )}
        </>
    );
};
