export const isLoggedIn = (state) => {
    if (!state || !state.login) return false;

    const { isAuthenticated, isRegistered } = state.login;
    return isAuthenticated && isRegistered;
};

export const followLink = (event, callback) => {
    if (!event || !event.currentTarget) return;
    event.preventDefault();
    try {
        callback(event.currentTarget);
    } finally {
        const { href, target } = getLinkAttributes(event);
        navigate(href, target);
    }
};

export const thenFollowLink = (event, promise) => {
    if (!event || !event.currentTarget || !promise) return;
    event.preventDefault();
    const { href, target } = getLinkAttributes(event);

    promise.finally(() => navigate(href, target));
};

const getLinkAttributes = (event) => {
    const target = event.ctrlKey ? '_blank' : '_self';
    const { currentTarget } = event;
    const { href } = currentTarget;
    return { href, target };
};

const navigate = (href, target) => {
    window.open(href, target);
};
