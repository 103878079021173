import {
    CLOSE_MODAL,
    OPEN_MODAL,
    SET_BTN_TEXT,
    SET_IS_POSITION_SHARED,
    SET_CONTENT_STATE,
    GEO_LOADING,
    GEO_CURRENT_POSITION,
    SET_MODAL_DATA,
    SET_MESSAGE,
} from 'Shared/action-types';
import { postJson, getJson } from 'Shared/server.js';

export const INIT = 'INIT';
export const DISPLAY_ALL_STORES = 'DISPLAY_ALL_STORES';
export const DISPLAY_CLOSEST_STORES = 'DISPLAY_CLOSEST_STORES';
export const DISPLAY_ALL_STORES_WITHOUT_BTN = 'DISPLAY_ALL_STORES_WITHOUT_BTN';

export function setUserLocalStore(localStore) {
    return postJson('/ProfilePage/UpdateUserLocalStore', { localStore }).then(response => {
        return response;
    });
}

export function openModal() {
    return dispatch => {
        dispatch({
            type: OPEN_MODAL,
        });
    };
}

export function closeModal() {
    return dispatch => {
        dispatch({
            type: CLOSE_MODAL,
        });
    };
}

export function setPositionShared(isPositionShared) {
    return dispatch => {
        dispatch({
            type: SET_IS_POSITION_SHARED,
            isPositionShared,
        });
    };
}

export function setContentState(contentState) {
    return dispatch => {
        dispatch({
            type: SET_CONTENT_STATE,
            contentState,
        });
    };
}

export function setBtnText(btnText) {
    return dispatch => {
        dispatch({
            type: SET_BTN_TEXT,
            btnText,
        });
    };
}

export function setLoading(isLoading) {
    return dispatch => {
        dispatch({
            type: GEO_LOADING,
            isLoading,
        });
    };
}

export function setCurrentPosition(currentPosition) {
    return dispatch => {
        dispatch({
            type: GEO_CURRENT_POSITION,
            currentPosition,
        });
    };
}

export function setModalData(modalData) {
    return dispatch => {
        dispatch({
            type: SET_MODAL_DATA,
            modalData,
        });
    };
}

let timer = null;
const TIMER_COUNT = 5000;
export function displayWarningMessage(message) {
    return dispatch => {
        dispatch({
            type: SET_MESSAGE,
            message,
        });

        if (timer) {
            clearTimeout(timer);
            timer = null;
        }

        timer = setTimeout(() => {
            dispatch({
                type: SET_MESSAGE,
            });
        }, TIMER_COUNT);
    };
}

export function calculDistance(lat1, lon1, lat2, lon2) {
    const toRadiens = deg => deg * (Math.PI / 180);

    const r = 6371;
    const dLat = toRadiens(lat2 - lat1);
    const dLon = toRadiens(lon2 - lon1);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(toRadiens(lat1)) * Math.cos(toRadiens(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return r * c;
}

export const fetchStoreAvailability = productCode => {
    return getJson(`/product/GetStoreAvailability?code=${productCode}`).then(response => {
        return response;
    });
};
