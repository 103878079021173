import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';
import { itemShape, linkShape } from '../../propTypes';
import PropTypes from 'prop-types';
import ExpandingLevel from '../ExpandingLevel';
import Thumbnail from '../Thumbnail';
import AutoHeight from '../../../AutoHeight';
import { showPageContent, hidePageContent, animationTiming, defaultEasing } from '../../util';
import { scrollInterpolated } from 'Shared/scroll';
import { debounce } from 'Shared/debounce';
import { PENDING } from '../../reducer';

const deriveHierarchy = (props) => {
    const { commerceHierarchy, roomsHierarchy, cmsHierarchy } = props;
    return {
        name: 'ROOT',
        url: 'ROOT',
        level: 0,
        children: commerceHierarchy.concat(roomsHierarchy).concat(cmsHierarchy),
    };
};

const setParentRefAndLevel = (item) => {
    item.children = item.children || [];
    item.children.forEach((child) => {
        child.parentItem = item;
        child.level = item.level + 1;
        setParentRefAndLevel(child);
    });
};

const setCurItemFromActive = (item, state) => {
    if (item.isActive) {
        if (!item.children || item.children.length === 0) {
            state.curItem = item.parentItem;
        } else {
            state.curItem = item;
        }
    }
    item.children.forEach((child) => {
        setCurItemFromActive(child, state);
    });
};

export default class MobileNav extends React.Component {
    static get propTypes() {
        return {
            commerceHierarchy: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
            roomsHierarchy: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
            cmsHierarchy: PropTypes.arrayOf(PropTypes.shape(itemShape)).isRequired,
            links: PropTypes.arrayOf(PropTypes.shape(linkShape)),
            open: PropTypes.bool.isRequired,
            isMobileTablet: PropTypes.bool,
            breakpoint: PropTypes.number,
        };
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (state.status === nextProps.status) return null;
        let hierarchy = deriveHierarchy(nextProps);
        let newState = {
            menuHierarchy: hierarchy,
            curItem: hierarchy,
            status: nextProps.status,
        };
        //NOTE: This is a remnant from previous implementation
        //State was mutated directly in the constructor
        setParentRefAndLevel(hierarchy);
        setCurItemFromActive(hierarchy, newState);
        return newState;
    }

    constructor(props) {
        super(props);
        const { status } = props;
        this.state = {
            ...deriveHierarchy(props),
            render: false,
            forceClosed: false,
            hideContent: false,
            animateFromLeft: false,
            curItem: null,
            contentHeight: null,
            status,
        };
        this.heightRef = null;
        this.portalElement = document.getElementById('MobileNavPortal');
        this.contentElement = document.getElementsByClassName('page-content')[0];
        this.state.curItem = this.state.menuHierarchy; //The root
    }

    setHeightRef = (node) => {
        this.heightRef = node;
    };

    componentDidUpdate(prevProps) {
        const { open } = this.props;
        if (open && !prevProps.open) {
            const { scrollTop } = document.documentElement;
            this.setState({ forceClosed: false, render: true, scrollTop }, () => {
                hidePageContent();
                this.scrollWindow(0);
            });
        } else if (!open && prevProps.open) {
            const { scrollTop } = this.state;

            this.setState({ forceClosed: true }, () => {
                showPageContent();
                this.scrollWindow(scrollTop);
            });
        }
    }

    scrollWindow = (position) => {
        const { p1, p2 } = defaultEasing;
        scrollInterpolated(position, animationTiming, p1.x, p1.y, p2.x, p2.y);
    };

    setStateDebounced = debounce(animationTiming, this.setState);

    navigateToChild = (e, url) => {
        e.preventDefault();

        const curItem = this.state.curItem;
        const childIndex = curItem.children.findIndex((item) => {
            return item.url === url;
        });

        if (childIndex === -1) {
            console.warn('Did not find child ' + url);
            return;
        }

        this.setState({ hideContent: true, animateFromLeft: true }, () =>
            this.setStateDebounced(
                {
                    hideContent: false,
                    animateFromLeft: false,
                    curItem: curItem.children[childIndex],
                },
                () => this.updateHeight(),
            ),
        );
    };

    navigateToParent = (e) => {
        e.preventDefault();

        const curItem = this.state.curItem;
        if (curItem.parentItem) {
            this.setState({ hideContent: true, animateFromLeft: false }, () =>
                this.setStateDebounced({ hideContent: false, animateFromLeft: true, curItem: curItem.parentItem }, () =>
                    this.updateHeight(),
                ),
            );
        }
    };

    updateHeight = () => {
        this.heightRef && this.heightRef.updateHeight && this.heightRef.updateHeight();
    };

    render() {
        if (this.portalElement) {
            return ReactDOM.createPortal(this.renderAdaptingHeight(this.renderContents()), this.portalElement);
        } else {
            return this.renderContents();
        }
    }

    renderAdaptingHeight = (children) => {
        const { open } = this.props;
        const { forceClosed } = this.state;
        const renderLoading = status === PENDING;
        const renderContents = !renderLoading;

        return (
            <AutoHeight
                ref={this.setHeightRef}
                forcedHeight={open && !forceClosed ? null : 0}
                className={styles.mobileMenu}
            >
                {renderContents && children}
            </AutoHeight>
        );
    };

    renderContents() {
        const { hideContent, animateFromLeft } = this.state;

        return (
            <div
                className={classnames(styles.wrapper, {
                    [styles.inLeft]: !hideContent && animateFromLeft,
                    [styles.outLeft]: hideContent && animateFromLeft,
                    [styles.inRight]: !hideContent && !animateFromLeft,
                    [styles.outRight]: hideContent && animateFromLeft,
                })}
            >
                <div
                    className={classnames(styles.content, {
                        [styles.hide]: hideContent,
                    })}
                >
                    {this.renderMenuContent()}
                </div>
            </div>
        );
    }

    renderMenuContent = () => {
        const { curItem } = this.state;
        if (!curItem) return null;
        const isSecondLevel = curItem.level > 0;
        return (
            <>
                {isSecondLevel && (
                    <div
                        onClick={(e) => this.navigateToParent(e)}
                        className={classnames(styles.backButton, styles.link)}
                    >
                        <div className={styles.backIcon}>
                            <i className="fa fa-chevron-left"></i>
                        </div>
                        <span>{translate('/Header/Nav/Back')}</span>
                    </div>
                )}
                {this.renderLevel(curItem)}
                {this.renderSubMenu()}
            </>
        );
    };

    renderSubMenu() {
        const { links } = this.props;
        if (!links || links.length < 1) return null;
        return (
            <ul className={styles.compactLinkList}>
                {links.map((item, index) => this.renderSubMenuItem(item, index))}
            </ul>
        );
    }

    renderSubMenuItem(item, index) {
        const { url, name, target } = item;
        return (
            <li key={index}>
                <a
                    href={url}
                    className={classnames(styles.compactLink)}
                    title={name}
                    {...(target && { target: target })}
                >
                    {name}
                </a>
            </li>
        );
    }

    renderLevel(menuItem) {
        const { level, children, url, name, target } = menuItem;
        const isSecondLevel = level > 0;
        const hasImages = children.some((item) => item.image);

        return (
            <ul
                className={classnames('mainMenu', styles.level, {
                    [styles.topLevelPadding]: !isSecondLevel,
                })}
            >
                {isSecondLevel && menuItem && (
                    <li>
                        <div className={styles.parentLinkContainer}>
                            <a
                                href={url}
                                className={classnames(styles.link, styles.parentLink, {
                                    [styles.topBorder]: !hasImages,
                                    [styles.noBorder]: hasImages,
                                })}
                                title={name}
                                {...(target && { target: target })}
                            >
                                {name}
                            </a>
                        </div>
                    </li>
                )}
                {this.renderItems(menuItem)}
            </ul>
        );
    }

    renderItems(menuItem) {
        const { level, children } = menuItem;
        const isFirstLevel = level === 0;
        const hasImages = children.some((item) => item.image);

        if (isFirstLevel) return children.map((item, index) => this.renderFirstLevelItem(item, index));
        if (hasImages) return this.renderImageView(menuItem);
        return children.map((item, index) => this.renderSecondLevelItem(item, index));
    }

    renderFirstLevelItem(item, index) {
        const hasChildren = item.children && item.children.length > 0;
        return (
            <li key={index} {...(hasChildren && { onClick: (e) => this.navigateToChild(e, item.url) })}>
                <div className={styles.linkArea}>
                    <a
                        className={styles.link}
                        href={item.url}
                        title={item.title}
                        {...(item.target && { target: item.target })}
                    >
                        <span>{item.name}</span>
                        {hasChildren && <i className={classnames(styles.linkIcon, 'fa', 'fa-chevron-right')}></i>}
                    </a>
                </div>
            </li>
        );
    }

    renderSecondLevelItem(item, index) {
        return (
            <li key={index}>
                <div className={styles.linkArea}>
                    <ExpandingLevel
                        className={styles.link}
                        wrapperClassName={styles.linkWrapper}
                        item={item}
                        onClick={this.handleClick}
                    />
                </div>
            </li>
        );
    }

    renderImageView(menuItem) {
        const { children } = menuItem;
        return (
            <li className={classnames(styles.imageView, styles.linkArea)}>
                {children.map((item, index) => this.renderImageItem(item, index))}
            </li>
        );
    }

    renderImageItem(item, index) {
        const { image, url, title, name } = item;
        return (
            <div key={index}>
                <h4 className={styles.imageViewHeading}>{title || name}</h4>
                <Thumbnail className={styles.imageViewImage} image={image} url={url} width={165} height={110} />
                <a className={styles.imageViewLink} href={url}>
                    {name}
                </a>
            </div>
        );
    }
}
