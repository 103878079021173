import { translate } from 'Shared/translate';

export const getProductAlt = (displayName, description) => {
    if (!description) return displayName;
    return displayName + ' - ' + description;
};

export const mapPromotions = (promotions, price) => {
    if (promotions && promotions.length > 0) return promotions;
    if (!price || !price.isMemberPrice) return promotions || [];
    return [translate('/Product/MemberOffer')];
};

export const mapBadge = (productProps) => {
    return {
        isComingSoon: productProps.isComingSoon,
        isNew: productProps.new,
        isCustomerFavorite: productProps.isCustomerFavorite,
    };
};

export const mapLabel = (productProps) => {
    const { hideBasePrice, price, promotionNames } = productProps;
    const showDiscountOverlay = !hideBasePrice && price && !price.isMemberPrice && price.discountPercentage;
    return {
        promotions: mapPromotions(promotionNames, price),
        showDiscountOverlay: showDiscountOverlay,
        discountPercentage: price.discountPercentage,
    };
};
