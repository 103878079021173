import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as styles from './base.scss';
import * as elementStyles from 'Shared/Form/Elements/base.scss';
import { clearSessionState } from 'Shared/session-helper';
import { translate } from 'Shared/translate';
import { Input } from 'Shared/Form';
import Button from 'Shared/Button';
import { getCode, validateCode, loginUrl } from './api';
import { pathsEqual, getUrlParameters } from 'Shared/url';

class PhoneLogin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            codeSent: false,
            values: {
                phone: props.phone,
                code: props.code,
            },
            errors: {
                phone: '',
                code: '',
            },
        };
    }

    handleChange = (name, value) => {
        const { values } = this.state;
        this.setState({ values: { ...values, [name]: value } });
    };

    handleReturnToSend = (event) => {
        event.preventDefault();
        const { values } = this.state;
        this.setState({ loading: true, codeSent: false, values: { ...values, code: null } }, () =>
            setTimeout(() => {
                this.setState({ loading: false });
            }, 300),
        );
    };

    handleSendSubmit = (event) => {
        event.preventDefault();

        const { values, errors, loading } = this.state;
        const { phone } = values;

        if (loading) return;

        this.setState({ loading: true, errors: { ...errors, phone: null } }, () =>
            getCode(phone).then(this.handleSendResponse, this.handleSendError),
        );
    };

    handleValidate = () => {};

    handleSendError = (error) => {
        this.handleSendResponse({ success: false, message: error });
    };

    handleSendResponse = (response) => {
        if (response.success) {
            this.setState({ codeSent: true, loading: false });
        } else {
            const errors = { ...this.state.errors, ...{ phone: this.getStandardError(response) } };
            this.setState({ codeSent: false, loading: false, errors });
        }
    };

    handleVerifySubmit = (event) => {
        event.preventDefault();

        const { values, errors, loading } = this.state;
        const { phone, code } = values;

        if (loading) return;

        const redirectUrl = this.getRedirectUrl();

        this.setState({ loading: true, errors: { ...errors, code: null } }, () =>
            validateCode(phone, code, redirectUrl).then(this.handleVerifyResponse, this.handleVerifyError),
        );
    };

    getRedirectUrl = () => {
        const queryParameters = getUrlParameters(window.location.search);
        const { requestedUrl } = queryParameters;

        if (requestedUrl) return requestedUrl;
        const { pathname } = window.location;

        if (pathsEqual(pathname, loginUrl)) return null;

        return pathname;
    };

    handleVerifyResponse = (response) => {
        if (response.success) {
            const { onLoginSuccess } = this.props;
            clearSessionState();
            if (onLoginSuccess) onLoginSuccess(response);
            if (response.redirectUrl) {
                window.location.href = response.redirectUrl;
            } else {
                this.setState({ loading: false });
            }
        } else {
            const { onLoginFailed } = this.props;
            const errors = { ...this.state.errors, ...{ code: this.getError(response) } };
            this.setState({ loading: false, errors });
            if (onLoginFailed) onLoginFailed(response);
        }
    };

    handleVerifyError = (error) => {
        this.handleVerifyResponse({ success: false, message: error });
    };

    getStandardError = () => {
        return translate('/Login/Error');
    };

    getError = (response) => {
        if (!response) return '';
        if (typeof response === 'string') return response;

        const message = response.message;
        if (message && message.stack && message.message) {
            return message.message;
        }

        return message;
    };

    render() {
        const { codeSent, loading, values, errors } = this.state;
        const { hideEnterCodeInstruction, hideLoginInstruction } = this.props;
        const { phone, code } = values;
        const displaySendForm = !codeSent && !loading;
        const displaySpinner = loading;
        const displayVerifyForm = codeSent && !loading;
        return (
            <div className={styles.phoneLogin}>
                <div className={styles.panels}>
                    <div className={classnames(styles.panel, styles.send, { [styles.hidden]: !displaySendForm })}>
                        <p className={classnames(styles.instruction, { [styles.hidden]: hideLoginInstruction })}>
                            {translate('/Login/Instruction')}
                        </p>
                        <form onSubmit={this.handleSendSubmit}>
                            <Input
                                prefix={'+47'}
                                name="phone"
                                inputMode="numeric"
                                defaultValue={phone}
                                onChange={this.handleChange}
                                label={translate('/Login/Form/Phone')}
                                placeholder={translate('/Login/Placeholder/Phone')}
                                title={translate('/Login/InvalidPhone')}
                                pattern="^[49]{1}[0-9]{7}$"
                                error={errors.phone}
                                required
                            />
                            <Button type="submit" className={elementStyles.button}>
                                {translate('/Login/Form/SubmitPhone')}
                            </Button>
                        </form>
                    </div>
                    <div
                        className={classnames(styles.panel, styles.loading, { [styles.hidden]: !displaySpinner })}
                    ></div>
                    <div className={classnames(styles.panel, styles.verify, { [styles.hidden]: !displayVerifyForm })}>
                        <p className={classnames(styles.instruction, { [styles.hidden]: hideEnterCodeInstruction })}>
                            {translate('/Login/EnterCodeInstruction')}
                        </p>
                        <p className={styles.instruction}>{translate('/Login/CodeSentTo', values.phone)}</p>
                        <form onSubmit={this.handleVerifySubmit}>
                            <Input
                                name="code"
                                defaultValue={code}
                                onChange={this.handleChange}
                                label={translate('/Login/Form/Code')}
                                placeholder={translate('/Login/Placeholder/Code')}
                                error={errors.code}
                                required
                            />
                            <Button type="submit" className={classnames(elementStyles.button, styles.button)}>
                                {translate('/Login/Form/SubmitCode')}
                            </Button>
                            <a className={styles.link} onClick={this.handleReturnToSend}>
                                {translate('/Login/BackToLogin')}
                            </a>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

PhoneLogin.propTypes = {
    onLoginSuccess: PropTypes.func,
    onLoginFailed: PropTypes.func,
    hideLoginInstruction: PropTypes.bool,
    hideEnterCodeInstruction: PropTypes.bool,
};

PhoneLogin.defaultProps = {
    heading: translate('/Login/Heading'),
    phone: '',
    code: '',
};

export default PhoneLogin;
