import React from 'react';
import cn from 'classnames';
import { translate } from 'Shared/translate';
import * as styles from './base.scss';

const labels = new Set(['A', 'B', 'C', 'D', 'E', 'F', 'G']);
const sizes = new Set(['large', 'small']);
const orientations = new Set(['left', 'right']);

const EnergyLabel = ({ type, size, orientation, className }) => {
    if (!labels.has(type)) return null;
    if (!sizes.has(size)) size = EnergyLabel.defaultProps.size;
    if (!orientations.has(orientation)) orientation = EnergyLabel.defaultProps.orientation;
    return (
        <div className={cn(className, styles.container, styles[orientation], styles[size])}>
            <span
                className={cn(styles.label, styles[type])}
                aria-label={`${translate('/EnergyLabel/EnergyClass')} - ${type}`}
            ></span>
            {<p className={styles.dataSheet}>{translate('/EnergyLabel/ProductSheet')}</p>}
        </div>
    );
};

EnergyLabel.defaultProps = {
    size: 'large',
    orientation: 'left',
};

export default EnergyLabel;
