import store from 'Shared/store';
import { getProps } from 'Shared/dom';
import { debounce } from 'Shared/debounce';
import { hasSupport, observerOptions, epiRenderDebounce } from './observer';

const blockAttribute = 'data-epi-block-id';

let observer = null;
let elements = null;

export const observeBlocks = (element, callback) => {
    if (!hasSupport) return;
    const { editContainer } = store.getState();
    const { isInEditMode } = editContainer;
    if (!isInEditMode) return;
    callback = debounce(epiRenderDebounce, callback);
    elements.set(element, callback);
    observer.observe(element, observerOptions);
};

if (hasSupport) {
    elements = new Map();
    observer = new MutationObserver((mutations) => {
        for (const mutation of mutations) {
            if (mutation.type !== 'childList') return;
            const { callback, element } = findCallback(mutation.target);
            if (callback) {
                const nodes = Array.prototype.slice.call(mutation.addedNodes);
                const blocks = nodes
                    .filter((node) => node.hasAttribute && node.hasAttribute(blockAttribute))
                    .map((node) => findProps(node));

                if (blocks.length > 0) {
                    callback(blocks);
                }
            }
        }
    });
}

const findProps = (node) => {
    const element = node.querySelector('[data-props]');
    if (!element) return {};
    return getProps(element);
};

const findCallback = (element) => {
    let callback;
    while (element.parentNode) {
        element = element.parentNode;
        callback = elements.get(element);
        if (callback) return { element, callback };
    }
    return {};
};
