import React from 'react';
import Item from './Item';
import * as styles from './item-list.scss';
import { groupCartItems } from '../utils';

export default function ItemList(props) {
    return (
        <section className={styles.productList}>
            {props.items &&
                groupCartItems(props.items, true).map((item) => {
                    const { lineId } = item;
                    return (
                        <Item
                            key={lineId}
                            item={item}
                            cartIsPending={props.cartIsPending}
                            adjustQuantity={item.quantity - item.originalQuantity}
                            quantity={item.originalQuantity}
                            update={props.update}
                            remove={props.remove}
                            breakpoint={props.breakpoint}
                            isMobileView={props.isMobileView}
                            showQuantityButtons={props.showQuantityButtons}
                            showUnitPrice={props.showUnitPrice}
                            showBrand={props.showBrand}
                            showDelivery={props.showDelivery}
                            showValidation={props.showValidation}
                            placeholdDelivery={props.placeholdDelivery}
                            min={props.minQuantity}
                            max={props.maxQuantity}
                            hideRemoveLink={props.hideRemoveLink}
                            compactRemoveLinks={props.compactRemoveLinks}
                            bigThumbnails={props.bigThumbnails}
                            useItemQuantities={props.useItemQuantities}
                        />
                    );
                })}
        </section>
    );
}
