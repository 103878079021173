import React, { useState, useEffect, memo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as styles from './base.scss';
import { resize } from '../url';

const Picture = ({ src, sizes, fallbackImageUrl, dispatch, className, quality, rmode, ...imageProps }) => {
    const [source, setSource] = useState(src || fallbackImageUrl);

    useEffect(() => setSource(src || fallbackImageUrl), [src]);

    const handleError = () => {
        if (source === fallbackImageUrl) return;
        setSource(fallbackImageUrl);
    };

    const getResizeMode = ({ width, height }) => {
        if (width && height) return 'crop';
        return null;
    };

    const defaultSize = sizes.find((x) => x.fallback === true);
    const defaultSource = defaultSize
        ? resize(source, defaultSize.width, defaultSize.height, rmode || getResizeMode(defaultSize), quality)
        : source;

    return (
        <picture className={classnames(className, { [styles.noImage]: !src })}>
            {sizes.map((size, index) => (
                <source
                    key={index}
                    media={size.selector}
                    srcSet={resize(source, size.width, size.height, rmode || getResizeMode(size), quality)}
                />
            ))}
            <img src={defaultSource} onError={handleError} loading="lazy" {...imageProps} />
        </picture>
    );
};

Picture.propTypes = {
    src: PropTypes.string,
    sizes: PropTypes.arrayOf(PropTypes.shape(imageSizeShape)).isRequired,
};

Picture.defaultProps = {
    sizes: [],
};

export const imageSizeShape = {
    selector: PropTypes.string.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    fallback: PropTypes.bool,
};

export default memo(
    connect(
        ({ knownUrls }) => ({
            fallbackImageUrl: knownUrls.fallbackImageUrl,
        }),
        null,
    )(Picture),
);
