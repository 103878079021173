import React from 'react';

let phrases = {};

// Are you here looking for how we know which language keys exists?
// We find that out dynaically using `webpack/translation-finder-loader.js`
// It goes through all code and looks for calls like this: `translate('xxx')`
// and automatically makes that phrase available in the frontend code.

export function setPhrases(p) {
    phrases = p;
}

export const matchFormat = /\{([0-9]+)\}/gi;

// Format phrase using curly braces, e.g. `format('foo{0} {1}', 'bar', 'baz')` => Outputs: foobar baz
export function format(phrase, ...values) {
    if (process.env.NODE_ENV !== 'production') {
        if (!phrase) return phrase;
        const placeholders = phrase.match(matchFormat) || [];
        if (placeholders.length > values.length) {
            const error = `The phrase "${phrase}" contains ${placeholders.length} placeholders
                     but ${values.length} placeholders was passed to translate(). The placeholders
                     passed in was: ${JSON.stringify(values)}`;
            console.error(error);
        }
    }
    return values.length === 0
        ? phrase
        : phrase.replace(matchFormat, (match, index) => (typeof values[index] !== 'undefined' ? values[index] : match));
}

export function translate(key, ...values) {
    if (!(key in phrases)) {
        return key;
    } else {
        return format(phrases[key], ...values) || key;
    }
}

export function getPhrase(key) {
    if (!(key in phrases)) {
        return key;
    } else {
        return phrases[key];
    }
}

export function renderTranslation(key, ...children) {
    const phrase = phrases[key] || key;
    return renderReplacement(phrase, ...children);
}

export function renderReplacement(phrase, ...children) {
    const matches = Array.from(phrase.matchAll(matchFormat));

    if (matches.length === 0) return phrase;
    if (matches.length < children.length) {
        const error = `The phrase "${phrase}" contains ${matches.length} placeholders but ${children.length} templates was passed to renderTranslation().`;
        console.error(error);
    }

    let nodes = [];
    let lastIndex = 0;
    let lastLength = 0;

    for (let i = 0; i < matches.length; i++) {
        const match = matches[i];
        const matchLength = match[0].length;

        const subphrase = phrase.substring(lastIndex, match.index);

        nodes.push(subphrase);
        nodes.push(children[i]);

        lastIndex = match.index + matchLength;
        lastLength = matchLength;
    }

    nodes.push(phrase.substring(lastIndex));

    return nodes.map((item, index) => <React.Fragment key={index}>{item}</React.Fragment>);
}
