import React from 'react';
import Collapse from 'react-collapse';
import PropTypes from 'prop-types';


const SPRING_CONFIG_DEFAULT = {
  stiffness: 230,
  damping: 26,
  precision: 0.01,
};

export default class HeightTransition extends React.PureComponent {
  onRender = (renderProps) => {
    !!this.props.onRender && this.props.onRender(renderProps);
  };

  getHeight(renderProps) {
    // Note that this returns 0 before and after transition
    return Math.max(renderProps.to, renderProps.from);
  }
  render() {
    const { isOpened, onRender, className, contentClassName, springConfig, ...restProps } = this.props;
    const SPRING_CONFIG = { ...SPRING_CONFIG_DEFAULT, ...springConfig };
    return (
      <Collapse
        theme={{
          collapse: className,
          content: contentClassName,
        }}
        isOpened={!!isOpened}
        onRender={this.onRender}
        springConfig={SPRING_CONFIG}
        {...restProps}
      />
    );
  }
}

HeightTransition.propTypes = {
    isOpened: PropTypes.bool,
    onRender: PropTypes.func,
    springConfig: PropTypes.object,
    className: PropTypes.string,
}