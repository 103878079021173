import { postJson } from 'Shared/server';

export default function genericRequestAction(actionTypes) {
    const { pending, update, fulfill, reject } = actionTypes;
    let queue = [];

    return (url, body, get = (d) => d) => {
        return (dispatch) => {
            if (pending) {
                dispatch({ type: pending });
            }

            const promise = Promise.all(queue).then(() =>
                postJson(url, body)
                    .then(get)
                    .then((data) => {
                        if (update && data) {
                            dispatch({
                                type: update,
                                data,
                            });
                        }
                        return data;
                    }),
            );

            const currentQueue = [...queue, promise];
            queue = currentQueue;

            Promise.all(queue).then(
                () => {
                    if (queue === currentQueue) {
                        queue.length = 0; // Empty queue
                        if (fulfill) {
                            dispatch({ type: fulfill });
                        }
                    }
                },
                (error) => {
                    if (queue === currentQueue) {
                        queue.length = 0; // Empty queue
                        if (reject) {
                            dispatch({ type: reject, error });
                        }
                    }
                },
            );

            return promise;
        };
    };
}
