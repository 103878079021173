import '@babel/polyfill';
import 'whatwg-fetch';
import '../Styles/globals.scss';

import './FlashMessages';
import './Header';
import './Checkout/OrderConfirmation';
import './FlowBox';

import store from 'Shared/store';
import { updateBreakpointOnResize } from 'Shared/breakpoints';
import { setPhrases } from 'Shared/translate';
import { renderContainers } from './entryContainers';
import { hasAuthSession, hasCartSession } from 'Shared/session-helper';
import { getAuthState } from './Login/actions';
import { getCart } from './Cart/actions';
import { pageView } from 'Shared/events';
import { registerVoyadoTracking } from 'Shared/tracking-voyado';
import { registerPeeriusTracking } from './Shared/tracking-peerius';

setPhrases(window.LANGUAGE_PHRASES || {});

const authSession = hasAuthSession();
const cartSession = hasCartSession();

if (!authSession) store.dispatch(getAuthState());
if (!window.CHECKOUT_INFO && !cartSession) store.dispatch(getCart(true));

store.dispatch(updateBreakpointOnResize());

registerVoyadoTracking();
registerPeeriusTracking();
renderContainers();
pageView();
