const initialState = window.EDIT_INFO || {
    isInEditMode: false,
    routedContentLink: null,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}
