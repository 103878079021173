import React, { useRef, useState, memo } from 'react';
import PropTypes from 'prop-types';
import parser from 'html-react-parser';
import Picture, { imageSizeShape } from 'Shared/Picture';
import { useIntersectionObserver } from 'Shared/Hooks';
import classnames from 'classnames';
import * as styles from './base.scss';

const EmbedObject = ({ className, overlayClass, imageUrl, imageAlt, sizes, embedSource, showOverlay }) => {
    const embedRef = useRef();
    const entry = useIntersectionObserver(embedRef, { freezeOnceVisible: true });
    const [overlayHidden, setOverlayHidden] = useState(false);
    const renderEmbed = () => {
        return (
            <>
                {entry.isIntersecting && parser(embedSource)}
                {showOverlay && !overlayHidden && renderOverlay(overlayClass)}
            </>
        );
    };

    const renderOverlay = (className) => {
        return (
            <div className={styles.overlay} onClick={(event) => handleOverlayClick(event)}>
                <div className={classnames(styles.playButton, className)}>
                    <i className="fa fa-play"></i>
                </div>
                {imageUrl && renderImage()}
            </div>
        );
    };

    const renderImage = (classNames) => {
        return <Picture className={classnames(styles.image, classNames)} src={imageUrl} alt={imageAlt} sizes={sizes} />;
    };

    const handleOverlayClick = () => {
        setOverlayHidden(true);
        if (embedRef.current) {
            const videoElement = embedRef.current.querySelector('video');
            if (videoElement) {
                try {
                    videoElement.play();
                } catch (error) {
                    console.error(error);
                }
            }
        }
    };

    return (
        <div ref={embedRef} className={classnames(styles.embedObject, className)}>
            {embedSource ? renderEmbed() : renderImage()}
        </div>
    );
};

EmbedObject.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    imageUrl: PropTypes.string,
    imageAlt: PropTypes.string,
    embedSource: PropTypes.string,
    showOverlay: PropTypes.bool,
    sizes: PropTypes.arrayOf(PropTypes.shape(imageSizeShape)),
};

export default memo(EmbedObject);
