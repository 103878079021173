import React from 'react';
import PropTypes from 'prop-types';
import { imageShape } from '../../propTypes';
import { resize } from 'Shared/url';

const Thumbnail = (props) => {
    const { image, url, width, height, className } = props;
    if (!image) return null;

    const { source, alt } = image;
    const src = resize(source, width, height, 'crop');

    if (!url) {
        return <img className={className} src={src} alt={alt} width={width} height={height} />;
    } else {
        return (
            <a href={url}>
                <img className={className} src={src} alt={alt} width={width} height={height} />
            </a>
        );
    }
};

Thumbnail.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    url: PropTypes.string,
    className: PropTypes.string,
    image: PropTypes.shape(imageShape),
};

export default Thumbnail;
