import React from 'react';

export default class CommerceEnabled extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return window.ENABLE_ECOMMERCE === 'False' ? null : this.props.children;
    }
}
