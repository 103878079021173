import React from 'react';
import * as styles from './base.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Stars } from './Components/Stars';
import { Star } from './Components/Star';
import { ratings, lipScoreActive } from './api';

const defaultState = {
    isLoaded: false,
    isLoading: false,
    score: 0,
    reviews: 0,
    votes: 0,
};

export const displayStyles = {
    wide: 'wide',
    narrow: 'narrow',
};

export class LipScore extends React.Component {
    static get propTypes() {
        return {
            productId: PropTypes.string,
            displayStyle: PropTypes.oneOf([displayStyles.wide, displayStyles.narrow]),
            className: PropTypes.string,
            votes: PropTypes.number,
            score: PropTypes.number,
        };
    }

    static defaultProps = {
        displayStyle: displayStyles.wide,
    };

    constructor(props) {
        super(props);
        this.state = {
            ...defaultState,
        };
    }

    componentDidMount() {
        if (this.getPropsContainScore(this.props)) {
            const { score, votes } = this.props;
            this.setState({
                isLoaded: true,
                isLoading: false,
                score,
                votes,
            });
        }
    }

    initComponentViaApi = (response) => {
        const { lipscore, votes } = response;
        this.setState({
            isLoaded: true,
            isLoading: false,
            // Note: score delivered from api/show is from 0 to 10 for some reason.
            // New api endpoint is from 0 to 5.
            // This method is only touched when fetching from old endpoint
            score: parseFloat(lipscore) / 2,
            votes,
        });
    };

    componentError = (error) => {
        console.error(error);
        this.setState({
            isLoading: false,
        });
    };

    getPropsContainScore = (props) => {
        const { score, votes } = props;
        if (score === null || score === undefined) return false;
        if (votes === null || votes === undefined) return false;
        return true;
    };

    getShouldRender = (isNarrow) => {
        const { isLoaded, score, votes } = this.state;
        if (isLoaded == false) return false;
        if (isNarrow && !votes) return false;
        if (score === NaN) return false;
        return true;
    };

    render() {
        const { className, displayStyle } = this.props;
        const { isLoading } = this.state;

        const isNarrow = displayStyle === displayStyles.narrow;
        const shouldRender = this.getShouldRender(isNarrow);

        return (
            <div
                className={classnames(styles.lipscore, className, {
                    [styles.lipscore_loading]: isLoading,
                    [styles.narrow]: isNarrow,
                })}
            >
                {shouldRender ? this.renderComponent() : this.renderPlaceholder()}
            </div>
        );
    }

    renderPlaceholder() {
        return (
            <>
                &nbsp;
                <span className={styles.lipscore__votes}>&nbsp;</span>
            </>
        );
    }

    renderComponent = () => {
        const { displayStyle } = this.props;
        switch (displayStyle) {
            case displayStyles.narrow:
                return this.renderNarrowComponent();
            default:
                return this.renderWideComponent();
        }
    };

    renderWideComponent = () => {
        const { votes, score } = this.state;
        return (
            <>
                <Stars score={score} />
                <span className={styles.lipscore__votes}>({votes})</span>
            </>
        );
    };

    renderNarrowComponent = () => {
        const { score } = this.state;
        return (
            <>
                <Star />
                <span className={styles.lipscore__votes}>{score.toFixed(1)}</span>
            </>
        );
    };
}

export default LipScore;
