import { BUNDLE_PENDING, BUNDLE_FULFILL, BUNDLE_REJECT, BUNDLE_UPDATE } from 'Shared/action-types';

export const PENDING = 'PENDING';
export const FULFILLED = 'FULFILLED';
export const REJECTED = 'REJECTED';

const initialState = {
    status: FULFILLED,
    data: window.BUNDLE_DATA || null,
    checkoutUrl: window.CHECKOUTURL,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case BUNDLE_PENDING:
            return Object.assign({}, state, {
                status: PENDING,
            });
        case BUNDLE_FULFILL:
            return Object.assign({}, state, {
                status: FULFILLED,
            });
        case BUNDLE_REJECT:
            return Object.assign({}, state, {
                status: REJECTED,
            });
        case BUNDLE_UPDATE:
            return Object.assign({}, state, {
                data: action.data,
            });
        default:
            return state;
    }
}
