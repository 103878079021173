import { translate } from 'Shared/translate';
import { compileQuantities } from './utils';
import { getQueryString, fill, clean } from 'Shared/url';
import {
    DISPLAY_PAY_AND_COLLECT,
    HIDE_PAY_AND_COLLECT,
    PAY_AND_COLLECT_ACTION,
    PAY_AND_COLLECT_STORE_UPDATE,
    PAY_AND_COLLECT_SEARCH,
    PAY_AND_COLLECT_SEARCH_COMPLETE,
    PAY_AND_COLLECT_SEARCH_ERROR,
} from 'Shared/action-types';

export function searchByQuery(cart, code, storeId, query, count, all, productOnDisplay) {
    return search(
        '/productInventoryApi/locateCartByQuery/',
        getParams(cart, { query }, { code, storeId, count, all, productOnDisplay }),
    );
}

export function searchByGeoPosition(cart, code, storeId, lat, lng, count, all, productOnDisplay) {
    return search(
        '/productInventoryApi/locateCartByGeolocation/',
        getParams(cart, { lat, lng }, { code, count, all, storeId, productOnDisplay }),
    );
}

function getParams(cart, required, optional = {}) {
    optional.count = ensureCount(optional.count);
    const quantities = compileQuantities(cart);
    return {
        cartLines: buildCartLinesParameter(quantities),
        ...clean(optional),
        ...fill(required),
    };
}

function buildCartLinesParameter(quantities) {
    const count = Object.keys(quantities).length;
    if (count < 1) return '';

    let keyValuePairs = [];
    for (const key in quantities) {
        keyValuePairs.push(`${key}|${quantities[key]}`);
    }
    return keyValuePairs.join(',');
}

export function payAndCollect(data) {
    return { type: PAY_AND_COLLECT_ACTION, data };
}

export function updateSelectedStoreDetails(store) {
    return { type: PAY_AND_COLLECT_STORE_UPDATE, store };
}

export function displayPayAndCollect(code, orderOnline, showAllStores, showOnDisplay) {
    return { type: DISPLAY_PAY_AND_COLLECT, code, orderOnline, showAllStores, showOnDisplay };
}

export function hidePayAndCollect() {
    return { type: HIDE_PAY_AND_COLLECT };
}

export function search(url, params) {
    const { count } = params;
    return { type: PAY_AND_COLLECT_SEARCH, url: `${url}${getQueryString(params)}`, count };
}

export function searchComplete(data) {
    return { type: PAY_AND_COLLECT_SEARCH_COMPLETE, data };
}

export function searchError(reason) {
    let message = null;
    if (typeof reason === 'string') message = reason;
    if (reason.message) message = reason.message;
    return { type: PAY_AND_COLLECT_SEARCH_ERROR, data: getErrorResponse(message) };
}

function ensureCount(count) {
    if (count <= 0) return 4;
    return count;
}

function getErrorResponse(message) {
    return {
        StoreInventories: [],
        ErrorMessage: message || translate('/ProductPage/DetailedStock/Error'),
        Success: false,
    };
}
