import store from 'Shared/store';
import { toggleQuickSearch, reset as resetQuickSearch } from '../Search/SearchBar/actions';
import { toggleDimmedArea } from 'Shared/DimmedArea/actions';

const TOGGLE_CLASS_NAME = 'mainMenu--open';
const navMenu = document.querySelector('#MobileNavPortal');
const DESKTOP_WIDTH = 1024;
let isMobile = window.innerWidth < DESKTOP_WIDTH;

const mobileHeaderSticky = () => {
    const element = document.querySelector('.headerContent');

    if (window.innerWidth < DESKTOP_WIDTH) {
        element.classList.add('header-fixed');
    } else {
        element.classList.remove('header-fixed');
    }
};

document.addEventListener('DOMContentLoaded', () => {
    navMenu.classList.add('mainMenu--loaded');

    mobileHeaderSticky();

    window.addEventListener(
        'resize',
        () => {
            mobileHeaderSticky();

            isMobile = window.innerWidth < DESKTOP_WIDTH;

            if (!isMobile) {
                store.dispatch(toggleQuickSearch(false));
                store.dispatch(resetQuickSearch());

                const state = store.getState();

                if (!state.cart.miniCartVisible) {
                    store.dispatch(toggleDimmedArea(false));
                    navMenu.style.width = '';
                }

                if (navMenu.classList.contains(TOGGLE_CLASS_NAME)) {
                    navMenu.classList.remove(TOGGLE_CLASS_NAME);
                }
            }
        },
        false,
    );
});
