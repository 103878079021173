import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const GradientOverlay = ({ children, position, disabled }) => {
    if (disabled) return <>{children}</>;
    return <div className={classnames('background-shade', 'relative', position)}>{children}</div>;
};

export const positions = ['', 'center', 'left', 'right', 'bottom', 'top'];

export const getGradientPosition = (position) => {
    switch (position) {
        case 'top':
        case 'topLeft':
        case 'topRight':
            return 'top';
        case 'left':
            return 'left';
        case 'right':
            return 'right';
        case 'bottom':
        case 'bottomLeft':
        case 'bottomRight':
            return 'bottom';
        default:
            return '';
    }
};

GradientOverlay.propTypes = {
    position: PropTypes.oneOf(positions),
    disabled: PropTypes.bool,
};

export default GradientOverlay;
