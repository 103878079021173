import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import store from '../../../store';
import PortalNav from './portalNav';

export function embed(container) {
    render(
        <Provider store={store}>
            <PortalNav
                className={'styledEpiMenuRight'}
                items={window[container.dataset.items]}
                portal={container.dataset.portal}
            />
        </Provider>,
        container,
    );
}
