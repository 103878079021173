import { innerHTML } from 'Shared/dom';

function render(messages) {
    // Use DOM manipulation functions from Shared/dom to prevent layout thrashing
    const container = document.getElementById('flash-messages');
    if (messages.length) {
        const html = messages
            .map(msg => {
                return `<div class="message message-${msg.type}">${msg.message}</div>`;
            })
            .join('');
        innerHTML(container, html);
    }
}

render(window.FLASH_MESSAGES || []);
// Uncomment to test:
/*render([{"type":"notice","message":"Kunden avbröt sitt köp."},
{"type":"error","message":"Ett fel inträffade."},
{"type":"success","message":"Betalningen genomfördes."}]);*/
