import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import cart from '../Cart/reducer';
import checkout from '../Checkout/reducer';
import breakpoint from 'Shared/Reducers/breakpoint';
import dimmedArea from 'Shared/DimmedArea/reducer';
import menu from 'Shared/Menu/reducer';
import searchResults from '../Search/SearchBar/reducer';
import searchPage from '../Search/reducer';
import payAndCollect from '../PayAndCollect/reducer';
import register from '../Registration/reducer';
import product from '../Product/ProductPage/reducer';
import bundle from '../Product/BundlePage/reducer';
import login from '../Login/reducer';
import profile from '../Profile/reducer';
import editContainer from 'Shared/EditContainer/reducer';
import searchByCityAndZip from '../StoreDetails/StoresFilters/SearchByCityAndZip/reducer';
import searchByRegion from '../StoreDetails/StoresFilters/SearchByRegion/reducer';
import stores from '../StoreDetails/StoreDetails/reducer';
import category from '../Category/reducer';
import recentlyAdded from 'Shared/RecentlyAddedMessage/reducer';
import newAccount from '../Login/reducer';
import searchByFilters from '../StoreDetails/StoresFilters/SearchByFacilitiesAndDepartments/reducer';
import orderHistory from '../OrderHistory/reducer';
import Loader from 'Shared/Loader/reducer';
import brandList from '../Brand/reducer';
import knownUrls from 'Shared/known-urls';
import articles from '../Articles/reducer';
import closestStore from '../Product/Components/ClosestStoreModal/reducer';
import recommendations from '../Recommendations/reducer';
import financing from '../Financing/reducer';
import { payAndCollectMiddleware } from '../PayAndCollect/middleware';
import { cartMiddleware } from '../Cart/middleware';

// Like `combineReducers` but merges an `Array` of reducers
export function mergeReducers({ reducers, initialState }) {
    return (state = initialState, action) => reducers.reduce((s, r) => r(s, action), state);
}

const composedCreateStore = compose(
    applyMiddleware(payAndCollectMiddleware, thunk),
    applyMiddleware(cartMiddleware, thunk),
    // This enables the Redux Dev Tools for Chrome:
    // https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd/related
    window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f,
)(createStore);

export default composedCreateStore(
    combineReducers({
        cart,
        checkout,
        payAndCollect,
        searchResults,
        searchPage,
        breakpoint,
        register,
        product,
        bundle,
        login,
        profile,
        orderHistory,
        category,
        recentlyAdded,
        dimmedArea,
        menu,
        newAccount,
        searchByRegion,
        searchByCityAndZip,
        stores,
        searchByFilters,
        Loader,
        brandList,
        knownUrls,
        closestStore,
        editContainer,
        articles,
        recommendations,
        financing,
    }),
);
