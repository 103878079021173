export const animationTiming = 300;
export const defaultEasing = {
    p1: { x: 0.6, y: 0.0 },
    p2: { x: 0.4, y: 1.0 },
};

const containerElement = document.getElementsByClassName('page-container')[0];
const defaultInterpolation = `cubic-bezier(${defaultEasing.p1.x}, ${defaultEasing.p1.y}, ${defaultEasing.p2.x}, ${defaultEasing.p2.y})`;

let contentHeight = null;
let animationTimeout = null;

export const hidePageContent = () => {
    if (!containerElement) return;
    contentHeight = containerElement.offsetHeight;
    setHideStyles(containerElement.style, contentHeight);
};

export const showPageContent = () => {
    if (!containerElement) return;
    setShowStyles(containerElement.style, contentHeight);
};

export const resetPageContent = () => {
    if (!containerElement) return;
    setResetStyles(containerElement.style);
};

const setHideImmediate = (style) => {
    style.display = 'none';
};

const setHideStyles = (style, height, easing = defaultInterpolation, delay = 0) => {
    style.height = `${height}px`;
    style.transition = `all ${animationTiming - delay}ms ${easing}`;

    if (delay) style.transitionDelay = `${delay}ms`;

    setTimeout(() => {
        style.overflow = 'hidden';
        style.height = '0';
        style.marginBottom = '0';
        style.paddingBottom = '0';
    }, 1);

    stopExistingTimeout();
    animationTimeout = setTimeout(() => {
        style.display = 'none';
    }, animationTiming);
};

const setShowStyles = (style, height, delay = 0) => {
    style.display = '';

    if (delay) style.transitionDelay = `${delay}ms`;

    setTimeout(() => {
        style.height = `${height}px`;
    }, 1);

    stopExistingTimeout();
    animationTimeout = setTimeout(() => {
        style.transition = '';
        style.transitionDelay = '';
        style.overflow = '';
        style.marginBottom = '';
        style.paddingBottom = '';
        style.height = '';
    }, animationTiming);
};

const stopExistingTimeout = () => {
    if (animationTimeout !== null) {
        clearTimeout(animationTimeout);
        animationTimeout = null;
    }
};

const setResetStyles = (style) => {
    style.transition = '';
    style.transitionDelay = '';
    style.overflow = '';
    style.marginBottom = '';
    style.paddingBottom = '';
    style.height = '';
    style.display = '';
};
