import { translate } from './translate';

export function formatPrice(price, formatAppearance = 'currency') {
    const floorOrCeil = price > 0 ? Math.floor(price) : Math.ceil(price);
    const fraction = Math.round((price - floorOrCeil) * 100);
    const thousandSeparator =
        translate('/Price/ThousandSeparator') === '[SPACE]' ? ' ' : translate('/Price/ThousandSeparator');
    const whole = floorOrCeil.toString().replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator);
    const formatedPrice =
        whole +
        (fraction !== 0
            ? translate('/Price/Decimal') + ((Math.abs(fraction) < 10 ? '0' : '') + Math.abs(fraction))
            : '');
    if (formatAppearance === 'currency') {
        return formatedPrice;
    } else {
        throw new Error('Unknown formatAppearance: ' + formatAppearance);
    }
}

export function leadingZeros(num, numberLength = 2) {
    let s = String(num);
    while (s.length < numberLength) {
        s = '0' + s;
    }
    return s;
}

export function toNumber(value) {
    return (value || '').toString().replace(',', '.');
}

export function isDecimal(input) {
    return input === '.' || input === ',';
}

export function toNumberValue(val) {
    return (val || '').toString().replace('.', ',');
}

export function min(maxValue, value) {
    if (!maxValue) return value;
    return Math.min(maxValue, value);
}
